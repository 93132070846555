<template>
  <div class="main-container wide">
    <div class="title-pan">
      <div class="filter-buttons">
        <div
          class="filter-button"
          v-if="range_obj.this_year"
          :class="{ active: range === 'this_year' }"
          @click="() => (range = 'this_year')"
        >
          本年
        </div>
        <div
          class="filter-button"
          v-if="range_obj.this_month"
          :class="{ active: range === 'this_month' }"
          @click="() => (range = 'this_month')"
        >
          本月
        </div>
        <div
          class="filter-button"
          v-if="range_obj.last_month"
          :class="{ active: range === 'last_month' }"
          @click="() => (range = 'last_month')"
        >
          上月
        </div>
        <div
          class="filter-button"
          v-if="range_obj.this_week"
          :class="{ active: range === 'this_week' }"
          @click="() => (range = 'this_week')"
        >
          本周
        </div>
        <div
          class="filter-button"
          v-if="range_obj.last_week"
          :class="{ active: range === 'last_week' }"
          @click="() => (range = 'last_week')"
        >
          上周
        </div>
      </div>
    </div>
    <div class="data-title">
      <div>
        {{ range_fetched ? range_obj[range_fetched].name : "" }}
        全员统计数据(美元)
      </div>
      <div>
        {{ range_fetched ? range_obj[range_fetched].name : "" }}
        [必达,冲刺,最终]目标(万美元)
      </div>
    </div>
    <div class="data-content">
      <div class="lt-head">
        <div class="lt-tr even">
          <div class="lt-td name">姓名</div>
          <div class="lt-td data">接单(收款)</div>
          <div class="lt-td data">接单金额</div>
          <div class="lt-td data">辅助接单(收款)</div>
          <div class="lt-td data">辅助接单金额</div>
          <div class="lt-td data">业务跟单(收款)</div>
          <div class="lt-td data">业务跟单金额</div>
          <div class="lt-td data">跟单出货</div>
        </div>
      </div>
      <div class="lt-body">
        <div
          class="lt-tr"
          v-for="(item, key) in data"
          :key="key"
          :class="{ odd: key % 2 === 0, even: key % 2 === 1 }"
        >
          <div class="lt-td name">{{ item.user.name }}</div>
          <div class="lt-td data">
            <div>{{ item.dataobj.jiedan_receipt }}</div>
            <div
              v-if="
                'jiedan_receipt' in item.dataobj &&
                'jiedan_receipt_obj' in item.dataobj
              "
              class="objective-value"
            >
              [{{ item.dataobj.jiedan_receipt_obj.join(",") }}]
            </div>
          </div>
          <div class="lt-td data">
            <div>{{ item.dataobj.jiedan_jiedan }}</div>
            <div
              v-if="
                'jiedan_jiedan' in item.dataobj &&
                'jiedan_jiedan_obj' in item.dataobj
              "
              class="objective-value"
            >
              [{{ item.dataobj.jiedan_jiedan_obj.join(",") }}]
            </div>
          </div>
          <div class="lt-td data">
            <div>{{ item.dataobj.fuzhujiedan_receipt }}</div>
            <div
              v-if="
                'fuzhujiedan_receipt' in item.dataobj &&
                'fuzhujiedan_receipt_obj' in item.dataobj
              "
              class="objective-value"
            >
              [{{ item.dataobj.fuzhujiedan_receipt_obj.join(",") }}]
            </div>
          </div>
          <div class="lt-td data">
            <div>{{ item.dataobj.fuzhujiedan_jiedan }}</div>
            <div
              v-if="
                'fuzhujiedan_jiedan' in item.dataobj &&
                'fuzhujiedan_jiedan_obj' in item.dataobj
              "
              class="objective-value"
            >
              [{{ item.dataobj.fuzhujiedan_jiedan_obj.join(",") }}]
            </div>
          </div>
          <div class="lt-td data">
            <div>{{ item.dataobj.yewugendan_receipt }}</div>
            <div
              v-if="
                'yewugendan_receipt' in item.dataobj &&
                'yewugendan_receipt_obj' in item.dataobj
              "
              class="objective-value"
            >
              [{{ item.dataobj.yewugendan_receipt_obj.join(",") }}]
            </div>
          </div>
          <div class="lt-td data">
            <div>{{ item.dataobj.yewugendan_jiedan }}</div>
            <div
              v-if="
                'yewugendan_jiedan' in item.dataobj &&
                'yewugendan_jiedan_obj' in item.dataobj
              "
              class="objective-value"
            >
              [{{ item.dataobj.yewugendan_jiedan_obj.join(",") }}]
            </div>
          </div>
          <div class="lt-td data">
            <div>{{ item.dataobj.gendan_shipment }}</div>
            <div
              v-if="
                'gendan_shipment' in item.dataobj &&
                'gendan_shipment_obj' in item.dataobj
              "
              class="objective-value"
            >
              [{{ item.dataobj.gendan_shipment_obj.join(",") }}]
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <ReturnBack></ReturnBack>
  </div>
</template>

<script>
// @ is an alias to /src
import ReturnBack from "@/components/ReturnBack.vue";
import { getPerformanceData, getRangeObj } from "@/api/user";
export default {
  name: "Performance",
  components: { ReturnBack },
  data: function () {
    return {
      data: [],
      range: "this_year",
      range_fetched: null,
      range_obj: {},
    };
  },
  watch: {
    range(v) {
      this.getperformancedata(v);
    },
  },
  methods: {
    getperformancedata(v) {
      this.data = this.data.map((v) => {
        v.amount = ".";
        return v;
      });
      if (!this.range_obj[v]) {
        return;
      }
      let time_range_id = this.range_obj[v]["id"];
      this.range_fetched = null;
      getPerformanceData(time_range_id).then((res) => {
        this.data = res.content.data.map((v) => {
          let dataobj = {};
          for (let i = 0; i < v.data.length; i++) {
            dataobj[v.data[i]["roleandtype"]] = v.data[i]["amount"];
          }
          for (let i = 0; i < v.objectives.length; i++) {
            dataobj[
              v.objectives[i]["role"] +
                "_" +
                v.objectives[i]["obj_type"] +
                "_obj"
            ] = [
              v.objectives[i]["obj1"],
              v.objectives[i]["obj2"],
              v.objectives[i]["obj3"],
            ];
            console.log(v.objectives);
          }
          v.dataobj = dataobj;
          return v;
        });
        console.log(this.data);
        this.range_fetched = v;
      });
    },
  },
  mounted: function () {
    document.title = "绩效统计";
    getRangeObj().then((res) => {
      this.range_obj = res.content;
      this.getperformancedata(this.range);
    });
  },
};
</script>

<style lang="less" scoped>
.title-pan {
  .title-pan-text {
    white-space: nowrap;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 760px;
  height: 50px;
  background: #fff;
  padding: 10px 5px;
  border-bottom: 1px solid #eee;
  z-index: 999;
  .filter-buttons {
    display: flex;
    overflow-x: hidden;
    .filter-button {
      flex-shrink: 0;
      padding: 5px 10px;
      background: #eee;
      margin: 0 2px;
      border-radius: 5px;
      font-size: 0.8em;
      &.active {
        background: #cce5ff;
      }
    }
  }
}
.data-title {
  padding: 9px 0;
}
.data-content {
  width: 100%;
  overflow-x: auto;
  .lt-head {
    .lt-tr {
      border-top: 1px solid #bbb;
    }
  }
  .lt-head,
  .lt-body {
    .lt-tr {
      justify-content: flex-start;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      .lt-td.data {
        width: 110px;
        border-left: 1px solid #ddd;
      }
      .lt-td.name {
        width: 80px;
      }
      .lt-td {
        flex-shrink: 0;
        text-align: center;
        .objective-value {
          font-size: 0.9em;
        }
      }
      &.even {
        background: #f3f3f3;
      }
    }
  }
}
</style>