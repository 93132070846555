<template>
  <div class="add-customer">
    <div class="form">
      <div class="form-little-title">新增客户</div>
      <InputItem labelname="客户代码" v-model="customer.code"></InputItem>
      <InputItem labelname="客户别称" v-model="customer.alias_name"></InputItem>
      <InputItem labelname="客户全名" v-model="customer.name"></InputItem>
      <InputItem labelname="国家" v-model="customer.country"></InputItem>
      <InputItem labelname="市场" v-model="customer.market"></InputItem>
      <SelectItem
        labelname="接单人"
        :selectlist="jiedanList"
        clearable
        v-model="customer.jiedan"
      ></SelectItem>
      <SelectItem
        labelname="辅助接单"
        :selectlist="fuzhujiedanList"
        clearable
        v-model="customer.fuzhujiedan"
        multiple
        v-if="!$store.state.roles_disabled.includes('fuzhujiedan')"
      ></SelectItem>
      <DatetimeItem
        labelname="创建日期"
        title="创建日期"
        v-model="customer.create_time"
      ></DatetimeItem>
      <br />
    </div>
    <div class="confirm-panel">
      <Button @click="canceloperation" class="left">取消</Button>
      <Button type="primary" @click="savecustomer" class="right">新增</Button>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";
import InputItem from "@/components/InputItem.vue";
import SelectItem from "@/components/SelectItem.vue";
import DatetimeItem from "@/components/DatetimeItem.vue";
import { addcustomer } from "@/api/customer";
import { getalltypeofuserlistfs } from "@/api/user";
export default {
  props: {},
  components: { Button, InputItem, SelectItem, DatetimeItem },
  data: function () {
    return {
      customer: { name: null, area: null, create_time: new Date() },
      jiedanList: [],
      fuzhujiedanList: [],
      jiedan: null,
      fuzhujiedan: null,
    };
  },
  methods: {
    savecustomer() {
      if (!this.customer.code) {
        this.$store.commit("add_notification", {
          msg: "请填写客户代码！",
        });
        return;
      }
      if (!this.customer.alias_name) {
        this.$store.commit("add_notification", {
          msg: "请填写客户别名！",
        });
        return;
      }
      if (!this.customer.name) {
        this.$store.commit("add_notification", {
          msg: "请填写客户全名！",
        });
        return;
      }
      if (!this.customer.country) {
        this.$store.commit("add_notification", {
          msg: "请填写所在国家！",
        });
        return;
      }
      if (!this.customer.market) {
        this.$store.commit("add_notification", {
          msg: "请填写所在市场！",
        });
        return;
      }
      if (!this.customer.jiedan) {
        this.$store.commit("add_notification", {
          msg: "请选择接单人！",
        });
        return;
      }
      let customer = this.customer;
      this.$store.commit("show_loading", "正在提交数据，请稍等......");
      addcustomer(customer)
        .then((res) => {
          this.$emit("saved", res.content);
          console.log(res);
        })
        .finally(() => {
          this.$store.commit("hide_loading");
        });
    },
    canceloperation() {
      this.$emit("canceled");
    },
  },
  mounted: function () {
    getalltypeofuserlistfs().then((res) => {
      this.jiedanList = res.content.jiedanList;
      this.fuzhujiedanList = res.content.fuzhujiedanList;
    });
  },
};
</script>
<style lang='less' scoped>
.add-customer {
  height: 100%;
  position: relative;
  .form {
    height: calc(100% - 45px);
    overflow-y: scroll;
  }
  .confirm-panel {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    height: 45px;
    width: 100%;
    .v-button {
      flex: 1 1 0;
      border-radius: 0px;
    }
    .v-button.left {
      border-bottom-left-radius: 7px;
    }
    .v-button.right {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 7px;
    }
    .v-button.middle {
      border-radius: 0px;
    }
  }
}
</style>