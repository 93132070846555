import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode';

Vue.use(Vuex)
//const debug = process.env.NODE_ENV !== "production";
const debug = false;

const token = window.localStorage.getItem('id_token');
const user_id = token ? decode(token).data.id : null;

export default new Vuex.Store({
  state: {
    token: token,
    user_id: user_id,
    attach_domain: null,
    notifications: [],
    loading: false,
    loading_msg: '正在提交数据',
    enable_logistics: false,
    roles_disabled: [],
    user: null,
    super_user: false,
    version: 5.98,
  },
  mutations: {
    login(state, token) {
      state.token = token;
      state.user_id = decode(token).data.id;
      window.localStorage.setItem('id_token', token);
    },
    logout(state) {
      state.token = null;
      state.user_id = null;
      window.localStorage.removeItem('id_token');
    },
    add_notification(state, obj) {
      if (!obj.status) {
        obj.status = 'warning';
      }
      obj.key = Math.random();
      state.notifications.unshift(obj);
      window.setTimeout(() => {
        state.notifications.pop();
      }, obj.seconds ? obj.seconds * 1000 : 2000);
    },
    show_loading(state, msg) {
      state.loading = true;
      state.loading_msg = msg;
    },
    hide_loading(state) {
      state.loading = false;
      state.loading_msg = '';
    },
    set_logistics_enabled(state, v) {
      state.enable_logistics = v;
    },
    set_roles_disabled(state, roles) {
      state.roles_disabled = roles;
    },
    set_super_user(state, v) {
      state.super_user = v;
    },
    set_user(state, user) {
      state.user = user;
    }
  },
  actions: {
  },
  modules: {
  },
  strict: debug
})
