<template>
  <div>
    <div class="add-order" @click="() => (showAddPop = true)">
      <svg-icon iconName="add"></svg-icon>
    </div>
    <div class="addpop" v-if="showAddPop">
      <div
        class="addpop-mask"
        v-on:click.stop="() => (showAddPop = false)"
      ></div>
      <div class="addpop-content">
        <div class="addpop-inner">
          <div class="add">
            <router-link to="/createorder">接单</router-link>
          </div>
          <div class="add">
            <router-link to="/batchshipment">出货</router-link>
          </div>
          <!--
          <div class="add">
            <router-link to="/batchreceipt">收款</router-link>
          </div>
          -->
        </div>
        <div class="addpop-arrow"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { url: String },
  data: function () {
    return {
      showAddPop: false,
    };
  },
  methods: {
    gotourl() {
      this.$router.push("/createorder");
    },
  },
};
</script>
<style lang='less' scoped>
.add-order {
  position: absolute;
  right: 25px;
  bottom: 70px;
  width: 45px;
  height: 45px;
  color: #aaa;
  border-radius: 50%;
  opacity: 0.85;
  .svg-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 2px;
    right: 2px;
  }
}
&:active {
  color: #33a3f4;
}
.addpop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.addpop-mask {
  position: relative;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.addpop-content {
  position: absolute;
  right: 80px;
  bottom: 75px;
  z-index: 1999;
}
.addpop-arrow {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 1px;
  background-color: #fff;
  transform: translate(-50%, 0) rotate(225deg);
  right: -7px;
  bottom: 15px;
}
.addpop-inner {
  font-size: 15px;
  color: #000;
  background-color: #fff;
  border-radius: 3px;
  padding: 10px 4px;
}
div.add a {
  color: #333;
  text-decoration: none;
  &:active {
    color: #33a3f4;
  }
}
div.add {
  padding: 6px 22px;
  border-top: 1px solid #ddd;
}
div.add-break-line {
  border-top: 1px solid #ddd;
}
</style>