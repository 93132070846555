<template>
  <div class="sales main-container">
    <div>
      <SearchBar v-model="keyword"></SearchBar>
      <ListItem
        v-for="(item, key) in changelogs"
        :key="key"
        :index="key + 1"
        @click.native="() => showitem(item)"
        v-show="
          !keyword ||
          item.summary.includes(keyword) ||
          item.remark.includes(keyword)
        "
      >
        <template v-slot:title>{{ item.summary }}</template>
        <template v-slot:extra><div>.</div> </template>
        <template v-slot:brief>
          <div>
            {{ item.remark }}
          </div>
          <div>
            <div class="inline-item">操作者: {{ item.operator_name }}</div>
            <div class="inline-item">
              操作时间:
              {{ new Date(item.update_time).format("yyyy-M-d hh:mm:ss") }}
            </div>
          </div>
        </template>
      </ListItem>
    </div>
    <!-- 订单 -->
    <div class="modal" v-show="modalshow > 0">
      <div class="modal-mask"></div>
      <div class="modal-2" v-if="modalshow === 2">
        <div class="content">
          <EditOrder
            :order_id="activeitem.key_value"
            v-if="activeitem && activeitem.table_name === 'orders'"
          ></EditOrder>
          <EditShipment
            :shipment_id="activeitem.key_value"
            v-if="activeitem && activeitem.table_name === 'shipments'"
          ></EditShipment>
        </div>
        <div class="exit-edit" @click="closeedit">
          <svg-icon iconName="close"></svg-icon>
        </div>
      </div>
    </div>
    <ReturnBack></ReturnBack>
    <Dialog v-if="dialogshow" :dialogprops="dialogprops"></Dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import ListItem from "@/components/ListItem.vue";
import SearchBar from "@/components/SearchBar.vue";
import EditOrder from "@/components/EditOrder.vue";
import EditShipment from "@/components/EditShipment.vue";
import Dialog from "@/components/Dialog.vue";
import ReturnBack from "@/components/ReturnBack.vue";
import { getchangelogs } from "@/api/other";

export default {
  name: "Sales",
  components: {
    ListItem,
    SearchBar,
    EditOrder,
    EditShipment,
    Dialog,
    ReturnBack,
  },
  data: function () {
    return {
      keyword: null,
      activeitem: null,
      changelogs: [],
      modalshow: 0,
      dialogshow: false,
      dialogprops: {
        title: "",
        desc: "",
        cancel: () => {},
        confirm: () => {},
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    getchangelogs() {
      getchangelogs().then((res) => {
        this.changelogs = res.content;
      });
    },
    showitem(item) {
      if (item.table_name === "orders" || item.table_name === "shipments") {
        this.activeitem = item;
        this.modalshow = 2;
        console.log(item);
      }
    },
    closeedit() {
      this.modalshow = 0;
      this.activeitem = null;
      this.dialogshow = false;
      this.getchangelogs();
    },
  },
  mounted: function () {
    document.title = "修改记录";
    this.getchangelogs();
  },
};
</script>

<style lang="less">
</style>