<template>
  <div class="batch-view main-container">
    <div class="form-little-title">出货</div>
    <DatetimeItem
      :labelname="`出货日期`"
      title="`出货日期`"
      v-model="effective_time"
    ></DatetimeItem>
    <SelectItem
      labelname="客户"
      :selectlist="customerList"
      v-model="customer"
      searchable
    ></SelectItem>
    <br />
    <div class="single-order-input">
      <SelectItem
        labelname="订单"
        :selectlist="orderList"
        v-model="order"
        vertical
        class="order"
        searchable
      ></SelectItem>
      <InputItem
        labelname="金额"
        v-model="ship_amount"
        type="number"
        vertical
        class="amount"
      ></InputItem>
      <div class="stage-button">
        <div class="button" @click="cacheitem">暂存</div>
      </div>
    </div>
    <div v-if="order" :style="{ 'margin-left': '5px' }">
      订单 {{ order.order_no }} 总金额为 {{ order.amount }}，已累计出货
      {{ order["ship_amount"] }}，还差{{ order.amount - order["ship_amount"] }}
    </div>
    <div v-else :style="{ visibility: 'hidden' }">订单</div>
    <br />
    <div class="form-little-title">已暂存出货记录</div>
    <div
      v-show="itemscached.length === 0"
      :style="{ 'text-align': 'center', color: '#ccc' }"
    >
      无
    </div>
    <div class="stage-area" v-show="itemscached.length">
      <div class="lt-head">
        <div class="lt-tr">
          <div class="lt-td">订单合同</div>
          <div class="lt-td">出货金额</div>
          <div class="lt-td">完成</div>
        </div>
      </div>
      <div class="lt-body">
        <div class="lt-tr" v-for="(item, k) in itemscached" :key="k">
          <div class="lt-td">{{ item.order_no }}</div>
          <div class="lt-td">{{ item.amount }}</div>
          <div class="lt-td"><input type="checkbox" v-model="item.all" /></div>
        </div>
        <div
          class="lt-tr"
          :style="{ 'font-weight': 'bold' }"
          v-show="itemscached.length > 1"
        >
          <div class="lt-td">总金额</div>
          <div class="lt-td">{{ total_amount_cached }}</div>
        </div>
      </div>
    </div>

    <br />
    <InputItem labelname="出货编号" v-model="shipment_title"></InputItem>
    <br />
    <div class="two-buttons-group">
      <Button @click.native="goBack">取消</Button>
      <Button type="primary" @click="batchshipment">批量出货</Button>
    </div>
    <br />
    <br />
    <div>
      说明：同一天内，同一个客户有多个订单同时出货适合在这里操作；如果只是某个订单出货，去订单列表单击订单操作更方便
    </div>
    <Dialog v-if="dialogshow" :dialogprops="dialogprops"></Dialog>
  </div>
</template>
<script>
import InputItem from "@/components/InputItem.vue";
import SelectItem from "@/components/SelectItem.vue";
import DatetimeItem from "@/components/DatetimeItem.vue";
import Button from "@/components/Button.vue";
import Dialog from "@/components/Dialog.vue";
import { getcustomerlist, getcustomerorderlist } from "@/api/customer";
import { batchshipment } from "@/api/order";

export default {
  components: { InputItem, SelectItem, DatetimeItem, Button, Dialog },
  data: function () {
    return {
      customerList: [],
      customer: null,
      effective_time: new Date(),
      ship_amount: null,
      orderList: [],
      order: null,
      exchange_rate: 0.65,
      currency: "美元",
      itemscached: [],
      dialogshow: false,
      dialogprops: {},
      shipment_title: null,
    };
  },
  watch: {
    customer(v) {
      let customer_id = v.value;
      this.orderList = [];
      this.order = null;
      this.itemscached = [];
      getcustomerorderlist(customer_id).then((res) => {
        this.orderList = res.content.filter((v) => !v.allshipped);
      });
    },
    effective_time(v) {
      console.log(v);
      this.itemscached = [];
    },
  },
  computed: {
    total_amount_cached() {
      return this.itemscached.reduce((total, cv) => {
        return total + cv.amount;
      }, 0);
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    cacheitem() {
      if (!this.order) {
        this.$store.commit("add_notification", {
          msg: "请先选择订单",
        });
        return;
      }
      let ship_amount = parseFloat(this.ship_amount);
      if (!ship_amount) {
        ship_amount = 0;
      }
      if (!ship_amount) {
        this.$store.commit("add_notification", {
          msg: `请填写出货金额`,
        });
        return;
      }
      let total_amount = this.order["amount"];
      let already_amount = this.order["ship_amount"];
      let effective_time = new Date(this.effective_time).format(
        "yyyy-MM-dd hh:mm:ss"
      );
      let all = false;
      if (already_amount + ship_amount >= total_amount) {
        all = true;
      }
      let that = this;
      let f = () => {
        if (that.itemscached.length === 0) {
          that.shipment_title = that.order.order_no;
        } else if (that.itemscached.length === 1) {
          that.shipment_title = that.shipment_title + "等";
        }
        that.itemscached.push({
          order_id: that.order.id,
          order_no: that.order.order_no,
          amount: ship_amount,
          effective_time: effective_time,
          all: all,
        });
        that.orderList = that.orderList.filter((v) => v.id !== that.order.id);
        that.order = null;
        that.ship_amount = null;
      };
      if (already_amount + ship_amount > total_amount) {
        this.dialogprops = {
          title: "金额异常",
          desc:
            "此次出货 " +
            ship_amount +
            " 后累计出货金额 " +
            (already_amount + ship_amount) +
            " 大于订单总金额 " +
            total_amount +
            " 请确认！",
          confirmtext: "确认出货",
          cancel: () => {
            that.dialogshow = false;
          },
          confirm: f,
        };
        this.dialogshow = true;
        return;
      }
      f();
    },
    batchshipment() {
      if (!this.itemscached.length) {
        this.$store.commit("add_notification", {
          msg: `请先暂存出货记录`,
        });
        return;
      }
      this.$store.commit("show_loading", "正在提交数据，请稍等......");
      let data = {
        shipment_title: this.shipment_title,
        amount: this.total_amount_cached,
        currency: this.currency,
        exchange_rate: this.exchange_rate,
        effective_time: this.effective_time,
        remark: "",
        customer_id: this.customer.value,
        order_shipments: this.itemscached,
      };
      batchshipment(data)
        .then(() => {
          this.$store.commit("hide_loading");
          this.goBack();
        })
        .catch((e) => {
          this.$store.commit("hide_loading");
          this.$store.commit("add_notification", {
            msg: "提交失败：" + e,
          });
        });
    },
  },
  mounted: function () {
    document.title = "出货";
    getcustomerlist().then((res) => {
      let customerlist = res.content;
      this.customerList = customerlist;
    });
  },
};
</script>
<style lang='less'>
.batch-view {
  .single-order-input {
    display: flex;
    align-items: center;
    .order {
      width: 55%;
      padding-left: 5px;
    }
    .amount {
      width: 30%;
      padding-left: 5px;
    }
    .stage-button {
      width: 15%;
      padding: 0 3px 0 5px;
      align-self: stretch;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #108ee9;
        color: #fff;
        border-radius: 5px;
        height: 100%;
        width: 100%;
        &:active {
          background-color: #505339;
        }
      }
    }
  }
  .single-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .narrow {
      border-bottom: 1px solid #ddd;
      .input-label {
        min-width: 32px;
      }
    }
  }
  .stage-area {
    margin: 10px 15px;
    .lt-head,
    .lt-body {
      .lt-tr {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding: 6px 0;
      }
    }
  }
}
</style>

