<template>
  <div class="users main-container">
    <div class="title">所有员工</div>
    <div class="staff">
      <div class="lt-head">
        <div class="lt-tr">
          <div class="lt-td no">序号</div>
          <div class="lt-td name">姓名</div>
          <div class="lt-td role">当前角色</div>
          <div class="lt-td operate">修改</div>
        </div>
      </div>
      <div class="lt-body">
        <div class="lt-tr" v-for="(item, k) in users" :key="item.id">
          <div class="lt-td no">{{ k + 1 }}</div>
          <div class="lt-td name">{{ item.name }}</div>
          <div class="lt-td role">{{ item.roles.join(", ") }}</div>
          <div class="lt-td operate">
            <svg-icon
              iconName="edit"
              @click.native="() => edituser(item)"
            ></svg-icon>
          </div>
        </div>
      </div>
      <br />
      <Button type="primary" @click="syncworkwx">手动同步企业微信</Button>
      <br />
      <div>
        一般企业微信新增员工之后会自动同步到该系统，如果信息不一致，可以手动同步!
      </div>
    </div>
    <div class="modal" v-show="modalshow > 0">
      <div class="modal-mask"></div>
      <div class="modal-2" v-if="modalshow === 2">
        <div class="content">
          <div class="user-name">{{ activeuser["name"] }}</div>
          <div class="lt-head">
            <div class="lt-tr">
              <div class="lt-td role">角色</div>
              <div class="lt-td enable-status">是否启用</div>
              <div class="lt-td coefficient">系数</div>
            </div>
          </div>
          <div class="lt-body">
            <div class="lt-tr" v-for="item in roles" :key="item.role">
              <div class="lt-td role">{{ item.role_name }}</div>
              <div class="lt-td enable-status">
                <input
                  type="radio"
                  :id="`${item.role}-enabled`"
                  :value="true"
                  v-model="item.enabled"
                />
                <label :for="`${item.role}-enabled`">启用</label>
                <input
                  type="radio"
                  :id="`${item.role}-disabled`"
                  :value="false"
                  v-model="item.enabled"
                />
                <label :for="`${item.role}-disabled`">禁用</label>
              </div>
              <div class="lt-td coefficient" v-if="item.editable">
                <input type="number" v-model="item.coefficient" />
              </div>
              <div class="lt-td coefficient" v-else></div>
            </div>
          </div>
          <br />
          <Button type="primary" @click="saveuserroles">保存</Button>
        </div>
        <div class="exit-edit" @click="closeedit">
          <svg-icon iconName="close"></svg-icon>
        </div>
      </div>
    </div>
    <ReturnBack></ReturnBack>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";
import ReturnBack from "@/components/ReturnBack.vue";
import {
  getAllUserList,
  getSingleUserRoles,
  saveUserRoles,
  syncWorkWx,
} from "@/api/user";

export default {
  name: "Users",
  components: { Button, ReturnBack },
  data: function () {
    return {
      users: [],
      activeuser: null,
      modalshow: 0,
      enabled: false,
      roles: [],
    };
  },
  methods: {
    edituser(user) {
      this.activeuser = user;
      this.getsingleuserroles(user.id);
      this.modalshow = 2;
      console.log(this.activeuser);
    },
    closeedit() {
      this.modalshow = 0;
      this.roles = [];
    },
    syncworkwx() {
      syncWorkWx().then(() => {
        getAllUserList().then((res) => {
          this.users = res.content;
        });
      });
    },
    getsingleuserroles(user_id) {
      getSingleUserRoles(user_id).then((res) => {
        let c = res.content;
        let roles_disabled = this.$store.state.roles_disabled;
        console.log(roles_disabled)
        let roles = [
          {
            role: "jiedan",
            role_name: "接单人",
            coefficient: c["jiedan"]["coefficient"],
            enabled: c["jiedan"]["enabled"],
            editable: false,
          },
        ];
        if (!roles_disabled.includes("fuzhujiedan")) {
          roles.push({
            role: "fuzhujiedan",
            role_name: "辅助接单",
            coefficient: c["fuzhujiedan"]["coefficient"],
            enabled: c["fuzhujiedan"]["enabled"],
            editable: true,
          });
        }
        if (!roles_disabled.includes("yewugendan")) {
          roles.push({
            role: "yewugendan",
            role_name: "业务跟单",
            coefficient: c["yewugendan"]["coefficient"],
            enabled: c["yewugendan"]["enabled"],
            editable: false,
          });
        }
        if (!roles_disabled.includes("gendan")) {
          roles.push({
            role: "gendan",
            role_name: "跟单人",
            coefficient: c["gendan"]["coefficient"],
            enabled: c["gendan"]["enabled"],
            editable: false,
          });
        }
        if (!roles_disabled.includes("fuzhugendan")) {
          roles.push({
            role: "fuzhugendan",
            role_name: "辅助跟单",
            coefficient: c["fuzhugendan"]["coefficient"],
            enabled: c["fuzhugendan"]["enabled"],
            editable: false,
          });
        }
        this.roles = roles;
      });
    },
    saveuserroles() {
      let user_id = this.activeuser.id;
      let roles = this.roles;
      let data = { user_id, roles };
      let that = this;
      that.$store.commit("show_loading", "正在提交数据，请稍等......");
      saveUserRoles(data)
        .then(() => {
          that.$store.commit("hide_loading");
          that.$store.commit("add_notification", {
            msg: "保存成功",
            status: "success",
          });
          getAllUserList().then((res) => {
            that.users = res.content;
          });
          that.closeedit();
        })
        .catch((e) => {
          that.$store.commit("hide_loading");
          that.$store.commit("add_notification", {
            msg: "保存失败" + e,
            status: "error",
          });
        });
    },
  },
  mounted: function () {
    getAllUserList().then((res) => {
      this.users = res.content;
    });
  },
};
</script>
<style scoped lang="less">
.users {
  .title {
    font-size: 1.1em;
    margin: 10px;
  }
  padding: 5px;
  .staff {
    margin: 6px 10px;
    .lt-head,
    .lt-body {
      .lt-tr {
        justify-content: center;
        text-align: center;
        align-items: center;
        .no {
          width: 40px;
          flex-shrink: 0;
        }
        .name {
          width: 70px;
          flex-shrink: 0;
        }
        .role {
          flex-grow: 1;
        }
        .operate {
          flex-shrink: 0;
          width: 50px;
          .svg-icon {
            width: 22px;
            height: 22px;
            &:active {
              color: blue;
            }
          }
        }
      }
    }
  }
  .modal {
    .modal-2 {
      .content {
        padding: 10px;
        .user-name {
          text-align: center;
          font-size: 1.1em;
        }
      }
      .lt-tr {
        align-items: center;
        justify-content: center;
        text-align: center;
        .role {
          width: 80px;
        }
        .enable-status {
          flex-grow: 1;
        }
        .coefficient {
          width: 60px;
          input {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
