<template>
  <div class="search">
    <input
      type="search"
      class="search-value"
      placeholder="搜索"
      maxlength="16"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: [String, Number],
    disabled: Boolean,
  },
};
</script>
<style lang="less" scoped>
div.search {
  padding: 4px 0;
  background-color: #efeff4;
  input {
    width: 100%;
    font-size: 14px;
    width: 100%;
    border: 1px solid #efeff4;
    border-radius: 0.25rem;
    padding: 5px;
    line-height: 1;
    outline: none;
  }
}
</style>