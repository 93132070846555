<script>
import Gesture from "@/components/vue-gesture";
export default {
  name: "Tabs",
  props: { tabs: Array, sizeobj: Object, currentTab: Number },
  components: { Gesture },
  data: function () {
    return {
      contentPos: this.getContentPosByIndex(this.currentTab),
      isMoving: false,
      lastOffset: 0,
      testlog: "",
    };
  },
  computed: {
    size: function () {
      return 100 / this.tabs.length;
    },
  },
  mounted: function () {
    this.prevCurrentTab = this.currentTab;
    this.lastOffset =
      0 - this.currentTab * this.$refs.tabbarcontent.clientWidth;
  },
  updated: function () {
    this.prevCurrentTab = this.currentTab;
  },
  methods: {
    onSwipe(status) {
      switch (status.direction) {
        case 2:
          this.goToTab(this.prevCurrentTab + 1);
          //this.testlog = "向右滑动"
          break;
        case 4:
          this.goToTab(this.prevCurrentTab - 1);
          //this.testlog = "向左滑动"
          break;
      }
    },
    onPanStart() {
      this.isMoving = true;
      let offset = +`${this.lastOffset}`.replace("%", "");
      if (`${this.lastOffset}`.indexOf("%") >= 0) {
        offset /= 100;
        offset *= this.$refs.tabbarcontent.clientWidth;
      }
      this.finalOffset = offset;
      //this.testlog = "开始滑动"
    },
    onPanMove(status) {
      //console.log(Math.abs(status.moveStatus.x), Math.abs(status.moveStatus.y));
      if (Math.abs(status.moveStatus.x) > Math.abs(status.moveStatus.y) * 2) {
        let offset = +`${this.lastOffset}`.replace("%", "");
        if (`${this.lastOffset}`.indexOf("%") >= 0) {
          offset /= 100;
          offset *= this.$refs.tabbarcontent.clientWidth;
        }
        offset += status.moveStatus.x;
        const canScrollOffset =
          -this.$refs.tabbarcontent.scrollWidth +
          this.$refs.tabbarcontent.clientWidth;
        offset = Math.min(offset, 0);
        offset = Math.max(offset, canScrollOffset);
        this.$refs.tabbarcontent.style.transform = `translate3d(${offset}px, 0px, 0px)`;
        this.finalOffset = offset;
      }
      //this.testlog = "滑动中..."
    },
    onPanEnd() {
      this.isMoving = false;
      const finalOffset = this.finalOffset;
      this.lastOffset = finalOffset;
      const threshold = 0.3;
      const width = this.$refs.tabbarcontent.clientWidth;
      const ratio = Math.abs(this.lastOffset / width);
      const direction = ratio > this.currentTab ? "<" : ">";
      const index = Math.floor(ratio);
      let offsetIndex;
      switch (direction) {
        case "<":
          offsetIndex = ratio - index > threshold ? index + 1 : index;
          break;
        case ">":
          offsetIndex = 1 - ratio + index > threshold ? index : index + 1;
          break;
        default:
          offsetIndex = Math.round(ratio);
      }
      //console.log(this.lastOffset, offsetIndex, this.currentTab);
      if (offsetIndex === this.currentTab) {
        this.$refs.tabbarcontent.style.transform = this.getContentPosByIndex(
          offsetIndex
        );
      } else {
        //console.log("no swipe, but changed");
        this.goToTab(offsetIndex);
      }
      //this.testlog = "滑动结束" + offsetIndex;
    },
    getContentPosByIndex(index) {
      const value = `${-index * 100}%`;
      this.lastOffset = value;
      // fix: content overlay TabBar on iOS 10. ( 0px -> 1px )
      //return `translate3d(${value}, 0px, 1px)`;  // 在ios中弹出框会被干扰，还是改成0px
      return `translate3d(${value}, 0px, 0px)`;
    },
    goToTab(index) {
      if (index < 0) {
        this.$emit("switchLeft");
      } else if (index === this.tabs.length) {
        this.$emit("switchRight");
      } else {
        //this.currentTab = index;
        this.contentPos = this.getContentPosByIndex(index);
        this.$emit("switchTab", index);
      }
      return true;
    },
  },
  render() {
    const contentStyle = {
      transform: this.contentPos,
      "-webkit-transform": this.contentPos,
      "-moz-transform": this.contentPos,
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      bottom: 0,
    };
    const size = 100 / this.tabs.length;
    const underlineProps = {
      style: {
        width: `${size}%`,
        left: `${size * this.currentTab}%`,
      },
      class: "vm-tabs-tab-bar-underline",
    };
    return (
      <div class="vm-tabs vm-tabs-horizontal vm-tabs-top">
        <div
          class="vm-tabs-tab-bar-wrap"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: this.sizeobj.barheight,
          }}
        >
          <div class="vm-tabs-tab-bar vm-tabs-tab-bar-animated vm-tabs-tab-bar-top">
            <div class="vm-tabs-tab-bar-content">
              {this.tabs.map((tab, index) => (
                <div
                  class={{
                    "vm-tabs-tab-bar-tab": true,
                    "vm-tabs-tab-bar-tab-active": index === this.currentTab,
                  }}
                  id={`vm-tabs-${index}`}
                  role="tab"
                  style={{ width: `${this.size}%` }}
                  onClick={() => this.goToTab(index)}
                >
                  <span class="vm-badge">
                    {tab.title}
                    {tab.text ? (
                      <sup class={"vm-badge-text " + tab.key}>{tab.text}</sup>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              ))}
              <div {...underlineProps}></div>
            </div>
          </div>
        </div>
        <Gesture
          onOnSwipe={this.onSwipe}
          onOnPanStart={this.onPanStart}
          onOnPanMove={this.onPanMove}
          onOnPanEnd={this.onPanEnd}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: this.sizeobj.contenttop,
              width: "100%",
              bottom: 0,
              /* 这个div是后面添加的，让Gesture事件作用在这个div上 */
              /* 子div在transform后在safari浏览器中无法检测到手势 */
            }}
          >
            <div
              class={{
                "vm-tabs-content-wrap": true,
                "vm-tabs-content-wrap-animated": !this.isMoving,
              }}
              style={contentStyle}
              ref="tabbarcontent"
            >
              {this.$slots.default.map((item) => (
                <div class="vm-tabs-pane-wrap">{item}</div>
              ))}
            </div>
          </div>
        </Gesture>
      </div>
    );
  },
};
</script>
<style lang='less' scoped>
@tabsPrefixClass: vm-tabs;
@tabBarPrefixClass: vm-tabs-tab-bar;
@easing-in-out: cubic-bezier(0.35, 0, 0.25, 1);
@effect-duration: 0.3s;
@tabs-color: #108ee9;
@page-hide-color: rgba(255, 255, 255, 0);
@page-show-color: rgba(255, 255, 255, 1);
.@{tabsPrefixClass} {
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  display: flex;
  flex: 1;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 50px;
  width: 100%;
  max-width: 760px;

  &-content-wrap {
    display: flex;
    flex: 1;
    width: 100%;
    /*height: 100%;*/

    &-animated {
      transition: transform @effect-duration @easing-in-out,
        left @effect-duration @easing-in-out,
        top @effect-duration @easing-in-out;
      will-change: transform, left, top;
    }
  }

  &-pane-wrap {
    width: 100%;
    flex-shrink: 0;
    overflow-y: auto;
  }

  &-tab-bar-wrap {
    flex-shrink: 0;
  }

  &-horizontal {
    .@{tabsPrefixClass} {
      &-pane-wrap-active {
        height: auto;
      }

      &-pane-wrap-inactive {
        height: 0;
        overflow: visible;
      }
    }
  }

  &-top,
  &-bottom {
    flex-direction: column;
  }
}

.common-pagination() {
  pointer-events: none;
  position: absolute;
  top: 0;
  display: block;
  width: 59px;
  height: 100%;
  content: " ";
  //z-index: 1;
}

.@{tabBarPrefixClass} {
  position: absolute;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  width: 100%;
  /*height: 100%;*/
  top: 0;
  height: 45px;
  overflow: visible;
  //z-index: 1;

  &-tab {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    line-height: 15px;
  }

  &-tab-active {
    color: @tabs-color;
  }

  &-underline {
    position: absolute;
    border: 1px @tabs-color solid;
  }

  &-animated &-content {
    transition: transform @effect-duration @easing-in-out;
    will-change: transform;
  }

  &-animated &-underline {
    transition: top @effect-duration @easing-in-out,
      left @effect-duration @easing-in-out,
      color @effect-duration @easing-in-out,
      width @effect-duration @easing-in-out;
    will-change: top, left, width, color;
  }

  &-top {
    flex-direction: row;

    .@{tabBarPrefixClass} {
      &-content {
        display: flex;
        width: 100%;
        flex-direction: row;
      }

      &-prevpage {
        .common-pagination();

        left: 0;
        background: linear-gradient(
          to right,
          @page-show-color,
          @page-hide-color
        );
      }

      &-nextpage {
        .common-pagination();

        right: 0;
        background: linear-gradient(
          to right,
          @page-hide-color,
          @page-show-color
        );
      }

      &-tab {
        padding: 8px 0;
      }

      &-underline {
        bottom: 0;
      }
    }
  }

  &-top {
    border-bottom: 1px #eee solid;
  }

  sup.vm-badge-text {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 4px;
    margin-left: 5px;
    height: 18px;
    line-height: 18px;
    min-width: 9px;
    border-radius: 12px;
    padding: 0 5px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    /*background-color: #ff5b05;*/
    background-color: rgb(241, 151, 54);
    white-space: nowrap;
    transform: translateX(-45%);
    transform-origin: -10% center;
    z-index: 10;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif;
  }
  sup.vm-badge-text.not_started {
    background-color:#a6a6a6;
  }
  sup.vm-badge-text.ongoing {
    background-color:#f19736;
  }
  sup.vm-badge-text.suspended {
    background-color: #993300;
  }
}
</style>