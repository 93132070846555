import request from "@/utils/request";

export function addcustomer(data) {
    return request.post("/addcustomer", { data });
}

export function getcustomerlist() {
    return request.get('/getcustomerlist', {});
}

export function getcustomerorderlist(customer_id) {
    return request.get('/getcustomerorderlist', {customer_id})
}

export function getsinglecustomer(customer_id) {
    return request.get('/getsinglecustomer', {customer_id})
}

export function updatecustomer(data) {
    return request.post("/updatecustomer", { data });
}