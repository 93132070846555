<template>
  <div class="exports main-container">
    <div class="export-button">
      <Button type="primary" @click="exportData">导出所有数据</Button>
    </div>
    <div class="export">
      <div class="lt-head">
        <div class="lt-tr">
          <div class="lt-td idx">序号</div>
          <div class="lt-td file-name">文件名</div>
          <!-- 
          <div class="lt-td export-time">导出时间</div>
          -->
          <div class="lt-td download">下载</div>
        </div>
      </div>
      <div class="lt-body">
        <div class="lt-tr" v-for="(item, idx) in exportlist" :key="item.id">
          <div class="lt-td idx">{{ idx + 1 }}</div>
          <div class="lt-td file-name">{{ item.file_name }}</div>
          <!-- 
          <div class="lt-td export-time">
            {{ new Date(item.create_time).format('yyyy-MM-dd hh:mm:ss')}}
          </div>
          -->
          <div class="lt-td download">
            <a :href="baseurl + '/downloadfile?filename=' + item.file_name"
              ><svg-icon iconName="download"></svg-icon
            ></a>
          </div>
        </div>
      </div>
      <br />
    </div>
    <ReturnBack></ReturnBack>
  </div>
</template>
<script>
import ReturnBack from "@/components/ReturnBack.vue";
import Button from "@/components/Button.vue";
import { getexportlist, exportdata, downloadfile } from "@/api/other";
export default {
  name: "Exports",
  components: { ReturnBack, Button },
  data: function () {
    return {
      exportlist: [],
      baseurl: process.env.VUE_APP_API_URL || `${location.origin}/api`,
    };
  },
  methods: {
    exportData() {
      let that = this;
      exportdata().then(() => {
        that.getExportList();
      });
    },
    getExportList() {
      getexportlist().then((res) => {
        let exportlist = res.content;
        this.exportlist = exportlist;
      });
    },
    downloadFile(filename) {
      console.log(filename);
      downloadfile(filename).then((res) => {
        console.log(res);
      });
    },
  },
  mounted: function () {
    this.getExportList();
  },
};
</script>
<style scoped lang="less">
.exports {
  .export-button {
    padding: 20px;
  }
  .export {
    margin: 6px 10px;
    .lt-head,
    .lt-body {
      .lt-tr {
        justify-content: center;
        text-align: center;
        align-items: center;
        .idx {
          width: 20%;
          flex-shrink: 0;
        }
        .file-name {
          width: 60%;
          flex-shrink: 0;
        }
        .export-time {
          flex-grow: 1;
          width: 40%;
        }
        .download {
          flex-shrink: 0;
          width: 20%;
          .svg-icon {
            width: 22px;
            height: 22px;
            &:active {
              color: blue;
            }
          }
        }
      }
    }
  }
}
</style>
