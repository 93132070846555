<template>
  <div class="periods main-container">
    <div class="input-item">
      <div class="input-label">年度</div>
      <div class="input-control year">
        <div class="minus" @click="() => (year = year - 1)">-</div>
        <div class="year-n">{{ year }}</div>
        <div class="add" @click="() => (year = year + 1)">+</div>
      </div>
    </div>
    <br />
    <DatetimeItem
      :labelname="`${year}年度开始日期`"
      :title="`${year}年度开始日期`"
      v-model="first_month_start"
      :disabled="month_start_disabled"
    ></DatetimeItem>
    <div v-show="!month_start_disabled" class="button-div">
      <Button type="primary" @click="createmonthranges"
        >确定{{ year }}年度开始日期</Button
      >
    </div>
    <br />
    <DatetimeItem
      :labelname="`${year}年度第1周开始日期`"
      :title="`${year}年度第1周开始日期`"
      v-model="first_week_start"
      :disabled="week_start_disabled"
    ></DatetimeItem>
    <div v-show="!week_start_disabled" class="button-div">
      <Button type="primary" @click="createweekranges"
        >确定{{ year }}年度第1周开始日期</Button
      >
    </div>
    <ReturnBack></ReturnBack>
  </div>
</template>
<script>
import ReturnBack from "@/components/ReturnBack.vue";
import DatetimeItem from "@/components/DatetimeItem.vue";
import Button from "@/components/Button.vue";
import { getPeriodData, createTimeRanges } from "@/api/user";

export default {
  name: "Periods",
  components: { ReturnBack, DatetimeItem, Button },
  data: function () {
    return {
      year: new Date().getFullYear(),
      first_week_start: null,
      first_month_start: null,
      week_start_disabled: true,
      month_start_disabled: true,
    };
  },
  watch: {
    year(v) {
      this.getperioddata(v);
    },
  },
  methods: {
    getperioddata() {
      this.first_week_start = null;
      this.first_month_start = null;
      getPeriodData(this.year).then((res) => {
        let week = res.content.week;
        let month = res.content.month;
        if (week) {
          this.week_start_disabled = true;
          this.first_week_start = new Date(week.start_time);
        } else {
          this.week_start_disabled = false;
        }
        if (month) {
          this.month_start_disabled = true;
          this.first_month_start = new Date(month.start_time);
        } else {
          this.month_start_disabled = false;
        }
      });
    },
    createmonthranges() {
      if (!this.first_month_start) {
        this.$store.commit("add_notification", {
          msg: `请先选择${this.year}年度开始日期`,
        });
        return;
      }
      let start_time = new Date(this.first_month_start).format(
        "yyyy-MM-dd 00:00:00"
      );
      let range_type = "month";
      let year = this.year;
      let data = { start_time, range_type, year };
      createTimeRanges(data).then(() => {
        this.getperioddata();
      });
    },
    createweekranges() {
      if (!this.first_week_start) {
        this.$store.commit("add_notification", {
          msg: `请先选择${this.year}年度第1周开始日期`,
        });
        return;
      }
      let start_time = new Date(this.first_week_start).format(
        "yyyy-MM-dd 00:00:00"
      );
      let range_type = "week";
      let year = this.year;
      let data = { start_time, range_type, year };
      createTimeRanges(data).then(() => {
        this.getperioddata();
      });
    },
  },
  mounted: function () {
    this.getperioddata();
  },
};
</script>

<style lang="less" scoped>
.periods {
  .year {
    display: flex;
    .minus,
    .add {
      border: 1px solid #ddd;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      &:active {
        background: #ddd;
      }
    }
    .year-n {
      margin: 0 20px;
    }
  }
  .button-div {
    width: 280px;
    margin: 0 auto;
  }
}
</style>