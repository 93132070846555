import request from "@/utils/request";

export function login(code) {
    return request.post("/login", { code });
}

export function heartbeat() {
    return request.get("/heartbeat", {});
}

export function getbasicconfig() {
    return request.get("/getbasicconfig", {})
}