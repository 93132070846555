<template>
  <div class="input-item">
    <div class="input-label">{{ labelname }}</div>
    <div class="input-control">
      <!--
      <Datetime
        type="datetime"
        :title="title"
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        format="yyyy-MM-dd HH:mm:ss"
        :phrases="{ok: '确定', cancel: '取消'}"
        :disabled="disabled"
      ></Datetime>
      -->
      <VueDatepickerLocal
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        :clearable="clearable"
        format="YYYY-MM-DD"
        :disabled="disabled"
        :title="title"
      ></VueDatepickerLocal>
    </div>
  </div>
</template>
<script>
//import { Datetime } from "vue-datetime";
import VueDatepickerLocal from "@/components/vue-datepicker-local";
//import "vue-datetime/dist/vue-datetime.css";
import "@/assets/css/item-common.css";
export default {
  components: { VueDatepickerLocal },
  props: {
    labelname: String,
    title: String,
    value: Date,
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      dt: "",
    };
  },
  methods: {
    input(e) {
      this.$emit("input", e);
    },
  },
};
</script>
<style>
.input-control .datepicker > input:disabled {
  background-color: #f6f6f6;
  border-color: #e6e6e6;
}
.input-control .datepicker {
  width: 100%;
}
.input-control .datepicker > input {
  border: 1px solid #ced4da;
}
</style>
