import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Sales from '../views/Sales.vue'
import Admin from '../views/Admin.vue'
import Statistics from '../views/Statistics.vue'
import CreateOrder from '../views/CreateOrder.vue'
import BatchShipment from '../views/BatchShipment.vue'
import BatchReceipt from '../views/BatchReceipt.vue'
import Users from '../views/Users.vue'
import Periods from '../views/Periods.vue'
import Objectives from '../views/Objectives.vue'
import Customers from '../views/Customers.vue'
import Exports from '../views/Exports.vue'
import Performance from '../views/Performance.vue'
import ChangeLogs from '../views/ChangeLogs.vue'
import OrderStats from '../views/OrderStats.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/createorder',
    name: 'CreateOrder',
    component: CreateOrder
  },
  {
    path: '/batchshipment',
    name: 'BatchShipment',
    component: BatchShipment
  },
  {
    path: '/batchreceipt',
    name: 'BatchReceipt',
    component: BatchReceipt
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/periods',
    name: 'Periods',
    component: Periods
  },
  {
    path: '/objectives',
    name: 'Objectives',
    component: Objectives
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/exports',
    name: 'Exports',
    component: Exports
  },
  {
    path: '/performance',
    name: 'Performance',
    component: Performance
  },
  {
    path: '/changelogs',
    name: 'ChangeLogs',
    component: ChangeLogs
  },
  {
    path: '/orderstats',
    name: 'OrderStats',
    component: OrderStats
  },
  /*
  {
    path: '/about',
    name: 'About',
    */
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //}
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
