<template>
  <div class="return-back" @click="goBack">
    <svg-icon iconName="back"></svg-icon>
  </div>
</template>

<script>
export default {
  props: { url: String },
  methods: {
    goBack() {
      if (this.url) {
        console.log(this.url, 'sss')
        this.$router.replace(this.url);
      }
      else if (window.history.length > 1) {
        this.$router.go(-1);
        console.log('back')
      } else {
        this.$router.push("/");
        console.log('home')
      }
    },
  },
};
</script>
<style lang='less' scoped>
.return-back {
  position: fixed;
  left: 25px;
  bottom: 25px;
  width: 45px;
  height: 45px;
  background-color: #ddd;
  border-radius: 50%;
  opacity: 0.85;
  .svg-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 2px;
    left: 2px;
  }
}
</style>