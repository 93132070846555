<template>
  <BatchShipOrReceipt action="收款"></BatchShipOrReceipt>
</template>
<script>
import BatchShipOrReceipt from "@/components/BatchShipOrReceipt.vue";

export default {
  components: { BatchShipOrReceipt },
  data: function () {
    return {};
  },
};
</script>
<style lang='less'>
</style>