<template>
  <div class="edit-order">
    <div class="form-little-title">修改订单信息</div>
    <div class="order-item">
      <InputItem
        labelname="合同编号"
        v-model="order_no"
        :disabled="!icons.order_no || icons.order_no.icon === 'edit'"
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.order_no ? icons.order_no.icon : 'edit'"
          @click.native="() => clickicon('order_no')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item">
      <SelectItem
        labelname="客户"
        :selectlist="customerList"
        v-model="customer"
        :disabled="!icons.customer || icons.customer.icon === 'edit'"
      ></SelectItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.customer ? icons.customer.icon : 'edit'"
          @click.native="() => clickicon('customer')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item">
      <DatetimeItem
        labelname="接单日期"
        title="接单日期"
        v-model="order_time"
        :disabled="!icons.order_time || icons.order_time.icon === 'edit'"
        :clearable="false"
      ></DatetimeItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.order_time ? icons.order_time.icon : 'edit'"
          @click.native="() => clickicon('order_time')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item" v-if="order.confirm_status >= 1">
      <DatetimeItem
        labelname="确认日期"
        title="确认日期"
        v-model="confirm_time"
        :disabled="!icons.confirm_time || icons.confirm_time.icon === 'edit'"
        :clearable="false"
      ></DatetimeItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.confirm_time ? icons.confirm_time.icon : 'edit'"
          @click.native="() => clickicon('confirm_time')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item" v-if="order.confirm_status === 2">
      <DatetimeItem
        labelname="定金日期"
        title="定金日期"
        v-model="deposit_time"
        :disabled="!icons.deposit_time || icons.deposit_time.icon === 'edit'"
        :clearable="false"
      ></DatetimeItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.deposit_time ? icons.deposit_time.icon : 'edit'"
          @click.native="() => clickicon('deposit_time')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item">
      <InputItem
        labelname="合同金额"
        v-model="amount"
        type="number"
        :disabled="!icons.amount || icons.amount.icon === 'edit'"
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.amount ? icons.amount.icon : 'edit'"
          @click.native="() => clickicon('amount')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item">
      <InputItem
        labelname="主要产品"
        v-model="products"
        :disabled="!icons.products || icons.products.icon === 'edit'"
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.products ? icons.products.icon : 'edit'"
          @click.native="() => clickicon('products')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item">
      <SelectItem
        labelname="接单人"
        :selectlist="jiedanList"
        v-model="jiedan"
        :disabled="!icons.jiedan || icons.jiedan.icon === 'edit'"
      ></SelectItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.jiedan ? icons.jiedan.icon : 'edit'"
          @click.native="() => clickicon('jiedan')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item" v-if="!$store.state.roles_disabled.includes('fuzhujiedan')">
      <SelectItem
        labelname="辅助接单"
        :selectlist="fuzhujiedanList"
        v-model="fuzhujiedan"
        :disabled="!icons.fuzhujiedan || icons.fuzhujiedan.icon === 'edit'"
        multiple
        clearable
      ></SelectItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.fuzhujiedan ? icons.fuzhujiedan.icon : 'edit'"
          @click.native="() => clickicon('fuzhujiedan')"
        ></svg-icon>
      </div>
    </div>
    <div
      class="order-item"
      v-if="!$store.state.roles_disabled.includes('fuzhujiedan') && false"
    >
      <InputItem
        class="narrow"
        labelname="辅助系数"
        v-model="fuzhucoefficient"
        :disabled="!icons.fuzhucoefficient || icons.fuzhucoefficient.icon === 'edit'"
        type="number"
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.fuzhucoefficient ? icons.fuzhucoefficient.icon : 'edit'"
          @click.native="() => clickicon('fuzhucoefficient')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item" v-if="!$store.state.roles_disabled.includes('yewugendan')">
      <SelectItem
        labelname="业务跟单"
        :selectlist="yewugendanList"
        v-model="yewugendan"
        :disabled="!icons.yewugendan || icons.yewugendan.icon === 'edit'"
        multiple
        clearable
      ></SelectItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.yewugendan ? icons.yewugendan.icon : 'edit'"
          @click.native="() => clickicon('yewugendan')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item" v-if="!$store.state.roles_disabled.includes('gendan')">
      <SelectItem
        labelname="跟单人"
        :selectlist="gendanList"
        v-model="gendan"
        :disabled="!icons.gendan || icons.gendan.icon === 'edit'"
        clearable
      ></SelectItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.gendan ? icons.gendan.icon : 'edit'"
          @click.native="() => clickicon('gendan')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item" v-if="!$store.state.roles_disabled.includes('fuzhugendan')">
      <SelectItem
        labelname="辅助跟单"
        :selectlist="fuzhugendanList"
        v-model="fuzhugendan"
        :disabled="!icons.fuzhugendan || icons.fuzhugendan.icon === 'edit'"
        clearable
      ></SelectItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.fuzhugendan ? icons.fuzhugendan.icon : 'edit'"
          @click.native="() => clickicon('fuzhugendan')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item">
      <InputItem
        labelname="订单来源"
        v-model="source"
        :disabled="!icons.source || icons.source.icon === 'edit'"
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.source ? icons.source.icon : 'edit'"
          @click.native="() => clickicon('source')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item" v-if="false">
      <SelectItem
        labelname="币种"
        :selectlist="currencyList"
        disabled
        v-model="currency"
      ></SelectItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.currency ? icons.currency.icon : 'edit'"
          @click.native="() => clickicon('currency')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item" v-if="false">
      <InputItem
        labelname="汇率"
        v-model="exchange_rate"
        disabled
        type="number"
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.exchange_rate ? icons.exchange_rate.icon : 'edit'"
          @click.native="() => clickicon('exchange_rate')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item">
      <div class="input-item short narrow">
        <div class="input-label">订单状态</div>
        <div class="input-control">
          <div class="input-text no-padding">
            <span
              v-for="item in [
                { value: false, label: '正常' },
                { value: true, label: '删除' },
              ]"
              :key="item.value"
            >
              <input
                type="radio"
                :id="`main-type-${item.value}`"
                :value="item"
                v-model="deleted"
                :disabled="!icons.deleted || icons.deleted.icon === 'edit'"
              />
              <label :for="`main-type-${item.value}`">{{ item.label }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.deleted ? icons.deleted.icon : 'edit'"
          @click.native="() => clickicon('deleted')"
        ></svg-icon>
      </div>
    </div>
    <br />
    <div class="add-customer-m" v-if="modalshow4customer">
      <AddCustomer
        v-on:saved="customersaved"
        v-on:canceled="canceloperation"
      ></AddCustomer>
      <!-- modal-2 结束 -->
    </div>
  </div>
</template>
<script>
import InputItem from "@/components/InputItem.vue";
import SelectItem from "@/components/SelectItem.vue";
import DatetimeItem from "@/components/DatetimeItem.vue";
import AddCustomer from "@/components/AddCustomer.vue";
import { getsingleorder, updateorder } from "@/api/order";
import { getcustomerlist } from "@/api/customer";
import { getalltypeofuserlistfs } from "@/api/user";

export default {
  components: {
    InputItem,
    SelectItem,
    DatetimeItem,
    AddCustomer,
  },
  props: { order_id: [String, Number] },
  data: function () {
    return {
      order_no: "",
      order_time: null,
      confirm_time: null,
      deposit_time: null,
      customerList: [{ value: 0, label: "+ 新增客户" }],
      customer: null,
      jiedanList: [],
      jiedan: null,
      fuzhujiedanList: [],
      fuzhujiedan: [],
      fuzhucoefficient: null,
      yewugendanList: [],
      yewugendan: [],
      gendanList: [],
      gendan: null,
      fuzhugendanList: [],
      fuzhugendan: null,
      amount: null,
      products: null,
      source: null,
      currencyList: [{ value: 1, label: "美元" }],
      currency: { value: 1, label: "美元" },
      exchange_rate: 6.5,
      order_statuses: [
        { value: true, label: "有效" },
        { value: false, label: "已删除" },
      ],
      deleted: null,
      modalshow: 0,
      order: {},
      icons: {},
      editable: {},
      modalshow4customer: false,
      super_user: false,
    };
  },
  watch: {
    customer(v) {
      if (v && v.value === 0) {
        // 添加添加客户
        this.modalshow4customer = true;
      }
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    customersaved(e) {
      e.value = e.id;
      e.label = e.name;
      getcustomerlist().then((res) => {
        let customerlist = res.content;
        customerlist.push({ value: 0, label: "+ 新增客户" });
        this.customerList = customerlist;
        this.customer = e;
      });
      this.modalshow4customer = false;
    },
    canceloperation() {
      this.customer = null;
      this.modalshow4customer = false;
    },
    clickicon(field) {
      if (!this.editable[field] && !this.super_user) {
        this.$store.commit("add_notification", {
          msg: "您无权修改此字段",
        });
        return;
      }
      let a = {};
      let value = null;
      if (!this.icons[field] || this.icons[field].icon === "edit") {
        a[field] = { icon: "save" };
        this.icons = Object.assign({}, this.icons, a);
      } else {
        // 点了保存按钮
        a[field] = { icon: "edit" };
        if (field === "order_no") {
          if (this.order_no === this.order.order_no) {
            this.$store.commit("add_notification", {
              msg: "合同号没有修改",
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.order_no;
        } else if (field === "order_time") {
          if (
            new Date(this.order_time).format("yyyy-MM-dd") ===
            new Date(this.order.order_time).format("yyyy-MM-dd")
          ) {
            this.$store.commit("add_notification", {
              msg: "接单日期没有修改",
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = new Date(this.order_time).format("yyyy-MM-dd 00:00:00");
        } else if (field === "confirm_time") {
          if (
            new Date(this.confirm_time).format("yyyy-MM-dd") ===
            new Date(this.order.confirm_time).format("yyyy-MM-dd")
          ) {
            this.$store.commit("add_notification", {
              msg: "确认日期没有修改",
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = new Date(this.confirm_time).format("yyyy-MM-dd 00:00:00");
        } else if (field === "deposit_time") {
          if (
            new Date(this.deposit_time).format("yyyy-MM-dd") ===
            new Date(this.order.deposit_time).format("yyyy-MM-dd")
          ) {
            this.$store.commit("add_notification", {
              msg: "定金日期没有修改",
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = new Date(this.deposit_time).format("yyyy-MM-dd 00:00:00");
        } else if (field === "customer") {
          let cur_obj = this.customer;
          let pre_obj = this.order.customer;
          let msg = "客户没有修改";
          let cur_value = cur_obj ? cur_obj.value : null;
          let pre_value = pre_obj ? pre_obj.value : null;
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.customer;
        } else if (field === "jiedan") {
          let cur_obj = this.jiedan;
          let pre_obj = this.order.jiedan;
          let msg = "接单人没有修改";
          let cur_value = cur_obj ? cur_obj.value : null;
          let pre_value = pre_obj ? pre_obj.value : null;
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.jiedan;
        } else if (field === "fuzhujiedan") {
          let cur_value = String(this.fuzhujiedan.map((v) => v.value));
          let pre_value = String(this.order.fuzhujiedan.map((v) => v.value));
          let msg = "辅助接单人没有修改";
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.fuzhujiedan;
        } else if (field === "fuzhucoefficient") {
          let msg = "辅助系数没有修改";
          let cur_value = parseFloat(this.fuzhucoefficient);
          let pre_value = this.order.fuzhucoefficient;
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.fuzhucoefficient;
        } else if (field === "yewugendan") {
          let cur_value = String(this.yewugendan.map((v) => v.value));
          let pre_value = String(this.order.yewugendan.map((v) => v.value));
          let msg = "业务跟单没有修改";
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.yewugendan;
        } else if (field === "gendan") {
          let cur_obj = this.gendan;
          let pre_obj = this.order.gendan;
          let msg = "跟单人没有修改";
          let cur_value = cur_obj ? cur_obj.value : null;
          let pre_value = pre_obj ? pre_obj.value : null;
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.gendan;
        } else if (field === "fuzhugendan") {
          let cur_obj = this.fuzhugendan;
          let pre_obj = this.order.fuzhugendan;
          let msg = "辅助跟单没有修改";
          let cur_value = cur_obj ? cur_obj.value : null;
          let pre_value = pre_obj ? pre_obj.value : null;
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.fuzhugendan;
        } else if (field === "amount") {
          let msg = "订单金额没有修改";
          let cur_value = parseFloat(this.amount);
          let pre_value = this.order.amount;
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.amount;
        } else if (field === "products") {
          let cur_value = this.products;
          let pre_value = this.order.products;
          let msg = "产品没有修改";
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.products;
        } else if (field === "source") {
          let cur_value = this.source;
          let pre_value = this.order.source;
          let msg = "订单来源没有修改";
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.source;
        } else if (field === "currency") {
          let cur_obj = this.currency;
          let pre_obj = this.order.currency;
          let msg = "币种没有修改";
          let cur_value = cur_obj ? cur_obj.label : null;
          let pre_value = pre_obj ? pre_obj.label : null;
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.currency;
        } else if (field === "exchange_rate") {
          let msg = "汇率没有修改";
          let cur_value = parseFloat(this.amount);
          let pre_value = this.order.amount;
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.exchange_rate;
        } else if (field === "deleted") {
          let msg = "订单状态没有修改";
          let cur_value = this.deleted.value;
          let pre_value = this.order.deleted;
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.deleted.value;
        }
        let order_id = this.order_id;
        let that = this;
        updateorder({ field, value, order_id })
          .then(() => {
            that.$store.commit("add_notification", {
              msg: "修改成功",
              status: "success",
            });
            this.icons = Object.assign({}, this.icons, a);
            this.getsingleorder();
          })
          .catch((e) => {
            that.$store.commit("add_notification", {
              msg: "修改失败：" + e,
            });
          });
        console.log(field, value);
        // 保存按钮点击逻辑结束
      }
    },
    getsingleorder() {
      getsingleorder(this.order_id).then((res) => {
        let data = res.content;
        this.order = JSON.parse(JSON.stringify(data));
        this.order_no = data.order_no;
        this.order_time = new Date(data.order_time);
        this.confirm_time = data.confirm_time ? new Date(data.confirm_time) : null;
        this.deposit_time = data.deposit_time ? new Date(data.deposit_time) : null;
        this.customer = data.customer;
        this.jiedan = data.jiedan;
        this.fuzhujiedan = data.fuzhujiedan;
        this.jiedanList = data.jiedanList;
        this.fuzhujiedanList = data.fuzhujiedanList;
        this.fuzhucoefficient = data.fuzhucoefficient;
        this.yewugendan = data.yewugendan;
        this.gendan = data.gendan;
        this.fuzhugendan = data.fuzhugendan;
        this.amount = data.amount;
        this.products = data.products;
        this.source = data.source;
        this.currency = data.currency;
        this.exchange_rate = data.exchange_rate;
        this.super_user = data.super_user;
        this.deleted = data.deleted
          ? { value: true, label: "已删除" }
          : { value: false, label: "正常" };
        if (
          data.jiedan.value === this.$store.state.user_id ||
          data.creator_id === this.$store.state.user_id
        ) {
          this.editable = {
            order_no: true,
            order_time: true,
            confirm_time: true,
            deposit_time: true,
            customer: false,
            jiedan: true,
            fuzhujiedan: true,
            fuzhucoefficient: true,
            yewugendan: true,
            gendan: true,
            fuzhugendan: true,
            amount: true,
            products: true,
            source: true,
          };
        }
      });
    },
  },
  mounted: function () {
    this.getsingleorder();
    getcustomerlist().then((res) => {
      let customerlist = res.content;
      //customerlist.push({ value: 0, label: "+ 新增客户" });
      this.customerList = customerlist;
    });
    getalltypeofuserlistfs().then((res) => {
      //this.jiedanList = res.content.jiedanList;
      //this.fuzhujiedanList = res.content.fuzhujiedanList;
      this.yewugendanList = res.content.yewugendanList;
      this.gendanList = res.content.gendanList;
      this.fuzhugendanList = res.content.fuzhugendanList;
    });
  },
};
</script>
<style lang="less">
.edit-order {
  padding: 5px 6px 8px;
  .order-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .edit-icon {
      width: 25px;
      height: 25px;
      margin-left: 8px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .add-customer-m {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 7px;
    z-index: 999;
  }
}
</style>
