<template>
  <div class="statistics main-container">
    <FooterBar></FooterBar>
  </div>
</template>

<script>
// @ is an alias to /src
import FooterBar from "@/components/FooterBar.vue";
export default {
  name: "Statistics",
  components: { FooterBar },
};
</script>