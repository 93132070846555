import request from "@/utils/request";

export function createOrder(data) {
    return request.post("/createorder", { data });
}

export function getorderlist(category) {
    return request.get('/getorderlist', { category })
}

export function getshipmentlist(category) {
    return request.get('/getshipmentlist', { category })
}

export function getreceiptlist(category) {
    return request.get('/getreceiptlist', { category })
}

export function getsingleorder(order_id) {
    return request.get('/getsingleorder', { order_id })
}

export function updateorder(data) {
    return request.post("/updateorder", { data });
}

export function shipsave(data) {
    return request.post("/shipsave", { data });
}
export function depositsave(data) {
    return request.post("/depositsave", { data });
}

export function getordershipments(order_id) {
    return request.get("/getordershipments", { order_id });
}

export function getshipmentreceipts(shipment_id) {
    return request.get("/getshipmentreceipts", { shipment_id });
}

export function receiptsave(data) {
    return request.post("/receiptsave", { data });
}

export function getorderreceipts(order_id) {
    return request.get("/getorderreceipts", { order_id });
}

export function closeorder(order_id) {
    return request.post("/closeorder", { order_id });
}

export function batchshipment(data) {
    return request.post("/batchshipment", { data });
}

export function batchreceipt(data) {
    return request.post("/batchreceipt", { data });
}

export function batchshiporreceipt(data, action) {
    if (action === '出货') {
        return batchshipment(data);
    } else if (action === '收款') {
        return batchreceipt(data);
    }
}

export function getcustomerdeposits(customer_id) {
    return request.get("/getcustomerdeposits", { customer_id });
}

export function getsingleshipment(shipment_id) {
    return request.get('/getsingleshipment', { shipment_id })
}

export function updateordershipmentamount(order_shipment_id, amount) {
    return request.post('/updateordershipmentamount', { order_shipment_id, amount })
}

export function updateshipment(data) {
    return request.post("/updateshipment", { data });
}

export function getOrderStats(data) {
    return request.post("/getorderstats", { data });
}