import { render, staticRenderFns } from "./ReturnBack.vue?vue&type=template&id=98a23720&scoped=true&"
import script from "./ReturnBack.vue?vue&type=script&lang=js&"
export * from "./ReturnBack.vue?vue&type=script&lang=js&"
import style0 from "./ReturnBack.vue?vue&type=style&index=0&id=98a23720&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98a23720",
  null
  
)

export default component.exports