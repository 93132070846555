<template>
  <div class="main-container order-stats">
    <div>
      <div class="filter-area">
        <div class="left-title">状态</div>
        <div
          class="filter-button"
          :class="{ active: confirm_status === 0 }"
          @click="() => (confirm_status = 0)"
        >
          未确认
        </div>
        <div
          class="filter-button"
          :class="{ active: confirm_status === 1 }"
          @click="() => (confirm_status = 1)"
        >
          已确认未付定金
        </div>
        <div
          class="filter-button"
          :class="{ active: confirm_status === 2 }"
          @click="() => (confirm_status = 2)"
        >
          已付定金或无需定金
        </div>
      </div>
      <div class="filter-area">
        <div class="left-title">日期类型</div>
        <div
          v-for="(item, i) in [
            { value: 'order_time', label: '下单日期' },
            { value: 'confirm_time', label: '确认日期' },
            { value: 'deposit_time', label: '收定金日期' },
          ]"
          :key="item.value"
        >
          <input
            type="radio"
            :id="`main-type-${item.value}`"
            :value="item"
            v-model="date_type"
            :disabled="confirm_status < i"
          />
          <label :for="`main-type-${item.value}`">{{ item.label }}</label>
        </div>
      </div>
      <div class="filter-area">
        <div class="left-title">日期范围</div>
        <VueDatepickerLocal
          v-model="start_time"
          format="YYYY-MM-DD"
          class="short"
          :disabled="range !== 'custom'"
        ></VueDatepickerLocal>
        <div>-</div>
        <VueDatepickerLocal
          v-model="end_time"
          format="YYYY-MM-DD"
          class="short"
          :disabled="range !== 'custom'"
        ></VueDatepickerLocal>
      </div>
      <div class="filter-area">
        <div
          class="filter-button"
          v-if="range_obj.this_year"
          :class="{ active: range === 'this_year' }"
          @click="() => (range = 'this_year')"
        >
          本年
        </div>
        <div
          class="filter-button"
          v-if="range_obj.this_month"
          :class="{ active: range === 'this_month' }"
          @click="() => (range = 'this_month')"
        >
          本月
        </div>
        <div
          class="filter-button"
          v-if="range_obj.last_month"
          :class="{ active: range === 'last_month' }"
          @click="() => (range = 'last_month')"
        >
          上月
        </div>
        <div
          class="filter-button"
          v-if="range_obj.this_week"
          :class="{ active: range === 'this_week' }"
          @click="() => (range = 'this_week')"
        >
          本周
        </div>
        <div
          class="filter-button"
          v-if="range_obj.last_week"
          :class="{ active: range === 'last_week' }"
          @click="() => (range = 'last_week')"
        >
          上周
        </div>
        <div
          class="filter-button"
          v-if="range_obj.all"
          :class="{ active: range === 'all' }"
          @click="() => (range = 'all')"
        >
          所有
        </div>
        <div
          class="filter-button"
          v-if="range_obj.custom"
          :class="{ active: range === 'custom' }"
          @click="() => (range = 'custom')"
        >
          自定义
        </div>
      </div>
      <div class="break-line"></div>
      <Button
        type="primary"
        @click="getorderstats"
        v-if="
          my_roles &&
          $store.state.user &&
          ($store.state.user.super_user ||
            $store.state.user.leader_user ||
            default_jiedan_id ||
            default_jiedan_id === 0 ||
            default_fuzhujiedan_id ||
            default_fuzhujiedan_id === 0)
        "
        >查询</Button
      >
      <div class="break-line"></div>
      <div v-if="title">{{ title }}</div>
      <div class="filter-area" v-if="orders.length > 0">
        <div class="left-title">全部</div>
        <div
          class="filter-button"
          @click="selectall"
          :class="{ active: !active_jiedan && !active_fuzhujiedan }"
        >
          全部: ${{ Math.round(total_amount * 100) / 100 }}
        </div>
      </div>
      <div class="filter-area" v-if="jiedans.length">
        <div class="left-title">接单</div>
        <div
          v-for="item in jiedans"
          :key="item.id"
          class="filter-button"
          @click="() => (active_jiedan = item)"
          :class="{ active: active_jiedan && active_jiedan.id === item.id }"
        >
          {{ item.name }}: ${{ Math.round(item.amount * 100) / 100 }}
        </div>
      </div>
      <div class="filter-area" v-if="fuzhujiedans.length">
        <div class="left-title">辅助接单</div>
        <div
          v-for="item in fuzhujiedans"
          :key="item.id"
          class="filter-button"
          @click="() => (active_fuzhujiedan = item)"
          :class="{
            active: active_fuzhujiedan && active_fuzhujiedan.id === item.id,
          }"
        >
          {{ item.name }}: ${{ Math.round(item.amount * 100) / 100 }}
        </div>
      </div>
      <ListItem
        v-for="(item, key) in orders_filtered"
        :key="key"
        :index="key + 1"
      >
        <template v-slot:title>合同号: {{ item.order_no }}</template>
        <template v-slot:extra><div>.</div> </template>
        <template v-slot:brief>
          <div>
            <div class="inline-item">接单: {{ item["jiedan"]["name"] }}</div>
            <div class="inline-item">
              日期: {{ new Date(item.order_time).format("yyyy-M-d") }}
            </div>
            <div class="inline-item">客户: {{ item["customer_name"] }}</div>
          </div>
          <div>
            <div
              class="inline-item"
              v-if="
                !$store.state.roles_disabled.includes('fuzhujiedan') &&
                item['fuzhujiedan'] &&
                item['fuzhujiedan'].length > 0
              "
            >
              辅助接单:
              {{ item["fuzhujiedan"].map((v) => v.name).join(",") }};
            </div>
            <div
              class="inline-item"
              v-if="
                !$store.state.roles_disabled.includes('yewugendan') &&
                item['yewugendan'] &&
                item['yewugendan'].length > 0
              "
            >
              业务跟单: {{ item["yewugendan"].map((v) => v.name).join(",") }};
            </div>
            <div
              class="inline-item"
              v-if="
                !$store.state.roles_disabled.includes('gendan') &&
                item['gendan'] &&
                item['gendan']['name']
              "
            >
              跟单: {{ item["gendan"]["name"] }};
            </div>
            <div
              class="inline-item"
              v-if="
                !$store.state.roles_disabled.includes('fuzhugendan') &&
                item['fuzhugendan'] &&
                item['fuzhugendan']['name']
              "
            >
              辅助跟单: {{ item["fuzhugendan"]["name"] }}
            </div>
          </div>
          <div>
            <div class="inline-item">总金额 ${{ item.amount }}</div>
            <div
              v-if="item.confirm_status < 2"
              class="inline-item"
              :class="
                ['not-confirmed', 'confirmed', 'deposit-paid'][
                  item.confirm_status
                ]
              "
            >
              {{ ["订单未确认", "订单已确认未付定金"][item.confirm_status]
              }}<span v-if="!item.confirm_status && !item.need_deposit"
                >(无需定金)</span
              >
            </div>
            <div class="inline-item" v-if="item.confirm_status === 2">
              已出货 ${{ Math.round(item.ship_amount * 100) / 100 }}
            </div>
            <div class="inline-item" v-if="item.confirm_status === 2">
              未出货 ${{
                Math.round((item.amount - item.ship_amount) * 100) / 100
              }}
            </div>
            <div
              class="inline-item"
              v-if="item.receipt_amount && item.confirm_status === 2"
            >
              已收款 ${{ Math.round(item.receipt_amount * 100) / 100 }}
            </div>
          </div>
        </template>
      </ListItem>
    </div>
    <br />
    <ReturnBack></ReturnBack>
  </div>
</template>

<script>
// @ is an alias to /src
import ReturnBack from "@/components/ReturnBack.vue";
import VueDatepickerLocal from "@/components/vue-datepicker-local";
import Button from "@/components/Button.vue";
import ListItem from "@/components/ListItem.vue";
import { getRangeObj, getAllUserList, getSingleUserRoles } from "@/api/user";
import { getOrderStats } from "@/api/order";
export default {
  name: "OrderStats",
  components: { ReturnBack, VueDatepickerLocal, Button, ListItem },
  data: function () {
    return {
      confirm_status: null,
      start_time: null,
      end_time: null,
      date_type: null,
      range_obj: {},
      range: null,
      orders: [],
      jiedans: [],
      active_jiedan: null,
      default_jiedan_id: null,
      fuzhujiedans: [],
      active_fuzhujiedan: null,
      default_fuzhujiedan_id: null,
      total_amount: 0,
      userdict: {},
      my_roles: null,
      title: null,
    };
  },
  watch: {
    range(v) {
      if (this.range_obj[v].start_time) {
        this.start_time = this.range_obj[v].start_time;
      }
      if (this.range_obj[v].end_time) {
        let end_time = this.range_obj[v].end_time;
        end_time = new Date(new Date(end_time) - 1);
        this.end_time = end_time;
      }
    },
    confirm_status(v) {
      this.date_type = [
        { value: "order_time", label: "下单日期" },
        { value: "confirm_time", label: "确认日期" },
        { value: "deposit_time", label: "收定金日期" },
      ][v];
      //if (v === 2) {
      //  this.range = "this_year";
      //}
    },
    active_jiedan(v) {
      if (v) {
        this.active_fuzhujiedan = null;
      }
    },
    active_fuzhujiedan(v) {
      if (v) {
        this.active_jiedan = null;
      }
    },
  },
  computed: {
    orders_filtered() {
      let orders = this.orders;
      return orders.filter(
        (v) =>
          (!this.active_jiedan || this.active_jiedan.id === v.jiedan.id) &&
          (!this.active_fuzhujiedan ||
            v.fuzhujiedan_ids.includes(this.active_fuzhujiedan.id))
      );
    },
  },
  methods: {
    selectall() {
      this.active_jiedan = null;
      this.active_fuzhujiedan = null;
    },
    getorderstats() {
      let end_time = new Date(this.end_time);
      end_time.setDate(end_time.getDate() + 1);
      let data = {
        start_time: new Date(this.start_time).format("yyyy-MM-dd 00:00:00"),
        end_time: new Date(end_time).format("yyyy-MM-dd 00:00:00"),
        confirm_status: this.confirm_status,
        date_type: this.date_type.value,
      };
      getOrderStats(data).then((res) => {
        let jiedan_dict = {};
        let jiedans = [];
        let fuzhujiedan_dict = {};
        let fuzhujiedans = [];
        let total_amount = 0;
        if (
          !this.$store.state.user.super_user &&
          !this.$store.state.user.leader_user &&
          !(this.default_jiedan_id === 0 || this.default_jiedan_id > 0) &&
          !(
            this.default_fuzhujiedan_id === 0 || this.default_fuzhujiedan_id > 0
          )
        ) {
          return;
        }
        let orders = res.content;
        if (!this.$store.state.super_user && !this.$store.state.user.leader_user) {
          orders = orders.filter((v) => {
            let participants = v.participants.filter((v) => v.id || v.id === 0);
            for (let i = 0; i < participants.length; i++) {
              let role = participants[i]["role"];
              if (
                role === "jiedan" &&
                participants[i].id === this.default_jiedan_id &&
                (participants[i].id === 0 || participants[i].id > 0)
              ) {
                return true;
              }
              if (
                role === "fuzhujiedan" &&
                participants[i].id === this.default_fuzhujiedan_id &&
                (participants[i].id === 0 || participants[i].id > 0)
              ) {
                return true;
              }
            }
            return false;
          });
        }
        orders = orders.map((v) => {
          let participants = v.participants.filter((v) => v.id || v.id === 0);
          for (let i = 0; i < participants.length; i++) {
            let role = participants[i]["role"];
            if (role === "fuzhujiedan" || role === "yewugendan") {
              if (v[role]) {
                v[role].push(participants[i]);
              } else {
                v[role] = [participants[i]];
              }
            } else {
              v[role] = participants[i];
            }
            if (role === "jiedan") {
              let p = participants[i];
              let user_id = p["id"];
              if (user_id in jiedan_dict) {
                let k = jiedan_dict[user_id];
                jiedans[k].amount = jiedans[k].amount + v.amount;
              } else {
                jiedans.push({
                  id: user_id,
                  name: p["name"],
                  amount: v.amount,
                });
                jiedan_dict[user_id] = jiedans.length - 1;
              }
            }
            if (role === "fuzhujiedan") {
              let p = participants[i];
              let user_id = p["id"];
              if (user_id in fuzhujiedan_dict) {
                let k = fuzhujiedan_dict[user_id];
                fuzhujiedans[k].amount = fuzhujiedans[k].amount + v.amount;
              } else {
                fuzhujiedans.push({
                  id: user_id,
                  name: p["name"],
                  amount: v.amount,
                });
                fuzhujiedan_dict[user_id] = fuzhujiedans.length - 1;
              }
            }
          }
          total_amount = total_amount + v.amount;
          if (!("fuzhujiedan" in v)) {
            v["fuzhujiedan"] = [];
          }
          v["fuzhujiedan_ids"] = v["fuzhujiedan"].map((v) => v.id);
          if (!("yewugendan" in v)) {
            v["yewugendan"] = [];
          }
          return v;
        });
        this.jiedans = jiedans;
        this.fuzhujiedans = fuzhujiedans;
        this.orders = orders;
        this.total_amount = total_amount;
        this.title = `${this.range_obj[this.range].name} ${
          ["未确认", "已确认未付定金", "已付定金或无需定金"][
            this.confirm_status
          ]
        } 订单数据 (${new Date(this.start_time).format(
          "yy年M月d日"
        )}-${new Date(this.end_time).format("yy年M月d日")})`;
      });
    },
  },
  mounted: function () {
    document.title = "接单统计";
    getSingleUserRoles(this.$store.state.user_id).then((res) => {
      this.my_roles = res.content;
      if (!this.$store.state.super_user && !this.$store.state.user.leader_user) {
        if (res.content.jiedan.enabled) {
          this.default_jiedan_id = this.$store.state.user_id;
        }
        if (res.content.jiedan.enabled) {
          this.default_fuzhujiedan_id = this.$store.state.user_id;
        }
      }
    });
    getRangeObj().then((res) => {
      let range_obj = res.content;
      range_obj["all"] = {
        id: 0,
        start_time: new Date(0),
        end_time: new Date("2038-01-01"),
        name: "所有",
      };
      range_obj["custom"] = {
        id: -100,
        name: "",
      };
      this.range_obj = range_obj;
      this.confirm_status = 0;
      this.range = "all";
    });
    getAllUserList().then((res) => {
      let userdict = {};
      for (let i = 0; i < res.content.length; i++) {
        let user = res.content[i];
        let user_id = user["id"];
        userdict[user_id] = user;
      }
      this.userdict = userdict;
    });
  },
};
</script>

<style lang="less">
.order-stats {
  .inline-item {
    display: inline-block;
    margin-right: 10px;
  }
  .not-confirmed,
  .confirmed {
    color: red;
  }
  .filter-area {
    padding: 1px 0;
    .datepicker {
      width: 110px;
      input {
        height: 30px;
        padding: 0 16px 0 4px;
      }
    }
  }
  .input-item {
    .input-label {
      padding-left: 0;
      font-size: 14px;
    }
    .input-control {
      font-size: 14px;
    }
  }
}
</style>