<template>
  <div :class="{ 'input-item': !vertical, 'input-item-vertical': vertical }">
    <div class="input-label">{{ labelname }}</div>
    <div class="input-control">
      <input
        :type="type || 'text'"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
        :disabled="disabled"
      />
    </div>
  </div>
</template>
<script>
import "@/assets/css/item-common.css";
export default {
  props: {
    labelname: String,
    value: [String, Number],
    disabled: Boolean,
    type: String,
    vertical: Boolean,
  },
  data: function () {
    return {
      inputvalue: "",
    };
  },
};
</script>
<style scoped>
input:disabled {
  cursor: not-allowed;
  background-color: #f6f6f6;
  border-color: #e6e6e6;
  box-shadow: none;
}
</style>