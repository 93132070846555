<template>
  <a
    class="v-button"
    :class="{
      'v-button-active': !this.disabled,
      'v-button-disabled': this.disabled,
      'v-button-warning': this.type === 'warning',
      'v-button-primary': this.type === 'primary',
      'v-button-remind': this.type === 'remind',
    }"
    @click="click"
    role="button"
    ><span><slot></slot></span
  ></a>
</template>
<script>
export default {
  props: { type: String, disabled: Boolean },
  methods: {
    click() {
      if (!this.disabled) {
        this.$emit("click");
      }
    },
  },
};
</script>
<style lang='less' scoped>
.v-button {
  display: block;
  outline: 0 none;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0;
  text-align: center;
  font-size: 18px;
  height: 47px;
  line-height: 47px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  &.small-text {
    span {
      font-size: 0.9em;
    }
    height: 40px;
    line-height: 40px;
  }
}
.v-button-active:active {
  background-color: #d8d8d8;
}
.v-button-warning {
  color: #fff;
  background-color: #e94f4f;
}

.v-button-primary {
  color: #fff;
  background-color: #108ee9;
  border: 1px solid #108ee9;
  border-radius: 5px;
}
.v-button-remind {
  color: #fff;
  background-color: #993300;
  border: 1px solid #993300;
  border-radius: 5px;
}
</style>