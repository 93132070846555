import request from "@/utils/request";

export function getUsersForSelect(user_id = null) {
    return request.get("/getusersforselect", { user_id });
}

export function getUserProfile(user_id = null) {
    return request.get("/getuserprofile", { user_id });
}

export function getUploadToken() {
    return request.post("/getuploadtoken", {});
}

export function syncUploadInfo(file) {
    return request.post("/syncuploadinfo", { file });
}

export function getalltypeofuserlistfs() {
    return request.get('/getalltypeofuserlistfs', {})
}

export function getPersonalData(start_time, end_time, user_id, time_range_id) {
    return request.get('/getpersonaldata', { start_time, end_time, user_id, time_range_id })
}

export function getCorpData(start_time, end_time, time_range_id) {
    return request.get('/getcorpdata', { start_time, end_time, time_range_id })
}

export function getAllUserList() {
    return request.get('/getalluserlist', {})
}

export function getSingleUserRoles(user_id) {
    return request.get('/getsingleuserroles', { user_id });
}

export function saveUserRoles(data) {
    return request.post("/saveuserroles", { data });
}

export function syncWorkWx() {
    return request.post("/syncworkwx", {});
}

export function getPeriodData(year) {
    return request.get("/getperioddata", { year });
}

export function createTimeRanges(data) {
    return request.post("/createtimeranges", { data });
}

export function getRangeObj() {
    return request.get("/getrangeobj", {});
}

export function saveJiedanObj(data) {
    return request.post("/savejiedanobj", { data });
}

export function saveFuzhuJiedanObj(data) {
    return request.post("/savefuzhujiedanobj", { data });
}

export function saveYewuGendanObj(data) {
    return request.post("/saveyewugendanobj", { data });
}

export function saveGendanObj(data) {
    return request.post("/savegendanobj", { data });
}
export function getUserObjectives(user_id, year) {
    return request.get('/getuserobjectives', { user_id, year });
}

export function getPerformanceData(time_range_id) {
    return request.get('/getperformancedata', {time_range_id })
}