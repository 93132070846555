<template>
  <div class="customers main-container">
    <div class="title">所有客户</div>
    <div class="add-customer-button" @click="() => (modalshow = 1)">
      添加客户+
    </div>
    <div class="customer">
      <div class="lt-head">
        <div class="lt-tr">
          <div class="lt-td code">代码</div>
          <div class="lt-td alias-name">别名</div>
          <div class="lt-td yewu">业务人员</div>
          <div class="lt-td operate">修改</div>
        </div>
      </div>
      <div class="lt-body">
        <div class="lt-tr" v-for="item in customers" :key="item.id">
          <div class="lt-td code">{{ item.code }}</div>
          <div class="lt-td alias-name">{{ item.alias_name }}</div>
          <div class="lt-td yewu">
            {{ item.jiedanList.concat(item.fuzhujiedanList).map(v => v.label).join(', ') }}
          </div>
          <div class="lt-td operate">
            <svg-icon
              iconName="edit"
              @click.native="() => editcustomer(item)"
            ></svg-icon>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div class="modal" v-show="modalshow > 0">
      <div class="modal-mask"></div>
      <div class="modal-2" v-if="modalshow === 1">
        <AddCustomer
          v-on:saved="customersaved"
          v-on:canceled="closeedit"
        ></AddCustomer>
      </div>
      <div class="modal-2" v-if="modalshow === 2">
        <EditCustomer
          :customer_id="activecustomer.id"
          v-if="activecustomer"
          v-on:changed="() => changed = true"
        ></EditCustomer>
        <div class="exit-edit" @click="closeedit">
          <svg-icon iconName="close"></svg-icon>
        </div>
      </div>
    </div>
    <ReturnBack></ReturnBack>
  </div>
</template>
<script>
import ReturnBack from "@/components/ReturnBack.vue";
//import Button from "@/components/Button.vue";
import AddCustomer from "@/components/AddCustomer.vue";
import EditCustomer from "@/components/EditCustomer.vue";
import { getcustomerlist } from "@/api/customer";
export default {
  name: "Customers",
  components: { ReturnBack, AddCustomer, EditCustomer },
  data: function () {
    return {
      customers: [],
      activecustomer: null,
      modalshow: 0,
      changed: false,
    };
  },
  methods: {
    editcustomer(customer) {
      this.activecustomer = customer;
      this.modalshow = 2;
    },
    closeedit() {
      this.modalshow = 0;
      this.roles = [];
      if (this.changed) {
        getcustomerlist().then((res) => {
          let customers = res.content;
          this.customers = customers;
        });
        this.changed = false;
      }
    },
    customersaved() {
      this.closeedit();
      getcustomerlist().then((res) => {
        let customers = res.content;
        this.customers = customers;
      });
    },
  },
  mounted: function () {
    getcustomerlist().then((res) => {
      let customers = res.content;
      this.customers = customers;
    });
  },
};
</script>
<style scoped lang="less">
.customers {
  .title {
    font-size: 1.1em;
    padding: 10px;
  }
  .customer {
    margin: 6px 10px;
    .lt-head,
    .lt-body {
      .lt-tr {
        justify-content: center;
        text-align: center;
        align-items: center;
        .code {
          width: 40px;
          flex-shrink: 0;
        }
        .alias-name {
          width: 150px;
          flex-shrink: 0;
        }
        .yewu {
          flex-grow: 1;
        }
        .operate {
          flex-shrink: 0;
          width: 50px;
          .svg-icon {
            width: 22px;
            height: 22px;
            &:active {
              color: blue;
            }
          }
        }
      }
    }
  }
  .modal {
    .modal-2 {
      .content {
        padding: 10px;
        .user-name {
          text-align: center;
          font-size: 1.1em;
        }
      }
      .lt-tr {
        align-items: center;
        justify-content: center;
        text-align: center;
        .role {
          width: 80px;
        }
        .enable-status {
          flex-grow: 1;
        }
        .coefficient {
          width: 60px;
          input {
            width: 100%;
          }
        }
      }
    }
  }
  .add-customer-button {
    position: absolute;
    top: 10px;
    right: 20px;
    background: #2ea44f;
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
  }
}
</style>
