<template>
  <div class="objectives main-container">
    <div class="input-item">
      <div class="input-label">年度</div>
      <div class="input-control year">
        <div class="minus" @click="() => (year = year - 1)">-</div>
        <div class="year-n">{{ year }}</div>
        <div class="add" @click="() => (year = year + 1)">+</div>
      </div>
    </div>
    <div v-if="first_month_start" class="start-date">
      年度开始日期: {{ new Date(first_month_start).format("yyyy-MM-dd") }}
    </div>
    <SelectItem
      labelname="员工"
      :selectlist="user_list"
      v-model="user"
      v-if="super_user"
    ></SelectItem>
    <div v-if="first_month_start && user_roles" class="main-content">
      <!-- 接单人开始 -->
      <div v-if="user_roles['jiedan'].enabled" class="role-item">
        <div class="role-title">接单人{{ user.label }}</div>
        <div class="obj-title">
          <div class="inline-block">接单人目标（单位：万美元）</div>
          <div
            class="inline-block edit-button"
            v-if="!jiedan_editable"
            @click="() => (jiedan_editable = !jiedan_editable)"
          >
            修改
          </div>
          <div
            class="inline-block save-button"
            v-if="jiedan_editable"
            @click="savejiedanobj"
          >
            保存
          </div>
        </div>
        <div class="lt-body" :class="{ immutable: !jiedan_editable }">
          <div class="lt-tr lt-th">
            <div class="lt-td type">类型</div>
            <div class="lt-td obj">必达目标</div>
            <div class="lt-td obj">冲刺目标</div>
            <div class="lt-td obj">最终目标</div>
            <div class="lt-td init-value">期初数</div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="jiedan_year_r_obj_set"
                :disabled="!jiedan_editable"
              />年收款
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_year_r_obj[0]"
                :disabled="!jiedan_editable || !jiedan_year_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_year_r_obj[1]"
                :disabled="!jiedan_editable || !jiedan_year_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_year_r_obj[2]"
                :disabled="!jiedan_editable || !jiedan_year_r_obj_set"
              />
            </div>
            <div class="lt-td init-value">
              <input
                type="number"
                class="obj"
                v-model="jiedan_year_r_init_value"
                :disabled="!jiedan_editable || !jiedan_year_r_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="jiedan_year_obj_set"
                :disabled="!jiedan_editable"
              />年接单
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_year_obj[0]"
                :disabled="!jiedan_editable || !jiedan_year_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_year_obj[1]"
                :disabled="!jiedan_editable || !jiedan_year_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_year_obj[2]"
                :disabled="!jiedan_editable || !jiedan_year_obj_set"
              />
            </div>
            <div class="lt-td init-value">
              <input
                type="number"
                v-model="jiedan_year_init_value"
                :disabled="!jiedan_editable || !jiedan_year_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="jiedan_month_r_obj_set"
                :disabled="!jiedan_editable"
              />月收款
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_month_r_obj[0]"
                :disabled="!jiedan_editable || !jiedan_month_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_month_r_obj[1]"
                :disabled="!jiedan_editable || !jiedan_month_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_month_r_obj[2]"
                :disabled="!jiedan_editable || !jiedan_month_r_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="jiedan_month_obj_set"
                :disabled="!jiedan_editable"
              />月接单
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_month_obj[0]"
                :disabled="!jiedan_editable || !jiedan_month_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_month_obj[1]"
                :disabled="!jiedan_editable || !jiedan_month_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_month_obj[2]"
                :disabled="!jiedan_editable || !jiedan_month_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="jiedan_week_r_obj_set"
                :disabled="!jiedan_editable"
              />周收款
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_week_r_obj[0]"
                :disabled="!jiedan_editable || !jiedan_week_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_week_r_obj[1]"
                :disabled="!jiedan_editable || !jiedan_week_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_week_r_obj[2]"
                :disabled="!jiedan_editable || !jiedan_week_r_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="jiedan_week_obj_set"
                :disabled="!jiedan_editable"
              />周接单
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_week_obj[0]"
                :disabled="!jiedan_editable || !jiedan_week_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_week_obj[1]"
                :disabled="!jiedan_editable || !jiedan_week_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="jiedan_week_obj[2]"
                :disabled="!jiedan_editable || !jiedan_week_obj_set"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 接单人结束 -->
      <div v-if="user_roles['fuzhujiedan'].enabled" class="role-item">
        <div class="role-title">辅助接单人{{ user.label }}</div>
        <div class="obj-title">
          <div class="inline-block">辅助接单人目标（单位：万美元）</div>
          <div
            class="inline-block edit-button"
            v-if="!fuzhujiedan_editable"
            @click="() => (fuzhujiedan_editable = !fuzhujiedan_editable)"
          >
            修改
          </div>
          <div
            class="inline-block save-button"
            v-if="fuzhujiedan_editable"
            @click="savefuzhujiedanobj"
          >
            保存
          </div>
        </div>
        <div class="lt-body" :class="{ immutable: !fuzhujiedan_editable }">
          <div class="lt-tr lt-th">
            <div class="lt-td type">类型</div>
            <div class="lt-td obj">必达目标</div>
            <div class="lt-td obj">冲刺目标</div>
            <div class="lt-td obj">最终目标</div>
            <div class="lt-td init-value">期初数</div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="fuzhujiedan_year_r_obj_set"
                :disabled="!fuzhujiedan_editable"
              />年收款
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_year_r_obj[0]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_year_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_year_r_obj[1]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_year_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_year_r_obj[2]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_year_r_obj_set"
              />
            </div>
            <div class="lt-td init-value">
              <input
                type="number"
                class="obj"
                v-model="fuzhujiedan_year_r_init_value"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_year_r_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="fuzhujiedan_year_obj_set"
                :disabled="!fuzhujiedan_editable"
              />年接单
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_year_obj[0]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_year_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_year_obj[1]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_year_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_year_obj[2]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_year_obj_set"
              />
            </div>
            <div class="lt-td init-value">
              <input
                type="number"
                v-model="fuzhujiedan_year_init_value"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_year_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="fuzhujiedan_month_r_obj_set"
                :disabled="!fuzhujiedan_editable"
              />月收款
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_month_r_obj[0]"
                :disabled="
                  !fuzhujiedan_editable || !fuzhujiedan_month_r_obj_set
                "
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_month_r_obj[1]"
                :disabled="
                  !fuzhujiedan_editable || !fuzhujiedan_month_r_obj_set
                "
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_month_r_obj[2]"
                :disabled="
                  !fuzhujiedan_editable || !fuzhujiedan_month_r_obj_set
                "
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="fuzhujiedan_month_obj_set"
                :disabled="!fuzhujiedan_editable"
              />月接单
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_month_obj[0]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_month_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_month_obj[1]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_month_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_month_obj[2]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_month_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="fuzhujiedan_week_r_obj_set"
                :disabled="!fuzhujiedan_editable"
              />周收款
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_week_r_obj[0]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_week_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_week_r_obj[1]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_week_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_week_r_obj[2]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_week_r_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="fuzhujiedan_week_obj_set"
                :disabled="!fuzhujiedan_editable"
              />周接单
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_week_obj[0]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_week_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_week_obj[1]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_week_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="fuzhujiedan_week_obj[2]"
                :disabled="!fuzhujiedan_editable || !fuzhujiedan_week_obj_set"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="user_roles['yewugendan'].enabled" class="role-item">
        <div class="role-title">业务跟单{{ user.label }}</div>
        <div class="obj-title">
          <div class="inline-block">业务跟单目标（单位：万美元）</div>
          <div
            class="inline-block edit-button"
            v-if="!yewugendan_editable"
            @click="() => (yewugendan_editable = !yewugendan_editable)"
          >
            修改
          </div>
          <div
            class="inline-block save-button"
            v-if="yewugendan_editable"
            @click="saveyewugendanobj"
          >
            保存
          </div>
        </div>
        <div class="lt-body" :class="{ immutable: !yewugendan_editable }">
          <div class="lt-tr lt-th">
            <div class="lt-td type">类型</div>
            <div class="lt-td obj">必达目标</div>
            <div class="lt-td obj">冲刺目标</div>
            <div class="lt-td obj">最终目标</div>
            <div class="lt-td init-value">期初数</div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="yewugendan_year_r_obj_set"
                :disabled="!yewugendan_editable"
              />年收款
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_year_r_obj[0]"
                :disabled="!yewugendan_editable || !yewugendan_year_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_year_r_obj[1]"
                :disabled="!yewugendan_editable || !yewugendan_year_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_year_r_obj[2]"
                :disabled="!yewugendan_editable || !yewugendan_year_r_obj_set"
              />
            </div>
            <div class="lt-td init-value">
              <input
                type="number"
                class="obj"
                v-model="yewugendan_year_r_init_value"
                :disabled="!yewugendan_editable || !yewugendan_year_r_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="yewugendan_year_obj_set"
                :disabled="!yewugendan_editable"
              />年接单
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_year_obj[0]"
                :disabled="!yewugendan_editable || !yewugendan_year_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_year_obj[1]"
                :disabled="!yewugendan_editable || !yewugendan_year_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_year_obj[2]"
                :disabled="!yewugendan_editable || !yewugendan_year_obj_set"
              />
            </div>
            <div class="lt-td init-value">
              <input
                type="number"
                v-model="yewugendan_year_init_value"
                :disabled="!yewugendan_editable || !yewugendan_year_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="yewugendan_month_r_obj_set"
                :disabled="!yewugendan_editable"
              />月收款
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_month_r_obj[0]"
                :disabled="!yewugendan_editable || !yewugendan_month_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_month_r_obj[1]"
                :disabled="!yewugendan_editable || !yewugendan_month_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_month_r_obj[2]"
                :disabled="!yewugendan_editable || !yewugendan_month_r_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="yewugendan_month_obj_set"
                :disabled="!yewugendan_editable"
              />月接单
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_month_obj[0]"
                :disabled="!yewugendan_editable || !yewugendan_month_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_month_obj[1]"
                :disabled="!yewugendan_editable || !yewugendan_month_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_month_obj[2]"
                :disabled="!yewugendan_editable || !yewugendan_month_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="yewugendan_week_r_obj_set"
                :disabled="!yewugendan_editable"
              />周收款
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_week_r_obj[0]"
                :disabled="!yewugendan_editable || !yewugendan_week_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_week_r_obj[1]"
                :disabled="!yewugendan_editable || !yewugendan_week_r_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_week_r_obj[2]"
                :disabled="!yewugendan_editable || !yewugendan_week_r_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="yewugendan_week_obj_set"
                :disabled="!yewugendan_editable"
              />周接单
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_week_obj[0]"
                :disabled="!yewugendan_editable || !yewugendan_week_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_week_obj[1]"
                :disabled="!yewugendan_editable || !yewugendan_week_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="yewugendan_week_obj[2]"
                :disabled="!yewugendan_editable || !yewugendan_week_obj_set"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="user_roles['gendan'].enabled" class="role-item">
        <div class="role-title">跟单人{{ user.label }}</div>
        <div class="obj-title">
          <div class="inline-block">跟单人目标（单位：万美元）</div>
          <div
            class="inline-block edit-button"
            v-if="!gendan_editable"
            @click="() => (gendan_editable = !gendan_editable)"
          >
            修改
          </div>
          <div
            class="inline-block save-button"
            v-if="gendan_editable"
            @click="savegendanobj"
          >
            保存
          </div>
        </div>
        <div class="lt-body" :class="{ immutable: !gendan_editable }">
          <div class="lt-tr lt-th">
            <div class="lt-td type">类型</div>
            <div class="lt-td obj">必达目标</div>
            <div class="lt-td obj">冲刺目标</div>
            <div class="lt-td obj">最终目标</div>
            <div class="lt-td init-value">期初数</div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="gendan_year_obj_set"
                :disabled="!gendan_editable"
              />年出货
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="gendan_year_obj[0]"
                :disabled="!gendan_editable || !gendan_year_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="gendan_year_obj[1]"
                :disabled="!gendan_editable || !gendan_year_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="gendan_year_obj[2]"
                :disabled="!gendan_editable || !gendan_year_obj_set"
              />
            </div>
            <div class="lt-td init-value">
              <input
                type="number"
                v-model="gendan_year_init_value"
                :disabled="!gendan_editable || !gendan_year_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="gendan_month_obj_set"
                :disabled="!gendan_editable"
              />月出货
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="gendan_month_obj[0]"
                :disabled="!gendan_editable || !gendan_month_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="gendan_month_obj[1]"
                :disabled="!gendan_editable || !gendan_month_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="gendan_month_obj[2]"
                :disabled="!gendan_editable || !gendan_month_obj_set"
              />
            </div>
          </div>
          <div class="lt-tr">
            <div class="lt-td type">
              <input
                type="checkbox"
                v-model="gendan_week_obj_set"
                :disabled="!gendan_editable"
              />周出货
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="gendan_week_obj[0]"
                :disabled="!gendan_editable || !gendan_week_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="gendan_week_obj[1]"
                :disabled="!gendan_editable || !gendan_week_obj_set"
              />
            </div>
            <div class="lt-td obj">
              <input
                type="number"
                v-model="gendan_week_obj[2]"
                :disabled="!gendan_editable || !gendan_week_obj_set"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <ReturnBack></ReturnBack>
  </div>
</template>
<script>
import ReturnBack from "@/components/ReturnBack.vue";
import SelectItem from "@/components/SelectItem.vue";
import {
  getPeriodData,
  getSingleUserRoles,
  saveJiedanObj,
  saveFuzhuJiedanObj,
  saveYewuGendanObj,
  saveGendanObj,
  getUserObjectives,
  getUsersForSelect,
} from "@/api/user";

export default {
  name: "Objectives",
  components: { ReturnBack, SelectItem },
  data: function () {
    return {
      year: new Date().getFullYear(),
      first_week_start: null,
      first_month_start: null,
      week_start_disabled: true,
      month_start_disabled: true,
      user_roles: null,
      user_list: [],
      user: null,
      userid2names: {},
      jiedan_editable: false,
      jiedan_year_obj: [],
      jiedan_year_obj_set: false,
      jiedan_year_init_value: 0,
      jiedan_year_r_obj: [],
      jiedan_year_r_obj_set: false,
      jiedan_year_r_init_value: 0,
      jiedan_month_obj: [],
      jiedan_month_obj_set: false,
      jiedan_month_r_obj: [],
      jiedan_month_r_obj_set: false,
      jiedan_week_obj: [],
      jiedan_week_obj_set: false,
      jiedan_week_r_obj: [],
      jiedan_week_r_obj_set: false,
      fuzhujiedan_editable: false,
      fuzhujiedan_year_obj: [],
      fuzhujiedan_year_obj_set: false,
      fuzhujiedan_year_init_value: 0,
      fuzhujiedan_year_r_obj: [],
      fuzhujiedan_year_r_obj_set: false,
      fuzhujiedan_year_r_init_value: 0,
      fuzhujiedan_month_obj: [],
      fuzhujiedan_month_obj_set: false,
      fuzhujiedan_month_r_obj: [],
      fuzhujiedan_month_r_obj_set: false,
      fuzhujiedan_week_obj: [],
      fuzhujiedan_week_obj_set: false,
      fuzhujiedan_week_r_obj: [],
      fuzhujiedan_week_r_obj_set: false,
      yewugendan_editable: false,
      yewugendan_year_obj: [],
      yewugendan_year_obj_set: false,
      yewugendan_year_init_value: 0,
      yewugendan_year_r_obj: [],
      yewugendan_year_r_obj_set: false,
      yewugendan_year_r_init_value: 0,
      yewugendan_month_obj: [],
      yewugendan_month_obj_set: false,
      yewugendan_month_r_obj: [],
      yewugendan_month_r_obj_set: false,
      yewugendan_week_obj: [],
      yewugendan_week_obj_set: false,
      yewugendan_week_r_obj: [],
      yewugendan_week_r_obj_set: false,
      gendan_editable: false,
      gendan_year_obj: [],
      gendan_year_obj_set: false,
      gendan_year_init_value: 0,
      gendan_month_obj: [],
      gendan_month_obj_set: false,
      gendan_week_obj: [],
      gendan_week_obj_set: false,
      super_user: false,
    };
  },
  watch: {
    year(v) {
      this.getperioddata(v);
    },
    user(v) {
      getSingleUserRoles(v.value).then((res) => {
        this.user_roles = res.content;
      });
      this.getuserobjectives();
    },
  },
  methods: {
    getperioddata() {
      this.first_week_start = null;
      this.first_month_start = null;
      getPeriodData(this.year).then((res) => {
        let week = res.content.week;
        let month = res.content.month;
        if (week) {
          this.week_start_disabled = true;
          this.first_week_start = new Date(week.start_time);
        } else {
          this.week_start_disabled = false;
        }
        if (month) {
          this.month_start_disabled = true;
          this.first_month_start = new Date(month.start_time);
        } else {
          this.month_start_disabled = false;
        }
      });
    },
    savejiedanobj() {
      let jiedan_year_obj,
        jiedan_year_obj_set,
        jiedan_year_r_obj,
        jiedan_year_r_obj_set;
      let jiedan_year_init_value, jiedan_year_r_init_value;
      let jiedan_month_obj,
        jiedan_month_obj_set,
        jiedan_month_r_obj,
        jiedan_month_r_obj_set;
      let jiedan_week_obj,
        jiedan_week_obj_set,
        jiedan_week_r_obj,
        jiedan_week_r_obj_set;
      jiedan_year_obj = [0, 0, 0];
      jiedan_year_r_obj = [0, 0, 0];
      jiedan_month_obj = [0, 0, 0];
      jiedan_month_r_obj = [0, 0, 0];
      jiedan_week_obj = [0, 0, 0];
      jiedan_week_r_obj = [0, 0, 0];
      jiedan_year_obj_set = this.jiedan_year_obj_set;
      jiedan_year_r_obj_set = this.jiedan_year_r_obj_set;
      jiedan_month_obj_set = this.jiedan_month_obj_set;
      jiedan_month_r_obj_set = this.jiedan_month_r_obj_set;
      jiedan_week_obj_set = this.jiedan_week_obj_set;
      jiedan_week_r_obj_set = this.jiedan_week_r_obj_set;
      for (let i = 0; i < this.jiedan_year_obj.length; i++) {
        if (this.jiedan_year_obj[i]) {
          jiedan_year_obj[i] = parseFloat(this.jiedan_year_obj[i]);
        }
      }
      for (let i = 0; i < this.jiedan_year_r_obj.length; i++) {
        if (this.jiedan_year_r_obj[i]) {
          jiedan_year_r_obj[i] = parseFloat(this.jiedan_year_r_obj[i]);
        }
      }
      for (let i = 0; i < this.jiedan_month_obj.length; i++) {
        if (this.jiedan_month_obj[i]) {
          jiedan_month_obj[i] = parseFloat(this.jiedan_month_obj[i]);
        }
      }
      for (let i = 0; i < this.jiedan_month_r_obj.length; i++) {
        if (this.jiedan_month_r_obj[i]) {
          jiedan_month_r_obj[i] = parseFloat(this.jiedan_month_r_obj[i]);
        }
      }
      for (let i = 0; i < this.jiedan_week_obj.length; i++) {
        if (this.jiedan_week_obj[i]) {
          jiedan_week_obj[i] = parseFloat(this.jiedan_week_obj[i]);
        }
      }
      for (let i = 0; i < this.jiedan_week_r_obj.length; i++) {
        if (this.jiedan_week_r_obj[i]) {
          jiedan_week_r_obj[i] = parseFloat(this.jiedan_week_r_obj[i]);
        }
      }
      let year = this.year;
      let user_id = this.user.value;
      jiedan_year_init_value = this.jiedan_year_init_value;
      jiedan_year_r_init_value = this.jiedan_year_r_init_value;
      let data = {
        jiedan_year_obj,
        jiedan_year_obj_set,
        jiedan_year_init_value,
        jiedan_year_r_obj,
        jiedan_year_r_obj_set,
        jiedan_year_r_init_value,
        jiedan_month_obj,
        jiedan_month_obj_set,
        jiedan_month_r_obj,
        jiedan_month_r_obj_set,
        jiedan_week_obj,
        jiedan_week_obj_set,
        jiedan_week_r_obj,
        jiedan_week_r_obj_set,
        year,
        user_id,
      };
      saveJiedanObj(data).then((res) => {
        console.log(res);
        this.jiedan_editable = false;
        this.getuserobjectives();
      });
    },
    savefuzhujiedanobj() {
      let fuzhujiedan_year_obj,
        fuzhujiedan_year_obj_set,
        fuzhujiedan_year_r_obj,
        fuzhujiedan_year_r_obj_set;
      let fuzhujiedan_year_init_value, fuzhujiedan_year_r_init_value;
      let fuzhujiedan_month_obj,
        fuzhujiedan_month_obj_set,
        fuzhujiedan_month_r_obj,
        fuzhujiedan_month_r_obj_set;
      let fuzhujiedan_week_obj,
        fuzhujiedan_week_obj_set,
        fuzhujiedan_week_r_obj,
        fuzhujiedan_week_r_obj_set;
      fuzhujiedan_year_obj = [0, 0, 0];
      fuzhujiedan_year_r_obj = [0, 0, 0];
      fuzhujiedan_month_obj = [0, 0, 0];
      fuzhujiedan_month_r_obj = [0, 0, 0];
      fuzhujiedan_week_obj = [0, 0, 0];
      fuzhujiedan_week_r_obj = [0, 0, 0];
      fuzhujiedan_year_obj_set = this.fuzhujiedan_year_obj_set;
      fuzhujiedan_year_r_obj_set = this.fuzhujiedan_year_r_obj_set;
      fuzhujiedan_month_obj_set = this.fuzhujiedan_month_obj_set;
      fuzhujiedan_month_r_obj_set = this.fuzhujiedan_month_r_obj_set;
      fuzhujiedan_week_obj_set = this.fuzhujiedan_week_obj_set;
      fuzhujiedan_week_r_obj_set = this.fuzhujiedan_week_r_obj_set;
      for (let i = 0; i < this.fuzhujiedan_year_obj.length; i++) {
        if (this.fuzhujiedan_year_obj[i]) {
          fuzhujiedan_year_obj[i] = parseFloat(this.fuzhujiedan_year_obj[i]);
        }
      }
      for (let i = 0; i < this.fuzhujiedan_year_r_obj.length; i++) {
        if (this.fuzhujiedan_year_r_obj[i]) {
          fuzhujiedan_year_r_obj[i] = parseFloat(
            this.fuzhujiedan_year_r_obj[i]
          );
        }
      }
      for (let i = 0; i < this.fuzhujiedan_month_obj.length; i++) {
        if (this.fuzhujiedan_month_obj[i]) {
          fuzhujiedan_month_obj[i] = parseFloat(this.fuzhujiedan_month_obj[i]);
        }
      }
      for (let i = 0; i < this.fuzhujiedan_month_r_obj.length; i++) {
        if (this.fuzhujiedan_month_r_obj[i]) {
          fuzhujiedan_month_r_obj[i] = parseFloat(
            this.fuzhujiedan_month_r_obj[i]
          );
        }
      }
      for (let i = 0; i < this.fuzhujiedan_week_obj.length; i++) {
        if (this.fuzhujiedan_week_obj[i]) {
          fuzhujiedan_week_obj[i] = parseFloat(this.fuzhujiedan_week_obj[i]);
        }
      }
      for (let i = 0; i < this.fuzhujiedan_week_r_obj.length; i++) {
        if (this.fuzhujiedan_week_r_obj[i]) {
          fuzhujiedan_week_r_obj[i] = parseFloat(
            this.fuzhujiedan_week_r_obj[i]
          );
        }
      }
      let year = this.year;
      let user_id = this.user.value;
      fuzhujiedan_year_init_value = this.fuzhujiedan_year_init_value;
      fuzhujiedan_year_r_init_value = this.fuzhujiedan_year_r_init_value;
      let data = {
        fuzhujiedan_year_obj,
        fuzhujiedan_year_obj_set,
        fuzhujiedan_year_init_value,
        fuzhujiedan_year_r_obj,
        fuzhujiedan_year_r_obj_set,
        fuzhujiedan_year_r_init_value,
        fuzhujiedan_month_obj,
        fuzhujiedan_month_obj_set,
        fuzhujiedan_month_r_obj,
        fuzhujiedan_month_r_obj_set,
        fuzhujiedan_week_obj,
        fuzhujiedan_week_obj_set,
        fuzhujiedan_week_r_obj,
        fuzhujiedan_week_r_obj_set,
        year,
        user_id,
      };
      saveFuzhuJiedanObj(data).then((res) => {
        console.log(res);
        this.fuzhujiedan_editable = false;
        this.getuserobjectives();
      });
    },
    saveyewugendanobj() {
      let yewugendan_year_obj,
        yewugendan_year_obj_set,
        yewugendan_year_r_obj,
        yewugendan_year_r_obj_set;
      let yewugendan_year_init_value, yewugendan_year_r_init_value;
      let yewugendan_month_obj,
        yewugendan_month_obj_set,
        yewugendan_month_r_obj,
        yewugendan_month_r_obj_set;
      let yewugendan_week_obj,
        yewugendan_week_obj_set,
        yewugendan_week_r_obj,
        yewugendan_week_r_obj_set;
      yewugendan_year_obj = [0, 0, 0];
      yewugendan_year_r_obj = [0, 0, 0];
      yewugendan_month_obj = [0, 0, 0];
      yewugendan_month_r_obj = [0, 0, 0];
      yewugendan_week_obj = [0, 0, 0];
      yewugendan_week_r_obj = [0, 0, 0];
      yewugendan_year_obj_set = this.yewugendan_year_obj_set;
      yewugendan_year_r_obj_set = this.yewugendan_year_r_obj_set;
      yewugendan_month_obj_set = this.yewugendan_month_obj_set;
      yewugendan_month_r_obj_set = this.yewugendan_month_r_obj_set;
      yewugendan_week_obj_set = this.yewugendan_week_obj_set;
      yewugendan_week_r_obj_set = this.yewugendan_week_r_obj_set;
      for (let i = 0; i < this.yewugendan_year_obj.length; i++) {
        if (this.yewugendan_year_obj[i]) {
          yewugendan_year_obj[i] = parseFloat(this.yewugendan_year_obj[i]);
        }
      }
      for (let i = 0; i < this.yewugendan_year_r_obj.length; i++) {
        if (this.yewugendan_year_r_obj[i]) {
          yewugendan_year_r_obj[i] = parseFloat(this.yewugendan_year_r_obj[i]);
        }
      }
      for (let i = 0; i < this.yewugendan_month_obj.length; i++) {
        if (this.yewugendan_month_obj[i]) {
          yewugendan_month_obj[i] = parseFloat(this.yewugendan_month_obj[i]);
        }
      }
      for (let i = 0; i < this.yewugendan_month_r_obj.length; i++) {
        if (this.yewugendan_month_r_obj[i]) {
          yewugendan_month_r_obj[i] = parseFloat(
            this.yewugendan_month_r_obj[i]
          );
        }
      }
      for (let i = 0; i < this.yewugendan_week_obj.length; i++) {
        if (this.yewugendan_week_obj[i]) {
          yewugendan_week_obj[i] = parseFloat(this.yewugendan_week_obj[i]);
        }
      }
      for (let i = 0; i < this.yewugendan_week_r_obj.length; i++) {
        if (this.yewugendan_week_r_obj[i]) {
          yewugendan_week_r_obj[i] = parseFloat(this.yewugendan_week_r_obj[i]);
        }
      }
      let year = this.year;
      let user_id = this.user.value;
      yewugendan_year_init_value = this.yewugendan_year_init_value;
      yewugendan_year_r_init_value = this.yewugendan_year_r_init_value;
      let data = {
        yewugendan_year_obj,
        yewugendan_year_obj_set,
        yewugendan_year_init_value,
        yewugendan_year_r_obj,
        yewugendan_year_r_obj_set,
        yewugendan_year_r_init_value,
        yewugendan_month_obj,
        yewugendan_month_obj_set,
        yewugendan_month_r_obj,
        yewugendan_month_r_obj_set,
        yewugendan_week_obj,
        yewugendan_week_obj_set,
        yewugendan_week_r_obj,
        yewugendan_week_r_obj_set,
        year,
        user_id,
      };
      saveYewuGendanObj(data).then((res) => {
        console.log(res);
        this.yewugendan_editable = false;
        this.getuserobjectives();
      });
    },
    savegendanobj() {
      let gendan_year_obj, gendan_year_obj_set;
      let gendan_year_init_value;
      let gendan_month_obj, gendan_month_obj_set;
      let gendan_week_obj, gendan_week_obj_set;
      gendan_year_obj = [0, 0, 0];
      gendan_month_obj = [0, 0, 0];
      gendan_week_obj = [0, 0, 0];
      gendan_year_obj_set = this.gendan_year_obj_set;
      gendan_month_obj_set = this.gendan_month_obj_set;
      gendan_week_obj_set = this.gendan_week_obj_set;
      for (let i = 0; i < this.gendan_year_obj.length; i++) {
        if (this.gendan_year_obj[i]) {
          gendan_year_obj[i] = parseFloat(this.gendan_year_obj[i]);
        }
      }
      for (let i = 0; i < this.gendan_month_obj.length; i++) {
        if (this.gendan_month_obj[i]) {
          gendan_month_obj[i] = parseFloat(this.gendan_month_obj[i]);
        }
      }
      for (let i = 0; i < this.gendan_week_obj.length; i++) {
        if (this.gendan_week_obj[i]) {
          gendan_week_obj[i] = parseFloat(this.gendan_week_obj[i]);
        }
      }
      let year = this.year;
      let user_id = this.user.value;
      gendan_year_init_value = this.gendan_year_init_value;
      let data = {
        gendan_year_obj,
        gendan_year_obj_set,
        gendan_year_init_value,
        gendan_month_obj,
        gendan_month_obj_set,
        gendan_week_obj,
        gendan_week_obj_set,
        year,
        user_id,
      };
      saveGendanObj(data).then((res) => {
        console.log(res);
        this.gendan_editable = false;
        this.getuserobjectives();
      });
    },
    getuserobjectives() {
      this.jiedan_editable = false;
      this.jiedan_year_obj = [];
      this.jiedan_year_obj_set = false;
      this.jiedan_year_init_value = 0;
      this.jiedan_year_r_obj = [];
      this.jiedan_year_r_obj_set = false;
      this.jiedan_year_r_init_value = 0;
      this.jiedan_month_obj = [];
      this.jiedan_month_obj_set = false;
      this.jiedan_month_r_obj = [];
      this.jiedan_month_r_obj_set = false;
      this.jiedan_week_obj = [];
      this.jiedan_week_obj_set = false;
      this.jiedan_week_r_obj = [];
      this.jiedan_week_r_obj_set = false;
      this.fuzhujiedan_year_obj = [];
      this.fuzhujiedan_year_obj_set = false;
      this.fuzhujiedan_year_r_obj = [];
      this.fuzhujiedan_year_r_obj_set = false;
      this.fuzhujiedan_month_obj = [];
      this.fuzhujiedan_month_obj_set = false;
      this.fuzhujiedan_month_r_obj = [];
      this.fuzhujiedan_month_r_obj_set = false;
      this.fuzhujiedan_week_obj = [];
      this.fuzhujiedan_week_obj_set = false;
      this.fuzhujiedan_week_r_obj = [];
      this.fuzhujiedan_week_r_obj_set = false;
      this.yewugendan_year_obj = [];
      this.yewugendan_year_obj_set = false;
      this.yewugendan_year_r_obj = [];
      this.yewugendan_year_r_obj_set = false;
      this.yewugendan_month_obj = [];
      this.yewugendan_month_obj_set = false;
      this.yewugendan_month_r_obj = [];
      this.yewugendan_month_r_obj_set = false;
      this.yewugendan_week_obj = [];
      this.yewugendan_week_obj_set = false;
      this.yewugendan_week_r_obj = [];
      this.yewugendan_week_r_obj_set = false;
      this.gendan_year_obj = [];
      this.gendan_year_obj_set = false;
      this.gendan_month_obj = [];
      this.gendan_month_obj_set = false;
      this.gendan_week_obj = [];
      this.gendan_week_obj_set = false;
      getUserObjectives(this.user.value, this.year).then((res) => {
        let content = res.content;
        let item;
        for (let i = 0; i < content.length; i++) {
          item = content[i];
          let { role, obj1, obj2, obj3, range_type, obj_type, init_value } =
            item;
          if (role === "jiedan") {
            if (obj_type === "jiedan") {
              if (range_type === "year") {
                this.jiedan_year_obj = [obj1, obj2, obj3];
                this.jiedan_year_obj_set = true;
                this.jiedan_year_init_value = init_value;
              }
              if (range_type === "month") {
                this.jiedan_month_obj = [obj1, obj2, obj3];
                this.jiedan_month_obj_set = true;
              }
              if (range_type === "week") {
                this.jiedan_week_obj = [obj1, obj2, obj3];
                this.jiedan_week_obj_set = true;
              }
            } else if (obj_type === "receipt") {
              if (range_type === "year") {
                this.jiedan_year_r_obj = [obj1, obj2, obj3];
                this.jiedan_year_r_obj_set = true;
                this.jiedan_year_r_init_value = init_value;
              }
              if (range_type === "month") {
                this.jiedan_month_r_obj = [obj1, obj2, obj3];
                this.jiedan_month_r_obj_set = true;
              }
              if (range_type === "week") {
                this.jiedan_week_r_obj = [obj1, obj2, obj3];
                this.jiedan_week_r_obj_set = true;
              }
            }
          }
          if (role === "fuzhujiedan") {
            if (obj_type === "jiedan") {
              if (range_type === "year") {
                this.fuzhujiedan_year_obj = [obj1, obj2, obj3];
                this.fuzhujiedan_year_obj_set = true;
                this.fuzhujiedan_year_init_value = init_value;
              }
              if (range_type === "month") {
                this.fuzhujiedan_month_obj = [obj1, obj2, obj3];
                this.fuzhujiedan_month_obj_set = true;
              }
              if (range_type === "week") {
                this.fuzhujiedan_week_obj = [obj1, obj2, obj3];
                this.fuzhujiedan_week_obj_set = true;
              }
            } else if (obj_type === "receipt") {
              if (range_type === "year") {
                this.fuzhujiedan_year_r_obj = [obj1, obj2, obj3];
                this.fuzhujiedan_year_r_obj_set = true;
                this.fuzhujiedan_year_r_init_value = init_value;
              }
              if (range_type === "month") {
                this.fuzhujiedan_month_r_obj = [obj1, obj2, obj3];
                this.fuzhujiedan_month_r_obj_set = true;
              }
              if (range_type === "week") {
                this.fuzhujiedan_week_r_obj = [obj1, obj2, obj3];
                this.fuzhujiedan_week_r_obj_set = true;
              }
            }
          }
          if (role === "yewugendan") {
            if (obj_type === "jiedan") {
              if (range_type === "year") {
                this.yewugendan_year_obj = [obj1, obj2, obj3];
                this.yewugendan_year_obj_set = true;
                this.yewugendan_year_init_value = init_value;
              }
              if (range_type === "month") {
                this.yewugendan_month_obj = [obj1, obj2, obj3];
                this.yewugendan_month_obj_set = true;
              }
              if (range_type === "week") {
                this.yewugendan_week_obj = [obj1, obj2, obj3];
                this.yewugendan_week_obj_set = true;
              }
            } else if (obj_type === "receipt") {
              if (range_type === "year") {
                this.yewugendan_year_r_obj = [obj1, obj2, obj3];
                this.yewugendan_year_r_obj_set = true;
                this.yewugendan_year_r_init_value = init_value;
              }
              if (range_type === "month") {
                this.yewugendan_month_r_obj = [obj1, obj2, obj3];
                this.yewugendan_month_r_obj_set = true;
              }
              if (range_type === "week") {
                this.yewugendan_week_r_obj = [obj1, obj2, obj3];
                this.yewugendan_week_r_obj_set = true;
              }
            }
          }
          if (role === "gendan") {
            if (obj_type === "shipment") {
              if (range_type === "year") {
                this.gendan_year_obj = [obj1, obj2, obj3];
                this.gendan_year_obj_set = true;
                this.gendan_year_init_value = init_value;
              }
              if (range_type === "month") {
                this.gendan_month_obj = [obj1, obj2, obj3];
                this.gendan_month_obj_set = true;
              }
              if (range_type === "week") {
                this.gendan_week_obj = [obj1, obj2, obj3];
                this.gendan_week_obj_set = true;
              }
            }
          }
        }
      });
    },
  },
  mounted: function () {
    this.getperioddata();
    document.title = "目标管理";
    let my_user_id = this.$store.state.user_id;
    if (my_user_id === 0) {
      this.user = { value: 0, label: "系统" };
      this.super_user = true;
    }
    getUsersForSelect().then((res) => {
      this.user_list = res.content;
      for (let i = 0; i < res.content.length; i++) {
        this.userid2names[res.content[i]["value"]] = res.content[i]["label"];
        if (my_user_id === res.content[i]["value"]) {
          this.super_user = res.content[i]["super_user"];
          this.user = { value: my_user_id, label: res.content[i]["label"] };
        }
      }
      getSingleUserRoles(this.user.value).then((res) => {
        this.user_roles = res.content;
      });
      this.getuserobjectives();
    });
  },
};
</script>

<style lang="less" scoped>
.objectives {
  .start-date,
  .main-content {
    padding: 5px;
  }
  .year {
    display: flex;
    .minus,
    .add {
      border: 1px solid #ddd;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      &:active {
        background: #ddd;
      }
    }
    .year-n {
      margin: 0 20px;
    }
  }
  .role-item {
    margin: 5px 0;
    .role-title {
      margin: 3px 0;
      font-size: 1.1em;
    }
    .obj-title {
      margin: 8px 0 0 0;
      .edit-button,
      .save-button {
        font-size: 0.9em;
        padding: 3px 6px;
        border-radius: 8px;
      }
      .edit-button {
        background: #e21232;
        color: #fff;
        &:active {
          background: cornsilk;
          color: #000;
        }
      }
      .save-button {
        background: #2ea44f;
        color: #fff;
        &:active {
          background: #ccc;
        }
      }
    }
  }
  .lt-body {
    max-width: 100%;
    overflow: auto;
    margin-bottom: 15px;
    &.immutable {
      .type {
        color: gray;
      }
      .lt-th {
        .type {
          color: #000;
        }
      }
    }
    .lt-tr {
      justify-content: left;
      .lt-td {
        text-align: left;
        flex-shrink: 0;

        &.type {
          width: 22%;
          font-size: 0.9em;
        }
        &.obj {
          width: 20%;
          input {
            width: 60px;
          }
        }
        &.init-value {
          width: 17%;
          input {
            width: 60px;
            max-width: 95%;
          }
        }
      }
      &.lt-th {
        .lt-td.type {
          font-size: 1em;
          padding-left: 1.3em;
        }
      }
    }
  }
}
</style>