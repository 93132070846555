import decode from 'jwt-decode';

export function isTokenExpired(token) {
    try {
        const decoded = decode(token);
        if (decoded.exp < Date.now() / 1000) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        return true;
    }
}

export function getUrlVar(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
}

export function getduarationarray(start_time) {
    if (!start_time) {
        return null;
    }
    let sec = new Date().getTime() - new Date(start_time).getTime();
    sec = Math.round(sec / 1000);
    let n = sec;
    let seconds = n % 60;
    n = (n - seconds) / 60; //总分钟数
    let minutes = n % 60;
    n = (n - minutes) / 60; //总小时数
    let hours = n % 24;
    n = (n - hours) / 24; //总天数
    let days = n;
    return [days, hours, minutes, seconds];
}