import request from "@/utils/request";

export function getexportlist(category = null) {
    return request.get('/getexportlist', { category })
}

export function exportdata() {
    return request.post("/exportdata", {});
}

export function downloadfile(filename) {
    return request.get('/downloadfile', { filename })
}

export function getchangelogs() {
    return request.get('/getchangelogs', {})
}