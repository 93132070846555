<template>
  <div class="batch-view">
    <div class="form-little-title">{{ action }}</div>
    <DatetimeItem
      :labelname="`${action}日期`"
      title="`${action}日期`"
      v-model="shiporreceipt_time"
    ></DatetimeItem>
    <SelectItem
      labelname="客户"
      :selectlist="customerList"
      v-model="customer"
    ></SelectItem>
    <br />
    <div class="single-order-input">
      <SelectItem
        labelname="订单"
        :selectlist="orderList"
        v-model="order"
        vertical
        class="order"
      ></SelectItem>
      <InputItem
        labelname="金额"
        v-model="shiporreceipt_amount"
        type="number"
        vertical
        class="amount"
      ></InputItem>
      <div class="stage-button">
        <div class="button" @click="cacheitem">暂存</div>
      </div>
    </div>
    <div v-if="order" :style="{ 'margin-left': '5px' }">
      订单 {{ order.order_no }} 总金额为 {{ order.amount }}，已累计{{ action }}
      {{
        order[action === "出货" ? "ship_amount" : "receipt_amount"]
          ? order[action === "出货" ? "ship_amount" : "receipt_amount"]
          : 0
      }}，还差{{
        order.amount -
        (order[action === "出货" ? "ship_amount" : "receipt_amount"]
          ? order[action === "出货" ? "ship_amount" : "receipt_amount"]
          : 0)
      }}
    </div>
    <div v-else :style="{ visibility: 'hidden' }">订单</div>
    <br />
    <div class="form-little-title">已暂存{{ action }}记录</div>
    <div
      v-show="itemscached.length === 0"
      :style="{ 'text-align': 'center', color: '#ccc' }"
    >
      无
    </div>
    <div class="stage-area" v-show="itemscached.length">
      <div class="lt-head">
        <div class="lt-tr">
          <div class="lt-td">订单合同</div>
          <div class="lt-td">{{ action }}金额</div>
          <div class="lt-td">完成</div>
        </div>
      </div>
      <div class="lt-body">
        <div class="lt-tr" v-for="(item, k) in itemscached" :key="k">
          <div class="lt-td">{{ item.order.order_no }}</div>
          <div class="lt-td">{{ item.shiporreceipt_amount }}</div>
          <div class="lt-td"><input type="checkbox" v-model="item.all" /></div>
        </div>
        <div
          class="lt-tr"
          :style="{ 'font-weight': 'bold' }"
          v-show="itemscached.length > 1"
        >
          <div class="lt-td">总金额</div>
          <div class="lt-td">{{ tatal_amount_cached }}</div>
        </div>
      </div>
    </div>

    <br />
    <div class="two-buttons-group">
      <Button @click.native="goBack">取消</Button>
      <Button type="primary" @click="() => batchshiporreceipt(action)"
        >批量{{ action }}</Button
      >
    </div>
    <br />
    <br />
    <div>
      说明：同一天内，同一个客户有多个订单同时{{
        action
      }}适合在这里操作；如果只是某个订单{{
        action
      }}，去订单列表单击订单操作更方便
    </div>
    <Dialog v-if="dialogshow" :dialogprops="dialogprops"></Dialog>
  </div>
</template>
<script>
import InputItem from "@/components/InputItem.vue";
import SelectItem from "@/components/SelectItem.vue";
import DatetimeItem from "@/components/DatetimeItem.vue";
import Button from "@/components/Button.vue";
import Dialog from "@/components/Dialog.vue";
import { getcustomerlist, getcustomerorderlist } from "@/api/customer";
import { batchshiporreceipt } from "@/api/order";

export default {
  components: { InputItem, SelectItem, DatetimeItem, Button, Dialog },
  props: { action: String },
  data: function () {
    return {
      customerList: [],
      customer: null,
      shiporreceipt_time: new Date(),
      shiporreceipt_amount: null,
      orderList: [],
      order: null,
      itemscached: [],
      dialogshow: false,
      dialogprops: {},
    };
  },
  watch: {
    customer(v) {
      let customer_id = v.value;
      this.orderList = [];
      this.order = null;
      this.itemscached = [];
      getcustomerorderlist(customer_id).then((res) => {
        this.orderList = JSON.parse(JSON.stringify(res.content));
      });
    },
    shiporreceipt_time(v) {
      console.log(v);
      this.itemscached = [];
    },
  },
  computed: {
    tatal_amount_cached() {
      return this.itemscached.reduce((total, cv) => {
        return total + cv.shiporreceipt_amount;
      }, 0);
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    cacheitem() {
      if (!this.order) {
        this.$store.commit("add_notification", {
          msg: "请先选择订单",
        });
        return;
      }
      if (!this.shiporreceipt_amount) {
        this.$store.commit("add_notification", {
          msg: `请填写${this.action}金额`,
        });
        return;
      }
      let already_amount;
      let total_amount = this.order["amount"];
      if (this.action === "出货") {
        already_amount = this.order["ship_amount"];
      } else {
        already_amount = this.order["receipt_amount"];
      }
      let shiporreceipt_amount = parseFloat(this.shiporreceipt_amount);
      if (!shiporreceipt_amount) {
        shiporreceipt_amount = 0;
      }
      let all = false;
      if (already_amount + shiporreceipt_amount === total_amount) {
        all = true;
      }
      this.$store.commit("add_notification", {
          msg: `${already_amount} ${shiporreceipt_amount} ${total_amount}`,
        });
      let shiporreceipt_time = new Date(this.shiporreceipt_time).format(
        "yyyy-MM-dd hh:mm:ss"
      );
      this.itemscached.push({
        order: this.order,
        shiporreceipt_amount: shiporreceipt_amount,
        shiporreceipt_time: shiporreceipt_time,
        total_amount: this.tatal_amount_cached,
        all: all,
      });
      this.orderList = this.orderList.filter((v) => v.id !== this.order.id);
      this.order = null;
      this.shiporreceipt_amount = null;
    },
    batchshiporreceipt(action) {
      if (!this.itemscached.length) {
        this.$store.commit("add_notification", {
          msg: `请先暂存${action}记录`,
        });
        return;
      }
      this.$store.commit("show_loading", "正在提交数据，请稍等......");
      batchshiporreceipt(this.itemscached, action)
        .then(() => {
          this.$store.commit("hide_loading");
          this.goBack();
        })
        .catch((e) => {
          this.$store.commit("hide_loading");
          this.$store.commit("add_notification", {
            msg: "提交失败：" + e,
          });
        });
    },
  },
  mounted: function () {
    document.title = this.action;
    getcustomerlist().then((res) => {
      let customerlist = res.content;
      this.customerList = customerlist;
    });
  },
};
</script>
<style lang='less'>
.batch-view {
  .single-order-input {
    display: flex;
    align-items: center;
    .order {
      width: 55%;
      padding-left: 5px;
    }
    .amount {
      width: 30%;
      padding-left: 5px;
    }
    .stage-button {
      width: 15%;
      padding: 0 3px 0 5px;
      align-self: stretch;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #108ee9;
        color: #fff;
        border-radius: 5px;
        height: 100%;
        width: 100%;
        &:active {
          background-color: #505339;
        }
      }
    }
  }
  .single-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .narrow {
      border-bottom: 1px solid #ddd;
      .input-label {
        min-width: 32px;
      }
    }
  }
  .stage-area {
    margin: 10px 15px;
    .lt-head,
    .lt-body {
      .lt-tr {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding: 6px 0;
      }
    }
  }
}
</style>
