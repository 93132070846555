<template>
  <div class="edit-customer">
    <div class="form-little-title">修改客户信息</div>
    <div class="customer-item">
      <InputItem
        labelname="客户代码"
        v-model="code"
        :disabled="!icons.code || icons.code.icon === 'edit' || true"
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.code ? icons.code.icon : 'edit'"
          @click.native="() => clickicon('code')"
        ></svg-icon>
      </div>
    </div>
    <div class="customer-item">
      <InputItem
        labelname="客户别名"
        v-model="alias_name"
        :disabled="!icons.alias_name || icons.alias_name.icon === 'edit'"
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.alias_name ? icons.alias_name.icon : 'edit'"
          @click.native="() => clickicon('alias_name')"
        ></svg-icon>
      </div>
    </div>
    <div class="customer-item">
      <InputItem
        labelname="客户全名"
        v-model="name"
        :disabled="!icons.name || icons.name.icon === 'edit'"
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.name ? icons.name.icon : 'edit'"
          @click.native="() => clickicon('name')"
        ></svg-icon>
      </div>
    </div>
    <div class="customer-item">
      <InputItem
        labelname="国家"
        v-model="country"
        :disabled="!icons.country || icons.country.icon === 'edit'"
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.country ? icons.country.icon : 'edit'"
          @click.native="() => clickicon('country')"
        ></svg-icon>
      </div>
    </div>
    <div class="customer-item">
      <InputItem
        labelname="市场"
        v-model="market"
        :disabled="!icons.market || icons.market.icon === 'edit'"
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.market ? icons.market.icon : 'edit'"
          @click.native="() => clickicon('market')"
        ></svg-icon>
      </div>
    </div>
    <div class="customer-item">
      <SelectItem
        labelname="接单人"
        :selectlist="jiedanList"
        v-model="jiedan"
        :disabled="!icons.jiedan || icons.jiedan.icon === 'edit'"
      ></SelectItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.jiedan ? icons.jiedan.icon : 'edit'"
          @click.native="() => clickicon('jiedan')"
        ></svg-icon>
      </div>
    </div>
    <div
      class="customer-item"
      v-if="!$store.state.roles_disabled.includes('fuzhujiedan')"
    >
      <SelectItem
        labelname="辅助接单"
        :selectlist="fuzhujiedanList"
        v-model="fuzhujiedan"
        multiple
        :disabled="!icons.fuzhujiedan || icons.fuzhujiedan.icon === 'edit'"
      ></SelectItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.fuzhujiedan ? icons.fuzhujiedan.icon : 'edit'"
          @click.native="() => clickicon('fuzhujiedan')"
        ></svg-icon>
      </div>
    </div>
    <div class="customer-item">
      <DatetimeItem
        labelname="新增日期"
        title="新增日期"
        v-model="create_time"
        :disabled="!icons.create_time || icons.create_time.icon === 'edit'"
      ></DatetimeItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.create_time ? icons.create_time.icon : 'edit'"
          @click.native="() => clickicon('create_time')"
        ></svg-icon>
      </div>
    </div>
  </div>
</template>
<script>
import InputItem from "@/components/InputItem.vue";
import SelectItem from "@/components/SelectItem.vue";
import DatetimeItem from "@/components/DatetimeItem.vue";
//import Button from "@/components/Button.vue";
import { getsinglecustomer, updatecustomer } from "@/api/customer";
import { getalltypeofuserlistfs } from "@/api/user";

export default {
  components: {
    InputItem,
    SelectItem,
    DatetimeItem /* ,Button, AddCustomer */,
  },
  props: { customer_id: [String, Number] },
  data: function () {
    return {
      customer: null,
      code: null,
      alias_name: null,
      name: null,
      country: null,
      market: null,
      jiedanList: [],
      jiedan: [],
      fuzhujiedanList: [],
      fuzhujiedan: [],
      modalshow: 0,
      icons: {},
      editable: {},
      modalshow4customer: false,
      create_time: null,
    };
  },
  watch: {},
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    canceloperation() {
      this.customer = null;
      this.modalshow4customer = false;
    },
    clickicon(field) {
      if (!this.editable[field]) {
        this.$store.commit("add_notification", {
          msg: "此字段不可以修改",
        });
        return;
      }
      let a = {};
      let value = null;
      if (!this.icons[field] || this.icons[field].icon === "edit") {
        a[field] = { icon: "save" };
        this.icons = Object.assign({}, this.icons, a);
      } else {
        // 点了保存按钮
        a[field] = { icon: "edit" };
        if (
          field === "code" ||
          field === "alias_name" ||
          field === "name" ||
          field === "country" ||
          field === "market"
        ) {
          let field_name = {
            code: "客户代码",
            alias_name: "客户别名",
            name: "客户全名",
            country: "所在国家",
            market: "所在市场",
          }[field];
          value = {
            code: this.code,
            alias_name: this.alias_name,
            name: this.name,
            country: this.country,
            market: this.market,
          }[field];
          if (value === this.customer[field]) {
            this.$store.commit("add_notification", {
              msg: field_name + "没有修改",
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
        } else if (field === "jiedan") {
          let cur_obj = this.jiedan;
          let pre_obj = this.customer.jiedanList;
          let msg = "接单人没有修改";
          let cur_value = cur_obj ? cur_obj.value : null;
          let pre_value = pre_obj ? pre_obj.value : null;
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.jiedan;
        } else if (field === "fuzhujiedan") {
          let cur_value = String(this.fuzhujiedan.map(v => v.value));
          let pre_value = String(this.customer.fuzhujiedanList.map(v => v.value));
          let msg = "辅助接单人没有修改";
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.fuzhujiedan;
        } else if (field === "yewugendan") {
          let cur_obj = this.yewugendan;
          let pre_obj = this.customer.yewugendan;
          let msg = "业务跟单没有修改";
          let cur_value = cur_obj ? cur_obj.value : null;
          let pre_value = pre_obj ? pre_obj.value : null;
          if (cur_value === pre_value) {
            this.$store.commit("add_notification", {
              msg: msg,
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.yewugendan;
        } else if (field === "create_time") {
          if (
            new Date(this.create_time).format("yyyy-MM-dd") ===
            new Date(this.customer.create_time).format("yyyy-MM-dd")
          ) {
            this.$store.commit("add_notification", {
              msg: "新增日期没有修改",
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = new Date(this.create_time).format("yyyy-MM-dd 00:00:00");
        }
        let that = this;
        let customer_id = this.customer_id;
        updatecustomer({ field, value, customer_id })
          .then(() => {
            that.$store.commit("add_notification", {
              msg: "修改成功",
              status: "success",
            });
            this.icons = Object.assign({}, this.icons, a);
            this.getsinglecustomer();
            this.$emit("changed", null);
          })
          .catch((e) => {
            that.$store.commit("add_notification", {
              msg: "修改失败：" + e,
            });
          });
        console.log(field, value);
        // 保存按钮点击逻辑结束
      }
    },
    getsinglecustomer() {
      getsinglecustomer(this.customer_id).then((res) => {
        let data = res.content;
        this.customer = JSON.parse(JSON.stringify(data));
        this.code = data.code;
        this.alias_name = data.alias_name;
        this.name = data.name;
        this.jiedan = null;
        if (data.jiedanList.length > 0) {
          this.jiedan = data.jiedanList[0];
        }
        this.fuzhujiedan = [];
        if (data.fuzhujiedanList.length > 0) {
          this.fuzhujiedan = data.fuzhujiedanList;
        }
        this.country = data.country;
        this.market = data.market;
        this.editable = {
          code: false,
          alias_name: true,
          name: true,
          jiedan: true,
          fuzhujiedan: true,
          country: true,
          market: true,
          create_time: true,
        };
      });
    },
  },
  mounted: function () {
    this.getsinglecustomer();
    getalltypeofuserlistfs().then((res) => {
      this.jiedanList = res.content.jiedanList;
      this.fuzhujiedanList = res.content.fuzhujiedanList;
      this.yewugendanList = res.content.yewugendanList;
    });
  },
};
</script>
<style lang='less'>
.edit-customer {
  height: 100%;
  position: relative;
  overflow-y: scroll;
  padding: 8px;
  .customer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .edit-icon {
      width: 25px;
      height: 25px;
      margin-left: 8px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .confirm-panel {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    height: 45px;
    width: 100%;
    .v-button {
      flex: 1 1 0;
      border-radius: 0px;
    }
    .v-button.left {
      border-bottom-left-radius: 7px;
    }
    .v-button.right {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 7px;
    }
    .v-button.middle {
      border-radius: 0px;
    }
  }
}
</style>