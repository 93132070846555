<template>
  <div class="dialog-component">
    <div class="dialog-mask"></div>
    <div class="dialog-body">
      <div class="dialog-content">
        <div class="dialog-title">{{ dialogprops.title }}</div>
        <br />
        <div class="dialog-desc">{{ dialogprops.desc }}</div>
      </div>
      <div class="dialog-buttons">
        <Button @click="() => dialogprops.cancel()">{{
          dialogprops.canceltext ? dialogprops.canceltext : "取消"
        }}</Button>
        <Button type="primary" @click="() => dialogprops.confirm()">{{
          dialogprops.confirmtext ? dialogprops.confirmtext : "确定"
        }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";

export default {
  props: {
    dialogprops: Object,
  },
  components: { Button },
  data: function () {
    return {};
  },
};
</script>
<style scoped lang='less'>
.dialog-component {
  position: absolute;
  right: 0;
  left: 0;
  max-height: 98vh;
  top: 50%;
  height: 400px;
  transform: translate(0, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;

  .dialog-mask {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .dialog-body {
    position: absolute;
    right: 20px;
    left: 20px;
    min-height: 200px;
    top: 50%;
    transform: translate(0, -50%);
    background-color: white;
    border-radius: 7px;
    .dialog-content {
      padding: 20px 20px 45px 20px;
      text-align: center;
      .dialog-title {
        font-weight: bold;
        font-size: 1.2em;
      }
      .dialog-desc {
        color: #333;
      }
    }
    .dialog-buttons {
      position: absolute;
      display: flex;
      justify-content: space-between;
      bottom: 0;
      height: 45px;
      width: 100%;
      .v-button {
        flex: 1 1 0;
        border-radius: 0px;
        border-bottom-left-radius: 7px;
      }
      .v-button-primary,
      .v-button-remind {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 7px;
      }
    }
  }
}
</style>