<template>
  <div class="list-item">
    <div class="list-left" @click="clickcallback">
      <div class="list-index">{{ index }}.</div>
      <div class="list-main">
        <div class="list-item-1"><slot name="title"></slot></div>
        <div class="list-item-2">
          <slot name="brief"></slot>
        </div>
      </div>
    </div>
    <div class="list-right" @click="$emit('edititem')">
      <div><slot name="extra"></slot></div>
      <svg-icon iconName="view" v-if="showIcon"></svg-icon>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: { name: String, index: Number, showIcon: Boolean },
  data: function () {
    return {
      clicked: false,
    };
  },
  methods: {
    clickcallback() {
      if (this.clicked) {
        this.clicked = false;
        this.$emit("doubleclick");
      } else {
        let that = this;
        that.clicked = true;
        window.setTimeout(() => {
          that.clicked = false;
        }, 300);
      }
    },
  },
};
</script>
<style lang='less' scoped>
.list-item {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 3px 10px 8px 0;
  border-bottom: 1px #ddd solid;

  .list-left {
    display: flex;
    flex-grow: 1;

    .list-index {
      font-size: 1.08em;
      margin: 6px 4px 6px 0;
      line-height: 23px;
      text-align: right;
      min-width: 24px;
      flex-shrink: 0;
      color: #bbb;
    }
    .list-item-1 {
      font-size: 1.05em;
      line-height: 23px;
      margin: 6px 0;
    }

    .list-item-2 {
      color: #444;
      font-size: 0.85em;
    }
  }
}
.list-right {
  display: flex;
  align-items: center;
  text-align: center;
  .svg-icon {
    width: 15px;
    height: 15px;
    margin-left: 4px;
  }
}
.list-item:active {
  background-color: #d8d8d8;
}
</style>