<template>
  <div id="app">
    <router-view v-if="loggedIn && config_got" />
    <div v-if="!loggedIn">Permission denied!</div>
    <div class="notifications">
      <transition-group name="list-complete">
        <div
          class="notification"
          v-for="item in $store.state.notifications"
          :key="item.key"
        >
          <svg-icon
            :iconName="item.status === 'warning' ? 'info' : item.status"
            :class="item.status"
          ></svg-icon
          >{{ item.msg }}
        </div>
      </transition-group>
    </div>
    <div class="loading" v-show="$store.state.loading">
      <div class="content">{{ $store.state.loading_msg }}</div>
    </div>
  </div>
</template>
<script>
import { isTokenExpired, getUrlVar } from "@/utils/tool";
import { login, heartbeat, getbasicconfig } from "@/api/auth";

export default {
  data: function () {
    return {
      loggedIn: false,
      config_got: false,
    };
  },
  methods: {
    reload() {
      window.location.reload();
    },
    getbasicconfig() {
      getbasicconfig()
        .then((res) => {
          console.log(res);
          let content = res.content;
          let sysconf = content.sysconf;
          this.$store.commit("set_logistics_enabled", sysconf.enable_logistics);
          let roles_disabled = content.sysconf.roles_disabled;
          this.$store.commit("set_user", content.user);
          console.log('user: ', content.user);
          let super_user = content.super_user;
          let min_version = content.min_version;
          if (
            content.sysconf.min_version &&
            content.sysconf.min_version > min_version
          ) {
            min_version = content.sysconf.min_version;
          }
          if (min_version && min_version > this.$store.state.version) {
            this.$store.commit("add_notification", {
              msg: '当前版本低，请"强制刷新"',
              seconds: 4,
            });
            this.need_refresh = true;
            this.reload();
          }
          if (roles_disabled) {
            roles_disabled = roles_disabled.split(",");
            this.$store.commit("set_roles_disabled", roles_disabled);
          }
          this.$store.commit("set_super_user", super_user);
          this.config_got = true;
        })
        .finally(() => {
          if (window.location.pathname === "/") {
            this.$router.push("/home");
          }
        });
    },
  },
  mounted: function () {
    //console.log("sssssss");
    let token = this.$store.state.token;
    if (isTokenExpired(token)) {
      // code 过期
      this.$store.commit("logout");
      let code = getUrlVar("code");
      if (code) {
        // url 中带了code，从企业微信网页登陆重定向过来, 用这个code登陆
        login(code)
          .then((res) => {
            if (!res.content) {
              // 没有 jwt-token
              return;
            }
            this.loggedIn = true;
            this.$store.commit("login", res.content); // jwt-token
            window.history.replaceState(
              "",
              "",
              window.origin + window.location.pathname
            );
            this.getbasicconfig();
          })
          .catch(() => {
            // 登陆失败，发心跳包再次获取oauth地址
            heartbeat();
          });
      } else {
        // url中无code，发心跳包，获取oauth地址
        heartbeat();
      }
    } else {
      this.loggedIn = true;
      this.getbasicconfig();
    }
  },
};
</script>
<style lang="less">
*,
::after,
::before {
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin-top: 0;
  margin: 0;
}
.inline-block {
  display: inline-block;
  margin-right: 9px;
}
a.no-decoration {
  text-decoration: none;
  color: #000;
}
.form-little-title {
  padding: 10px 0px 2px;
  margin-left: 5px;
  border-bottom: 1px solid #aaa;
  margin-bottom: 5px;
  color: #555;
  font-size: 0.9em;
}
.two-buttons-group {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  .v-button {
    width: 100%;
    margin: 0 6px;
  }
}
.notifications {
  position: fixed;
  top: 40px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 9999;
  .notification {
    transition: all 1s;
    width: 100%;
    background: #fafafa;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 8px 12px;
    margin: 5px 0;
    border-radius: 8px;
    .svg-icon {
      width: 20px;
      height: 20px;
      margin: 0 8px -4px 0;
    }
    .warning {
      color: #faad14;
    }
    .info {
      color: #1890ff;
    }
    .success {
      color: #52c41a;
    }
    .error {
      color: #ff4d4f;
    }
  }
}
.list-complete-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.list-complete-leave-to {
  opacity: 0;
}
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .content {
    border-radius: 10px;
    padding: 20px;
  }
}
.lt-head,
.lt-body {
  .lt-tr {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #bbb;
    padding: 6px 0;
  }
}
#app {
  background: #f6f6f6;
  width: 100%;
  min-height: 100vh;
}
.main-container {
  position: relative;
  max-width: 760px;
  margin: 0 auto;
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.06) inset;
  &.wide {
    max-width: 850px;
  }
}
body {
  padding: 0;
  margin: 0;
}
.filter-area {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .left-title {
    padding-right: 5px;
  }
  .search {
    flex-grow: 1;
  }
  .filter-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 0 8px;
    flex-shrink: 0;
    background: #eee;
    margin: 1px 2px;
    border-radius: 5px;
    font-size: 0.75em;
    &.active {
      background: #cce5ff;
    }
  }
  .break {
    border-right: 1px solid #ccc;
    min-height: 20px;
    margin: 0 4px;
  }
}
.break-line {
  padding: 4px 0;
}
</style>