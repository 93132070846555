<template>
  <div :class="{ 'input-item': !vertical, 'input-item-vertical': vertical }">
    <div class="input-label" v-if="labelname">{{ labelname }}</div>
    <div class="select-control">
      <vSelect
        :options="selectlist"
        :value="value"
        v-on:input="$emit('input', $event)"
        :disabled="disabled"
        :searchable="searchable"
        :clearable="clearable"
        :multiple="multiple"
        :appendToBody="appendToBody"
        ><template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            没有找到 '<em>{{ search }}</em
            >'
          </template>
          <em style="opacity: 0.5" v-else>(空)</em>
        </template></vSelect
      >
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: { /*SelectComponent, SelectItemComponent*/ vSelect },
  props: {
    labelname: String,
    selectlist: Array,
    value: [Object, Array, String],
    disabled: Boolean,
    labelInValue: Boolean,
    searchable: Boolean,
    clearable: Boolean,
    vertical: Boolean,
    multiple: Boolean,
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {};
  },
};
</script>
<style lang="less">
.small-size {
  .select-control {
    .v-select {
      .vs__selected {
        font-size: 14px;
      }
    }
  }
}
.select-control {
  width: 100%;
  .v-select {
    &.vs--single {
      .vs__dropdown-toggle {
        .vs__selected-options {
          width: calc(100% - 30px);
        }
        .vs__selected {
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .vs__dropdown-toggle {
      padding: 0;
      min-height: 29px;
      background: #fff;
      .vs__selected-options {
        padding-left: 3px;
      }
      .vs__selected {
        padding: 0 1px;
        margin: 1px 0;
        .vs__deselect {
          margin-left: 2px;
          margin-right: 2px;
        }
      }
      input.vs__search {
        padding: 0;
        margin-top: 0;
        max-width: 0;
        max-height: 0;
      }
      .vs__actions {
        padding: 2px 4px 2px 0px;
        .vs__clear {
          margin-right: 2px;
        }
      }
    }
    &.vs--open {
      .vs__dropdown-toggle {
        .vs__selected {
          min-height: 27px;
        }
        input.vs__search {
          max-width: 100%;
          line-height: 1;
          max-height: none;
        }
      }
    }
    &.vs--disabled {
      .vs__dropdown-toggle {
        background-color: #fafafa;
        .vs__actions {
          .vs__clear {
            display: none;
          }
          .vs__open-indicator {
            fill: #b5b5b5;
          }
        }
      }
    }
  }
}
.low-menu {
  .vs__dropdown-menu {
    max-height: 250px;
  }
}
</style>