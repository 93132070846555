<template>
  <div class="footer">
    <router-link
      :to="item.url"
      v-for="(item, index) in footerList1"
      :key="index"
      class="footer-item-a"
    >
      <svg-icon :iconName="item.icona" v-show="item.url===$route.path"></svg-icon>
      <svg-icon :iconName="item.icon" v-show="item.url!==$route.path"></svg-icon>
      <div class="title">{{ item.name }}</div>
    </router-link>
    <router-link
      :to="item.url"
      v-for="(item, index) in footerList2"
      :key="index + 2"
      class="footer-item-a"
    >
      <svg-icon :iconName="item.icona" v-show="item.url===$route.path"></svg-icon>
      <svg-icon :iconName="item.icon" v-show="item.url!==$route.path"></svg-icon>
      <div class="title">{{ item.name }}</div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "FooterBar",
  props: {},
  data: function () {
    return {
      showAddPop: false,
      footerList1: [
        {
          name: "首页",
          icon: "home",
          icona: "home-selected",
          url: "/home",
        },
        {
          name: "销售",
          icon: "orders",
          icona: "orders",
          url: "/sales",
        },
      ],
      footerList2: [
        /*{
          name: "分析",
          icon: "statistics",
          icona: "statistics-selected",
          url: "/statistics",
        },*/
        {
          name: "我的",
          icon: "user",
          icona: "user-selected",
          url: "/admin",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang='less' scoped>
.footer {
  position: fixed;
  z-index: 99;
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  width: 100%;
  max-width: 760px;
  bottom: 0;
  border-top: 1px solid #ddd;
  background-color: #fff;
  text-align: center;

  a.footer-item-a {
    color: #333;
    text-decoration: none;
    flex: 1 1 0;
    display: block;
    width: 100%;

    div.title {
      font-size: 14px;
      margin-top: -2px;
    }

    div.add a {
      color: #333;
      text-decoration: none;
    }
    div.add {
      padding: 9px;
      border-top: 1px solid #ddd;
    }
    div.add-break-line {
      border-top: 1px solid #ddd;
    }

    .svg-icon {
      width: 15px;
      height: 15px;
    }
  }
  a.router-link-active {
    color: rgb(51, 163, 244);
  }
}
</style>