import axios from "axios";
import $store from "@/store";

const VUE_APP_API_URL = process.env.VUE_APP_API_URL || `${location.origin}/api`;

const instance = axios.create({
    baseURL: VUE_APP_API_URL,
    timeout: 15000
});

const defaultOpt = {};

function baseRequest(options) {
    const token = $store.state.token;
    const headers = options.headers || {}
    headers["Authorization"] = "JWT " + token;
    options.headers = headers;
    return instance(options).then((res => {
        const data = res.data || {};

        if (res.status !== 200) {
            console.log(1);
            return Promise.reject({ msg: "请求失败", res, data });
        }
        if (data.errcode === -1) {
            // 鉴权失败，重定向到企业微信鉴权页面
            console.log(data.content);
            $store.commit("logout");
            window.location = data.content;
        } else if (data.errcode >= 1) {
            // 出错
            return Promise.resolve(data, res);
        } else {
            //return Promise.reject({ msg: res.data.msg, res, data });
            return Promise.resolve(data, res);
        }
    }))
}

const request = ["post", "put", "patch"].reduce((request, method) => {
    /**
     * @param url String 接口地址
     * @param data Object post参数
     * @param options Object axios配置项
     * @return {AxioPromise}
     */
    request[method] = (url, data = {}, options = {}) => {
        return baseRequest(Object.assign({ url, data, method }, defaultOpt, options))
    };
    return request;
}, {});

["get", "delete", "head"].forEach(method => {
    request[method] = (url, params = {}, options = {}) => {
        return baseRequest(Object.assign({ url, params, method }, defaultOpt, options))
    };
});

export default request;