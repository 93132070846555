<template>
  <div class="home main-container">
    <div class="home-content">
      <div class="title-pan">
        <div class="title-pan-text" v-if="user">
          绩效盘
          <div
            class="change-view"
            v-if="user.leader_user"
            @click="() => (getcorp = !getcorp)"
          >
            {{ getcorp ? "全" : "个" }}
          </div>
        </div>
        <div class="title-pan-text" v-else>个人绩效</div>
        <div class="filter-buttons">
          <div
            class="filter-button left"
            v-if="range_obj.this_month"
            :class="{ active: range === 'this_month' }"
            @click="() => (range = 'this_month')"
          >
            本月
          </div>
          <div
            class="filter-button middle"
            v-if="range_obj.last_month"
            :class="{ active: range === 'last_month' }"
            @click="() => (range = 'last_month')"
          >
            上月
          </div>
          <div
            class="filter-button middle"
            v-if="range_obj.this_week"
            :class="{ active: range === 'this_week' }"
            @click="() => (range = 'this_week')"
          >
            本周
          </div>
          <div
            class="filter-button middle"
            v-if="range_obj.last_week"
            :class="{ active: range === 'last_week' }"
            @click="() => (range = 'last_week')"
          >
            上周
          </div>
          <div
            class="filter-button right"
            v-if="range_obj.this_year"
            :class="{ active: range === 'this_year' }"
            @click="() => (range = 'this_year')"
          >
            本年
          </div>
        </div>
      </div>
      <div class="jixiao-content">
        <div class="jixiao-item" v-for="(item, k) in data" :key="k">
          <div class="range" v-if="range_obj[range]">
            {{ range_obj[range]["name"] }} {{ user.name }}
          </div>
          <div class="real-data">
            <div class="text">{{ item.type }}($)</div>
            <div class="number">
              {{ (Math.round(item.amount) / 10000).toLocaleString()
              }}{{ item.amount ? "w" : "" }}
            </div>
          </div>
          <div
            class="obj-data"
            v-if="
              objectives[item['roleandtype']] &&
              objectives[item['roleandtype']].reduce((a, c) => a + c)
            "
          >
            <div class="text">
              <div class="objective-inline">目标</div>
              <div class="objective-inline remark">
                <span>(</span>
                <span v-if="objectives[item['roleandtype']][0]">必达/</span
                ><span v-if="objectives[item['roleandtype']][1]">冲刺/</span
                ><span v-if="objectives[item['roleandtype']][2]">最终</span
                ><span>)($)</span>
              </div>
            </div>
            <div class="number">
              <span v-if="objectives[item['roleandtype']][0]"
                >{{ objectives[item["roleandtype"]][0] }}/</span
              ><span v-if="objectives[item['roleandtype']][1]"
                >{{ objectives[item["roleandtype"]][1] }}/</span
              ><span v-if="objectives[item['roleandtype']][2]"
                >{{ objectives[item["roleandtype"]][2] }}w</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterBar></FooterBar>
    <Add></Add>
  </div>
</template>

<script>
// @ is an alias to /src
import FooterBar from "@/components/FooterBar.vue";
import Add from "@/components/Add.vue";
import { getPersonalData, getCorpData, getRangeObj } from "@/api/user";
export default {
  name: "Home",
  components: { FooterBar, Add },
  data: function () {
    return {
      data: [],
      range: "this_year",
      range_obj: {},
      user: null,
      objectives: {},
      getcorp: true,
    };
  },
  watch: {
    range(v) {
      if (this.getcorp) {
        this.getcorpdata(v);
      } else {
        this.getpersonaldata(v);
      }
    },
    getcorp() {
      if (this.getcorp) {
        this.getcorpdata(this.range);
      } else {
        this.getpersonaldata(this.range);
      }
    },
  },
  methods: {
    getpersonaldata(v) {
      this.data = this.data.map((v) => {
        v.amount = ".";
        return v;
      });
      let start_time;
      let end_time;
      if (!this.range_obj[v]) {
        return;
      }
      start_time = this.range_obj[v]["start_time"];
      end_time = this.range_obj[v]["end_time"];
      /*
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth();
      if (v === "this_month") {
        start_time = new Date(year, month, 1);
        end_time = new Date(year, month + 1, 0);
      } else if (v === "last_month") {
        start_time = new Date(year, month - 1, 1);
        end_time = new Date(year, month, 0);
      } else if (v === "this_year") {
        if (month > 0) {
          start_time = new Date(year, 1, 1);
          end_time = new Date(year + 1, 1, 1);
        } else {
          start_time = new Date(year - 1, 1, 1);
          end_time = new Date(year, 1, 1);
        }
      } else if (v === "last_year") {
        start_time = new Date(year - 1, 0, 1);
        end_time = new Date(year, 0, 0);
      } else if (v === "this_week") {
        start_time = null;
        end_time = null;
      }
      */
      let time_range_id = this.range_obj[v]["id"];
      getPersonalData(
        start_time,
        end_time,
        this.$store.state.user_id,
        time_range_id
      ).then((res) => {
        this.data = res.content["data"];
        this.user = res.content["user"];
        let t = res.content["objectives"];
        let objectives = {};
        for (let i = 0; i < t.length; i++) {
          let { role, obj_type, obj1, obj2, obj3 } = t[i];
          objectives[role + "_" + obj_type] = [obj1, obj2, obj3];
        }
        this.objectives = objectives;
      });
    },
    getcorpdata(v) {
      this.data = this.data.map((v) => {
        v.amount = ".";
        return v;
      });
      let start_time;
      let end_time;
      if (!this.range_obj[v]) {
        return;
      }
      start_time = this.range_obj[v]["start_time"];
      end_time = this.range_obj[v]["end_time"];
      let time_range_id = this.range_obj[v]["id"];
      getCorpData(start_time, end_time, time_range_id).then((res) => {
        this.data = res.content["data"];
        this.user = res.content["user"];
        let t = res.content["objectives"];
        let objectives = {};
        for (let i = 0; i < t.length; i++) {
          let { role, obj_type, obj1, obj2, obj3 } = t[i];
          objectives[role + "_" + obj_type] = [obj1, obj2, obj3];
        }
        this.objectives = objectives;
      });
    },
  },
  mounted: function () {
    document.title = "首页";
    getRangeObj().then((res) => {
      this.range_obj = res.content;
      if (this.getcorp) {
        this.getcorpdata(this.range);
      } else {
        this.getpersonaldata(this.range);
      }
    });
  },
};
</script>

<style lang="less" scoped>
.home-content {
  position: fixed;
  background: #eee;
  width: 100%;
  max-width: 760px;
  height: 100vh;
  padding-bottom: 50px;
  overflow-y: auto;
  .title-pan {
    .title-pan-text {
      white-space: nowrap;
      .change-view {
        display: inline-block;
        font-size: 0.9em;
        padding: 2px 6px;
        border: 1px solid#33a3f4;
        border-radius: 50%;
        background: #33a3f4;
        color: #fff;
        &:active {
          background: #0367fc;
          border: 1px solid #0367fc;
        }
      }
    }
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    width: 100%;
    max-width: 760px;
    height: 50px;
    background: #fff;
    padding: 10px 5px;
    border-bottom: 1px solid #eee;
    z-index: 999;
    .filter-buttons {
      display: flex;
      overflow-x: hidden;
      margin-left: 20px;
      .filter-button {
        flex-shrink: 0;
        padding: 5px 8px;
        background: #eee;
        font-size: 0.8em;
        &.active {
          background: #cce5ff;
        }
        &.left {
          border-radius: 5px 0 0 5px;
        }
        &.right {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
  .jixiao-content {
    padding-top: 52px;
    .jixiao-item {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-around;
      background: #fff;
      height: 120px;
      margin-bottom: 8px;
      text-align: center;
      padding: 0 12%;
      .real-data,
      .obj-data {
        flex: 1 1 0;
      }
      .obj-data {
        .objective-inline {
          display: inline-block;
          &.remark {
            font-size: 0.8em;
          }
        }
        .number {
          font-size: 1.4em;
        }
      }
      .range {
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px 0 0 5px;
        font-size: 0.85em;
      }
      .text {
        font-size: 0.9em;
      }
      .number {
        font-size: 1.4em;
      }
    }
  }
}
</style>