<template>
  <div id="admin" class="main-container">
    <div class="avatar-name">
      <div class="headpic">
        <img v-if="thumb_avatar" class="avatar" :src="thumb_avatar" />
        <svg-icon v-if="!thumb_avatar" iconName="avatar"></svg-icon>
      </div>
      <div class="text">
        <div class="name">{{ name }}</div>
        <div class="remark-line">
          <div class="userid">uid: {{ userid }}</div>
        </div>
      </div>
    </div>
    <div class="reload item-1" @click="reload">
      <svg-icon iconName="refresh"></svg-icon>
      <div class="title">强制刷新</div>
    </div>
    <router-link to="/users" class="no-decoration" v-if="super_user">
      <div class="employee item-1">
        <svg-icon iconName="members"></svg-icon>
        <div class="title">人员管理</div>
      </div>
    </router-link>
    <router-link to="/periods" class="no-decoration" v-if="super_user">
      <div class="employee item-1">
        <svg-icon iconName="maintain"></svg-icon>
        <div class="title">周期管理</div>
      </div>
    </router-link>
    <router-link to="/objectives" class="no-decoration">
      <div class="employee item-1">
        <svg-icon iconName="objective"></svg-icon>
        <div class="title">目标管理</div>
      </div>
    </router-link>
    <router-link to="/customers" class="no-decoration" v-if="super_user">
      <div class="employee-admin-item item-1">
        <svg-icon iconName="members"></svg-icon>
        <div class="title">客户管理</div>
      </div>
    </router-link>
    <router-link to="/exports" class="no-decoration" v-if="super_user">
      <div class="exports-admin-item item-1">
        <svg-icon iconName="export"></svg-icon>
        <div class="title">数据导出</div>
      </div>
    </router-link>
    <router-link to="/performance" class="no-decoration" v-if="leader_user">
      <div class="employee item-1">
        <svg-icon iconName="performance"></svg-icon>
        <div class="title">绩效统计</div>
      </div>
    </router-link>
    <router-link
      to="/orderstats"
      class="no-decoration"
      v-if="
        leader_user ||
        (roles && (roles.jiedan.enabled || roles.fuzhujiedan.enable))
      "
    >
      <div class="employee item-1">
        <svg-icon iconName="performance"></svg-icon>
        <div class="title">接单统计</div>
      </div>
    </router-link>
    <router-link to="/changelogs" class="no-decoration" v-if="leader_user">
      <div class="employee item-1">
        <svg-icon iconName="project"></svg-icon>
        <div class="title">修改记录</div>
      </div>
    </router-link>

    <div class="version">前端版本: {{ $store.state.version }}</div>
    <br />
    <br />
    <br />
    <br />
    <FooterBar></FooterBar>
  </div>
</template>

<script>
// @ is an alias to /src
import FooterBar from "@/components/FooterBar.vue";
import { getUserProfile } from "@/api/user";

export default {
  name: "Home",
  data: function () {
    return {
      name: "",
      thumb_avatar: null,
      userid: null,
      logmsg: "",
      super_user: false,
      leader_user: false,
      roles: null,
    };
  },
  methods: {
    reload() {
      this.$store.commit("logout");
      window.location.reload();
    },
  },
  components: {
    FooterBar,
  },
  mounted: function () {
    document.title = "我";
    const user_id = this.$store.state.user_id;
    getUserProfile(user_id)
      .then((res) => {
        let content = res.content;
        this.name = content.name;
        this.thumb_avatar = content.thumb_avatar;
        this.userid = content.userid;
        this.logmsg = res;
        this.super_user = content.super_user;
        this.leader_user = content.leader_user;
        this.roles = content.roles;
        this.$store.commit("set_super_user", content.super_user);
      })
      .catch((e) => {
        this.logmsg = e;
      });
  },
};
</script>

<style lang='less' scoped>
#admin {
  background-color: #efefef;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 50px;
  overflow-y: scroll;

  .avatar-name {
    display: flex;
    padding: 16px;
    background-color: #fcfcfc;
    margin: 10px;
    .headpic {
      width: 60px;
      height: 60px;
      img {
        width: 60px;
        height: 60px;
        border-radius: 5px;
      }
      .svg-icon {
        width: 60px;
        height: 60px;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 5px 12px;

      .name {
        font-weight: 600;
      }
      .remark-line {
        color: #888;
        display: flex;
        align-items: center;
        .userid {
          margin-right: 20px;
        }
      }
    }
  }
  .item-1 {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fcfcfc;
    border-top: 1px solid#efefef;
    .svg-icon {
      width: 24px;
      height: 24px;
    }
    .title {
      line-height: 24px;
      padding-left: 10px;
      height: 24px;
    }
  }
  a.no-decoration {
    color: #000;
    text-decoration: none;
  }
  .version {
    text-align: center;
    margin-top: 30px;
    font-size: 0.7em;
    color: #888;
  }
}
</style>