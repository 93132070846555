<template>
  <div class="sales main-container">
    <Tabs
      :tabs="tabs"
      :sizeobj="sizeobj"
      :currentTab="currentTab"
      @switchTab="currentTab = $event"
    >
      <div>
        <!-- 订单 -->
        <div class="filter-area">
          <div
            class="filter-button"
            :class="{ active: order_category === 'notallshipped' }"
            @click="() => (order_category = 'notallshipped')"
          >
            未出完
          </div>
          <div
            class="filter-button"
            :class="{ active: order_category === 'allshipped' }"
            @click="() => (order_category = 'allshipped')"
          >
            已出完
          </div>
          <div
            class="filter-button"
            :class="{ active: order_category === 'allpaid' }"
            @click="() => (order_category = 'allpaid')"
          >
            已结清
          </div>
          <div class="break"></div>
          <div
            class="filter-button"
            :class="{ active: order_category === 'deleted' }"
            @click="() => (order_category = 'deleted')"
          >
            已删除
          </div>
        </div>
        <div class="filter-area2">
          <div
            class="filter-button"
            :class="{ active: confirm_status === 0 }"
            @click="() => confirm_status_clicked(0)"
          >
            未确认 {{ order_confirm_status_cnts[0] }}
          </div>
          <div
            class="filter-button"
            :class="{ active: confirm_status === 1 }"
            @click="() => confirm_status_clicked(1)"
          >
            已确认未付定金 {{ order_confirm_status_cnts[1] }}
          </div>
          <div
            class="filter-button"
            :class="{ active: confirm_status === 2 }"
            @click="() => confirm_status_clicked(2)"
          >
            已付定金或无需定金 {{ order_confirm_status_cnts[2] }}
          </div>
        </div>
        <SearchBar v-model="order_keyword"></SearchBar>
        <ListItem
          v-for="(item, key) in orders"
          :key="key"
          :index="key + 1"
          @click.native="() => showmenu(item)"
          v-show="
            !order_keyword ||
            item.order_no.includes(order_keyword) ||
            item.customer_name.includes(order_keyword)
          "
        >
          <template v-slot:title>合同号: {{ item.order_no }}</template>
          <template v-slot:extra><div>.</div> </template>
          <template v-slot:brief>
            <div>
              <div class="inline-item">接单: {{ item["jiedan"]["name"] }}</div>
              <div class="inline-item">
                日期: {{ new Date(item.order_time).format("yyyy-M-d") }}
              </div>
              <div class="inline-item">客户: {{ item["customer_name"] }}</div>
            </div>
            <div>
              <div
                class="inline-item"
                v-if="
                  !$store.state.roles_disabled.includes('fuzhujiedan') &&
                  item['fuzhujiedan'] &&
                  item['fuzhujiedan'].length > 0
                "
              >
                辅助接单:
                {{ item["fuzhujiedan"].map((v) => v.name).join(",") }};
              </div>
              <div
                class="inline-item"
                v-if="
                  !$store.state.roles_disabled.includes('yewugendan') &&
                  item['yewugendan'] &&
                  item['yewugendan'].length > 0
                "
              >
                业务跟单: {{ item["yewugendan"].map((v) => v.name).join(",") }};
              </div>
              <div
                class="inline-item"
                v-if="
                  !$store.state.roles_disabled.includes('gendan') &&
                  item['gendan'] &&
                  item['gendan']['name']
                "
              >
                跟单: {{ item["gendan"]["name"] }};
              </div>
              <div
                class="inline-item"
                v-if="
                  !$store.state.roles_disabled.includes('fuzhugendan') &&
                  item['fuzhugendan'] &&
                  item['fuzhugendan']['name']
                "
              >
                辅助跟单: {{ item["fuzhugendan"]["name"] }}
              </div>
            </div>
            <div>
              <div class="inline-item">总金额 ${{ item.amount }}</div>
              <div
                v-if="item.confirm_status < 2"
                class="inline-item"
                :class="
                  ['not-confirmed', 'confirmed', 'deposit-paid'][
                    item.confirm_status
                  ]
                "
              >
                {{ ["订单未确认", "订单已确认未付定金"][item.confirm_status]
                }}<span v-if="!item.confirm_status && !item.need_deposit"
                  >(无需定金)</span
                >
              </div>
              <div class="inline-item" v-if="item.confirm_status === 2">
                已出货 ${{ Math.round(item.ship_amount * 100) / 100 }}
              </div>
              <div
                class="inline-item"
                v-if="
                  order_category === 'notallshipped' &&
                  item.confirm_status === 2
                "
              >
                未出货 ${{
                  Math.round((item.amount - item.ship_amount) * 100) / 100
                }}
              </div>
              <div
                class="inline-item"
                v-if="
                  (item.receipt_amount || order_category !== 'notallshipped') &&
                  item.confirm_status === 2
                "
              >
                已收款 ${{ Math.round(item.receipt_amount * 100) / 100 }}
              </div>
            </div>
          </template>
        </ListItem>
        <div class="summary" v-if="orders.length > 0">
          <div>
            订单总金额: ${{
              Math.round(
                orders.reduce((total, cv) => {
                  return total + cv.amount;
                }, 0)
              )
            }}
          </div>
          <div>
            已出总金额: ${{
              Math.round(
                orders.reduce((total, cv) => {
                  return total + cv.ship_amount;
                }, 0)
              )
            }}
          </div>
          <div>
            未出总金额: ${{
              Math.round(
                orders.reduce((total, cv) => {
                  return total + cv.amount - cv.ship_amount;
                }, 0)
              )
            }}
          </div>
        </div>
        <!-- 订单 -->
      </div>
      <div>
        <!-- 出货 -->
        <div class="filter-area">
          <SearchBar v-model="shipment_keyword"></SearchBar>
          <div
            class="filter-button"
            :class="{ active: ship_category === 'notallpaid' }"
            @click="() => (ship_category = 'notallpaid')"
          >
            未结清
          </div>
          <div
            class="filter-button"
            :class="{ active: ship_category === 'allpaid' }"
            @click="() => (ship_category = 'allpaid')"
          >
            已结清
          </div>
        </div>
        <ListItem
          v-for="(item, key) in shipments"
          :key="key"
          :index="key + 1"
          @click.native="() => showmenu(item)"
          v-show="
            !shipment_keyword ||
            item.shipment_title.includes(shipment_keyword) ||
            item.customer_name.includes(shipment_keyword)
          "
        >
          <template v-slot:title>出货编号: {{ item.shipment_title }}</template>
          <template v-slot:extra><div>.</div> </template>
          <template v-slot:brief>
            <div>
              <div class="inline-item">操作者: {{ item.creator_name }}</div>
              <div class="inline-item">
                日期: {{ new Date(item.effective_time).format("yyyy-M-d") }}
              </div>
              <div class="inline-item">客户: {{ item["customer_name"] }}</div>
            </div>
            <div>
              <div class="inline-item">
                出货金额: {{ Math.round(item.amount * 100) / 100 }}
              </div>
              <div class="inline-item">
                已收款: {{ Math.round(item.receipt_amount * 100) / 100 }}
              </div>
              <div class="inline-item">
                未收款:
                {{
                  Math.round((item.amount - item.receipt_amount) * 100) / 100
                }}
              </div>
            </div>
            <div v-if="$store.state.enable_logistics">
              <div class="inline-item">物流公司: {{ item.logistics_corp }}</div>
              <div class="inline-item">单号: {{ item.logistics_no }}</div>
            </div>
          </template>
        </ListItem>
        <!-- 出货 -->
      </div>
      <div>
        <!-- 收款 -->
        <SearchBar v-model="receipt_keyword"></SearchBar>
        <ListItem
          v-for="(item, key) in receipts"
          :key="key"
          :index="key + 1"
          @click.native="() => {}"
          v-show="
            !receipt_keyword || item.customer_name.includes(receipt_keyword)
          "
        >
          <template v-slot:title>{{ item.receipt_no }}</template>
          <template v-slot:extra><div>.</div> </template>
          <template v-slot:brief>
            <div>
              <div class="inline-item">操作者: {{ item.creator_name }}</div>
              <div class="inline-item">
                日期: {{ new Date(item.effective_time).format("yyyy-M-d") }}
              </div>
              <div class="inline-item">客户: {{ item["customer_name"] }}</div>
            </div>
            <div>
              <div class="inline-item">收款金额: {{ item.amount }}</div>
            </div>
          </template>
        </ListItem>
        <!-- 收款 -->
      </div>
    </Tabs>
    <div class="modal" v-show="modalshow > 0">
      <div class="modal-mask"></div>
      <div class="modal-1" v-show="modalshow === 1">
        <div class="order-no" v-if="activeitem">
          {{ ["合同", "出货编号"][currentTab] }}
          {{ [activeitem.order_no, activeitem.shipment_title][currentTab] }}
        </div>
        <div
          class="button-wrapper"
          v-for="(button, key) in buttons"
          :key="key"
          v-show="showbutton(activeitem, button)"
        >
          <Button
            :type="button.type"
            @click="() => clickbuttoncallback(activeitem, button)"
            >{{ button.text }}</Button
          >
        </div>
        <!-- modal-1 结束 -->
      </div>
      <div class="modal-2" v-if="modalshow === 2">
        <div class="content">
          <EditOrder
            :order_id="activeitem.id"
            v-if="activeitem && operation === 'editOrder'"
          ></EditOrder>
          <div
            class="deposit-order"
            v-if="activeitem && operation === 'depositOrder'"
          >
            <!-- 对订单进行预收款 -->
            <div class="form-little-title">
              {{ activeitem.order_no }} 的预收款
            </div>
            <div class="ship-data">
              总金额: {{ activeitem.amount }} {{ activeitem.currency }}；
              已收款金额:
              {{ activeitem.receipt_amount }}
              {{ activeitem.currency }}
            </div>
            <InputItem
              labelname="预收金额"
              v-model="deposit_amount"
              type="number"
            ></InputItem>
            <DatetimeItem
              labelname="收款日期"
              title="收款日期"
              v-model="deposit_time"
            ></DatetimeItem>
            <InputItem
              labelname="收款备注"
              v-model="deposit_remark"
            ></InputItem>
            <div class="two-buttons-group">
              <Button @click="closeedit">取消</Button>
              <Button type="primary" @click="() => depositsave()">预收</Button>
            </div>
            <div
              class="description"
              :style="{ 'font-size': '0.8em', padding: '5px' }"
            >
              说明：订单还没出货，收到定金；后续出货之后收款需要将预收款抵扣
            </div>
            <div class="form-little-title">收款历史</div>
            <div
              v-for="item in order_receipts"
              :key="item.id"
              class="ship-item"
            >
              <div>
                {{ new Date(item.effective_time).format("yyyy-MM-dd") }}
              </div>
              <div>收 {{ item.amount }} {{ item.currency }}</div>
              <div>操作者: {{ item.creator_name }}</div>
            </div>
            <!-- 对订单进行预收款 -->
          </div>
          <div
            class="ship-order"
            v-if="activeitem && operation === 'shipOrder'"
          >
            <!-- 对订单进行出货 -->
            <div class="form-little-title">
              订单{{ activeitem.order_no }}出货{{ activeitem.currency }}
            </div>
            <div class="ship-data">
              总金额: {{ activeitem.amount }}； 已出:
              {{ activeitem.ship_amount }}； 待出:
              {{ activeitem.amount - activeitem.ship_amount }}
            </div>
            <InputItem
              labelname="出货金额"
              v-model="ship_amount"
              type="number"
            ></InputItem>
            <DatetimeItem
              labelname="出货日期"
              title="出货日期"
              v-model="ship_time"
            ></DatetimeItem>
            <InputItem
              labelname="出货编号"
              v-model="shipment_title"
            ></InputItem>
            <InputItem
              labelname="物流公司"
              v-model="logistics_corp"
              v-if="$store.state.enable_logistics"
            ></InputItem>
            <InputItem
              labelname="物流单号"
              v-model="logistics_no"
              v-if="$store.state.enable_logistics"
            ></InputItem>
            <InputItem labelname="出货备注" v-model="ship_remark"></InputItem>
            <div
              class="warning"
              v-if="
                ship_amount * 1 + activeitem.ship_amount > activeitem.amount
              "
            >
              请注意本次出货 {{ ship_amount }} 加已出货
              {{ activeitem.ship_amount }} 将超出订单总金额
              {{ activeitem.amount }}
            </div>
            <div class="two-buttons-group">
              <Button @click="() => shipsave(false)">出货(部分)</Button>
              <Button type="primary" @click="() => shipsave(true)"
                >出货(完成)</Button
              >
            </div>
            <div class="form-little-title">出货历史</div>
            <div
              v-for="item in order_shipments"
              :key="item.id"
              class="ship-item"
            >
              <div>
                {{ new Date(item.effective_time).format("yyyy-MM-dd") }}
              </div>
              <div>出 {{ item.ship_amount }} {{ item.currency }}</div>
              <div>操作者: {{ item.creator_name }}</div>
            </div>
            <!-- 对订单进行出货 -->
          </div>
          <div
            class="receipt-shipment"
            v-if="activeitem && operation === 'receiptShipment'"
          >
            <!-- 对出货单进行收款 -->
            <div class="form-little-title">
              {{ activeitem.shipment_title }} 的收款
            </div>
            <div class="ship-data">
              总金额: {{ activeitem.amount }} {{ activeitem.currency }}；
              已收款金额:
              {{ activeitem.receipt_amount }}
              {{ activeitem.currency }}
            </div>
            <div v-if="customer_deposits.length > 0" class="receipt-methods">
              <div
                class="receipt-method-button"
                :class="{ active: receipt_method === 'deduction' }"
                @click="() => (receipt_method = 'deduction')"
              >
                抵扣预收款
              </div>
              <div
                class="receipt-method-button"
                :class="{ active: receipt_method === 'receipt' }"
                @click="() => (receipt_method = 'receipt')"
              >
                收款
              </div>
            </div>
            <div v-show="receipt_method === 'deduction'">
              <SelectItem
                labelname="预收款单"
                :selectlist="customer_deposits"
                v-model="customer_deposit"
              ></SelectItem>
              <div
                class="deposit-text"
                v-if="deposit_text"
                :class="deposit_text.class"
              >
                {{ deposit_text.content }}
              </div>
            </div>
            <InputItem
              :labelname="`${
                receipt_method === 'receipt' ? '收款' : '抵扣'
              }金额`"
              v-model="receipt_amount"
              type="number"
            ></InputItem>
            <DatetimeItem
              labelname="收款日期"
              title="收款日期"
              v-model="receipt_time"
              v-show="receipt_method === 'receipt'"
            ></DatetimeItem>
            <InputItem labelname="备注" v-model="receipt_remark"></InputItem>
            <div class="two-buttons-group">
              <Button
                type="primary"
                class="small-text"
                @click="() => receiptsave(false)"
                >完成该出货部分收款</Button
              >
              <Button
                type="primary"
                class="small-text"
                @click="() => receiptsave(true)"
                >完成该出货全部收款</Button
              >
            </div>
            <div class="form-little-title">收款历史</div>
            <div
              v-for="item in shipment_receipts"
              :key="item.id"
              class="ship-item"
            >
              <div>
                {{ new Date(item.effective_time).format("yyyy-MM-dd") }}
              </div>
              <div>收 {{ item.amount }} {{ item.currency }}</div>
              <div>操作者: {{ item.creator_name }}</div>
            </div>
            <!-- 对出货单进行收款 -->
          </div>
          <EditShipment
            :shipment_id="activeitem.id"
            v-if="activeitem && operation === 'editShipment'"
          ></EditShipment>
        </div>
        <div class="exit-edit" @click="closeedit">
          <svg-icon iconName="close"></svg-icon>
        </div>
      </div>
    </div>
    <Dialog v-if="dialogshow" :dialogprops="dialogprops"></Dialog>
    <FooterBar></FooterBar>
    <Add></Add>
  </div>
</template>

<script>
// @ is an alias to /src
import Add from "@/components/Add.vue";
import FooterBar from "@/components/FooterBar.vue";
import Tabs from "@/components/Tabs.vue";
import ListItem from "@/components/ListItem.vue";
import Button from "@/components/Button.vue";
import SearchBar from "@/components/SearchBar.vue";
import EditOrder from "@/components/EditOrder.vue";
import EditShipment from "@/components/EditShipment.vue";
import InputItem from "@/components/InputItem.vue";
import DatetimeItem from "@/components/DatetimeItem.vue";
import Dialog from "@/components/Dialog.vue";
import SelectItem from "@/components/SelectItem.vue";
import {
  getorderlist,
  getshipmentlist,
  getreceiptlist,
  depositsave,
  shipsave,
  getordershipments,
  getshipmentreceipts,
  getorderreceipts,
  receiptsave,
  updateorder,
  getcustomerdeposits,
  getsingleshipment,
} from "@/api/order";
export default {
  name: "Sales",
  components: {
    FooterBar,
    Add,
    Tabs,
    ListItem,
    Button,
    SearchBar,
    EditOrder,
    EditShipment,
    InputItem,
    DatetimeItem,
    Dialog,
    SelectItem,
  },
  data: function () {
    return {
      tabs: [
        { key: "orders", title: "订单" },
        { key: "shipments", title: "出货" },
        { key: "receipts", title: "收款" },
      ],
      order_keyword: null,
      shipment_keyword: null,
      receipt_keyword: null,
      orders: [],
      order_category: "notallshipped",
      orders_obj: {}, // 保存各个状态的订单列表
      shipments: [],
      shipments_obj: {}, // 保存各个状态的出货单列表
      ship_category: "notallpaid",
      receipts: [],
      sizeobj: { barheight: "45px", contenttop: "46px" },
      currentTab: 0,
      modalshow: 0,
      activeitem: null,
      buttons: [
        {
          action: "editOrder",
          text: "修改订单基本信息",
          type: "",
          allow_tabs: [0],
          allow_categories: [],
          allow_confirm_statuses: [],
        },
        {
          action: "confirmOrder",
          text: "确认订单",
          type: "primary",
          allow_tabs: [0],
          allow_categories: ["notallshipped"],
          allow_confirm_statuses: [0],
        },
        {
          action: "orderDeposited",
          text: "已收定金",
          type: "primary",
          allow_tabs: [0],
          allow_categories: ["notallshipped"],
          allow_confirm_statuses: [0, 1],
        },
        {
          action: "depositOrder",
          text: "预收款",
          type: "primary",
          allow_tabs: [0],
          allow_categories: ["notallshipped"],
        },
        {
          action: "shipOrder",
          text: "出货",
          type: "primary",
          allow_tabs: [0],
          allow_categories: ["notallshipped"],
          allow_confirm_statuses: [2],
        },
        {
          action: "undoShipOrder",
          text: "撤销出货完成",
          type: "warning",
          allow_tabs: [0],
          allow_categories: ["allshipped"],
        },
        {
          action: "editShipment",
          text: "修改出货详情",
          type: "",
          allow_tabs: [1],
          allow_categories: [],
        },
        {
          action: "receiptShipment",
          text: "收款",
          type: "primary",
          allow_tabs: [1],
          allow_categories: ["notallpaid"],
        },
        {
          action: "undoReceiptShipment",
          text: "撤销收款完成",
          type: "warning",
          allow_tabs: [1],
          allow_categories: ["allpaid"],
        },
        {
          action: "canceloperation",
          text: "返回",
          type: "",
          allow_tabs: [],
          allow_categories: [],
        },
      ],
      operation: null,
      shiment_title: null,
      deposit_amount: null,
      deposit_time: new Date(),
      deposit_remark: null,
      order_receipts: [], // 订单收款记录
      confirm_status: null,
      order_confirm_status_cnts: [0, 0, 0], // 不同状态的订单数量
      ship_amount: null,
      ship_time: new Date(),
      ship_remark: null,
      logistics_no: null,
      logistics_corp: null,
      order_shipments: [], // 订单的出货记录
      receipt_amount: null,
      receipt_time: new Date(),
      receipt_remark: null,
      shipment_receipts: [], // 出货的收款记录
      customer_deposits: [],
      customer_deposit: null,
      receipt_method: "receipt", // 出货收款方式：receipt: "收款"，deduction:"抵扣预收款"
      super_user: false,
      dialogshow: false,
      dialogprops: {
        title: "",
        desc: "",
        cancel: () => {},
        confirm: () => {},
      },
    };
  },
  computed: {
    deposit_text() {
      if (this.receipt_method === "deduction") {
        if (this.customer_deposit) {
          if (this.customer_deposit.amount >= this.receipt_amount) {
            return {
              content: `预收款金额 ${this.customer_deposit.amount} ${this.customer_deposit.currency}`,
              class: "ok",
            };
          } else {
            return {
              content: `预收款金额 ${this.customer_deposit.amount} ${this.customer_deposit.currency}，不足以抵扣`,
              class: "warning",
            };
          }
        }
      }
      return null;
    },
  },
  watch: {
    currentTab: function (v) {
      this.activeitem = null;
      if (v === 0) {
        this.updateorderlist();
        document.title = {
          notallshipped: "订单-货未出完",
          allshipped: "订单-货已出完",
          allpaid: "订单-款已结清",
        }[this.order_category];
      } else if (v === 1) {
        this.updateshipmentlist();
        document.title = {
          notallpaid: "出货-货款未结清",
          allpaid: "出货-货款已结清",
        }[this.ship_category];
      } else if (v === 2) {
        this.updatereceiptlist();
      }
    },
    order_category: function () {
      this.updateorderlist();
      let category = {
        notallshipped: "货未出完",
        allshipped: "货已出完",
        allpaid: "款已结清",
        deleted: "已删除",
      }[this.order_category];
      document.title = "订单-" + category;
    },
    ship_category: function (v) {
      this.updateshipmentlist();
      document.title = {
        notallpaid: "出货-款未付清",
        allpaid: "出货-款已付清",
      }[v];
    },
    confirm_status: function () {
      this.updateorderlist();
    },
  },
  methods: {
    confirm_status_clicked(v) {
      let confirm_status = this.confirm_status;
      if (confirm_status === null) {
        this.confirm_status = v;
      } else if (confirm_status !== v) {
        this.confirm_status = v;
      } else if (confirm_status === v) {
        this.confirm_status = null;
      }
    },
    updateorderlist() {
      let order_category = this.order_category;
      this.orders = this.orders_obj[order_category]
        ? this.orders_obj[order_category]
        : [];
      let confirm_status = this.confirm_status;
      let order_confirm_status_cnts = [0, 0, 0];
      getorderlist(order_category)
        .then((res) => {
          let orders = res.content.map((v) => {
            for (let i = 0; i < v.participants.length; i++) {
              let role = v.participants[i]["role"];
              if (role === "fuzhujiedan" || role === "yewugendan") {
                if (v[role]) {
                  v[role].push(v.participants[i]);
                } else {
                  v[role] = [v.participants[i]];
                }
              } else {
                v[role] = v.participants[i];
              }
            }
            if (!("fuzhujiedan" in v)) {
              v["fuzhujiedan"] = [];
            }
            if (!("yewugendan" in v)) {
              v["yewugendan"] = [];
            }
            order_confirm_status_cnts[v.confirm_status] =
              order_confirm_status_cnts[v.confirm_status] + 1;
            return v;
          });
          if (order_category === "notallshipped" && confirm_status !== null) {
            orders = orders.filter((v) => v.confirm_status === confirm_status);
          }
          this.order_confirm_status_cnts = order_confirm_status_cnts;
          this.orders_obj[order_category] = orders;
          this.orders = orders;
        })
        .finally(() => {
          this.$store.commit("hide_loading");
        });
    },
    updateshipmentlist() {
      let ship_category = this.ship_category;
      this.shipments = this.shipments_obj[ship_category]
        ? this.shipments_obj[ship_category]
        : [];
      this.$store.commit("show_loading", "正在加载中...");
      getshipmentlist(ship_category)
        .then((res) => {
          let shipments = res.content.map((v) => {
            return v;
          });
          this.shipments_obj[ship_category] = shipments;
          this.shipments = shipments;
        })
        .finally(() => {
          this.$store.commit("hide_loading");
        });
    },
    updatereceiptlist() {
      this.$store.commit("show_loading", "正在加载中...");
      getreceiptlist()
        .then((res) => {
          let receipts = res.content.map((v) => {
            return v;
          });
          this.receipts = receipts;
        })
        .finally(() => {
          this.$store.commit("hide_loading");
        });
    },
    showmenu(item) {
      this.activeitem = item;
      this.customer_deposits = []; // 这个清空一下
      this.receipt_method = "receipt";
      this.modalshow = 1;
      let that = this;
      if ("shipment_title" in item) {
        getsingleshipment(item.id).then((res) => {
          that.activeitem = res.content;
        });
      }
    },
    showbutton(activeitem, button) {
      let action = button.action;
      let my_user_id = this.$store.state.user_id;
      let super_user = this.super_user;
      if (!activeitem) {
        return false;
      }
      let allow_tabs = button.allow_tabs;
      let currentTab = this.currentTab;
      if (allow_tabs.length > 0 && !allow_tabs.includes(currentTab)) {
        return false;
      }
      let allow_categories = button.allow_categories;
      if (allow_categories.length > 0) {
        let category;
        if (currentTab === 0) {
          category = this.order_category;
        } else if (currentTab === 1) {
          category = this.ship_category;
        }
        if (!allow_categories.includes(category)) {
          return false;
        }
      }
      let allow_confirm_statuses = button.allow_confirm_statuses;
      if (allow_confirm_statuses && allow_confirm_statuses.length > 0) {
        if (!allow_confirm_statuses.includes(activeitem.confirm_status)) {
          return false;
        }
      }
      let need_deposit = activeitem.need_deposit;
      if (!need_deposit && button.action === "orderDeposited") {
        return false;
      }
      if (["confirmOrder", "orderDeposited", "depositOrder"].includes(action)) {
        // 确认订单，预收款 这些操作只能是由接单员、辅助接单员和业务跟单操作
        let yewu_ids = [activeitem.jiedan.id];
        if (activeitem.fuzhujiedan) {
          yewu_ids = yewu_ids.concat(activeitem.fuzhujiedan.map((v) => v.id));
        }
        if (activeitem.yewugendan) {
          yewu_ids = yewu_ids.concat(activeitem.yewugendan.map((v) => v.id));
        }
        if (!yewu_ids.includes(my_user_id) && !super_user) {
          return false;
        }
      }
      if (["receiptShipment", "undoReceiptShipment"].includes(action)) {
        // 这些操作只能是由接单员、辅助接单员和业务跟单操作
        /*
        if (action === "receiptShipment") {
          console.log(
            !activeitem.jiedan_ids ||
              (!activeitem.jiedan_ids.includes(my_user_id) &&
                !activeitem.fuzhujiedan_ids.includes(my_user_id) &&
                !activeitem.yewugendan_ids.includes(my_user_id))
          );
        }
        */
        if (
          (!activeitem.jiedan_ids ||
            (!activeitem.jiedan_ids.includes(my_user_id) &&
              !activeitem.fuzhujiedan_ids.includes(my_user_id) &&
              !activeitem.yewugendan_ids.includes(my_user_id))) &&
          !super_user
        ) {
          return false;
        }
      }
      if (["shipOrder", "undoShipOrder"].includes(action)) {
        // 这些操作只能是由跟单员和辅助跟单员操作
        let ids = [
          activeitem.jiedan.id,
          activeitem.gendan.id,
          activeitem.fuzhugendan.id,
        ];
        if (activeitem.fuzhujiedan) {
          ids = ids.concat(activeitem.fuzhujiedan.map((v) => v.id));
        }
        if (activeitem.yewugendan) {
          ids = ids.concat(activeitem.yewugendan.map((v) => v.id));
        }
        if (!ids.includes(my_user_id) && !super_user) {
          return false;
        }
      }
      return true;
    },
    closeedit() {
      this.modalshow = 0;
      this.operation = null;
      this.dialogshow = false;
      this.activeitem = null;
      if (this.currentTab === 0) {
        this.updateorderlist();
      } else if (this.currentTab === 1) {
        this.updateshipmentlist();
      } else if (this.currentTab === 2) {
        this.updatereceiptlist();
      }
    },
    canceloperation() {
      this.activeitem = null;
      this.modalshow = 0;
    },
    clickbuttoncallback(activeitem, button) {
      let action = button.action;
      if (action === "editOrder") {
        this.modalshow = 2;
        this.operation = "editOrder";
      } else if (action === "confirmOrder") {
        let order_id = activeitem.id;
        let that = this;
        let field = "confirm_status";
        let value = activeitem.need_deposit ? 1 : 2;
        updateorder({ field, value, order_id })
          .then(() => {
            that.$store.commit("add_notification", {
              msg: "操作成功",
              status: "success",
            });
            this.closeedit();
          })
          .catch((e) => {
            that.$store.commit("add_notification", {
              msg: "操作失败：" + e,
            });
          });
      } else if (action === "depositOrder") {
        this.modalshow = 2;
        this.operation = "depositOrder";
        getorderreceipts(activeitem.id).then((res) => {
          this.order_receipts = res.content;
        });
      } else if (action === "orderDeposited") {
        let order_id = activeitem.id;
        let that = this;
        let field = "confirm_status";
        let value = 2;
        updateorder({ field, value, order_id })
          .then(() => {
            that.$store.commit("add_notification", {
              msg: "操作成功",
              status: "success",
            });
            this.closeedit();
          })
          .catch((e) => {
            that.$store.commit("add_notification", {
              msg: "操作失败：" + e,
            });
          });
      } else if (action === "shipOrder") {
        this.modalshow = 2;
        this.operation = "shipOrder";
        this.shipment_title = activeitem.order_no;
        let that = this;
        getordershipments(activeitem.id).then((res) => {
          that.order_shipments = res.content;
          if (res.content.length > 0) {
            that.shipment_title =
              activeitem.order_no +
              String.fromCharCode(res.content.length + 65);
          }
        });
      } else if (action === "undoShipOrder") {
        let order_id = activeitem.id;
        let that = this;
        let field = "allshipped";
        let value = false;
        updateorder({ field, value, order_id })
          .then(() => {
            that.$store.commit("add_notification", {
              msg: "撤销成功",
              status: "success",
            });
            this.closeedit();
          })
          .catch((e) => {
            that.$store.commit("add_notification", {
              msg: "撤销失败：" + e,
            });
          });
      } else if (action === "receiptOrder") {
        this.modalshow = 2;
        this.operation = "receiptOrder";
        getorderreceipts(activeitem.id).then((res) => {
          this.order_receipts = res.content;
        });
      } else if (action === "editShipment") {
        this.modalshow = 2;
        this.operation = "editShipment";
      } else if (action === "receiptShipment") {
        this.modalshow = 2;
        this.operation = "receiptShipment";
        getshipmentreceipts(activeitem.id).then((res) => {
          this.shipment_receipts = res.content;
        });
        getcustomerdeposits(activeitem.customer_id).then((res) => {
          let customer_deposits = res.content.map((v) => {
            v.value = v.id;
            v.label = v.receipt_no;
            return v;
          });
          this.customer_deposits = customer_deposits;
          if (customer_deposits.length >= 1) {
            this.customer_deposit = customer_deposits[0];
            this.receipt_method = "deduction";
          }
        });
      } else if (action === "closeOrder") {
        this.closeorder();
      } else if (action === "rebootOrder") {
        this.rebootorder();
      } else if (action === "canceloperation") {
        this.canceloperation();
      }
    },
    depositsave() {
      let order_id = this.activeitem.id;
      let deposit_amount = parseFloat(this.deposit_amount);
      if (!deposit_amount) {
        deposit_amount = 0;
      }
      let deposit_time = this.deposit_time;
      let deposit_remark = this.deposit_remark;
      let leiji_receipt_amount =
        deposit_amount + this.activeitem.receipt_amount;
      let that = this;
      let f = () => {
        that.$store.commit("show_loading", "正在提交数据，请稍等......");
        depositsave({
          order_id,
          deposit_amount,
          deposit_time,
          deposit_remark,
        })
          .then((res) => {
            res;
            that.dialogshow = false;
            that.closeedit();
            that.deposit_amount = null;
            that.deposit_remark = null;
            that.$store.commit("hide_loading");
            that.$store.commit("add_notification", {
              msg: "提交成功",
              status: "success",
            });
          })
          .catch((e) => {
            that.$store.commit("hide_loading");
            that.$store.commit("add_notification", {
              msg: "提交失败+" + e,
            });
          });
      };
      if (leiji_receipt_amount > this.activeitem.amount) {
        this.dialogprops = {
          title: "",
          desc:
            "此次收款" +
            deposit_amount +
            "，累计收款金额将为 " +
            leiji_receipt_amount +
            "，超过了订单总金额 " +
            this.activeitem.amount +
            "，请再次确认！",
          confirmtext: "确认提交",
          cancel: () => {
            that.dialogshow = false;
          },
          confirm: f,
        };
        this.dialogshow = true;
        return;
      } else if (!deposit_amount) {
        this.$store.commit("add_notification", {
          msg: "请输入预收金额",
        });
        return;
      }
      f();
    },
    shipsave(all) {
      let order_id = this.activeitem.id;
      let ship_amount = parseFloat(this.ship_amount);
      if (!ship_amount) {
        ship_amount = 0;
      }
      let ship_time = this.ship_time;
      let ship_remark = this.ship_remark;
      let logistics_no = this.logistics_no;
      let logistics_corp = this.logistics_corp;
      let shipment_title = this.shipment_title;
      let leiji_ship_amount = ship_amount + this.activeitem.ship_amount;
      let that = this;
      let f = () => {
        that.$store.commit("show_loading", "正在提交数据，请稍等......");
        shipsave({
          order_id,
          ship_amount,
          ship_time,
          ship_remark,
          shipment_title,
          logistics_corp,
          logistics_no,
          all,
        })
          .then((res) => {
            res;
            that.dialogshow = false;
            that.closeedit();
            that.ship_amount = null;
            that.ship_remark = null;
            that.shipment_title = null;
            that.$store.commit("hide_loading");
            that.$store.commit("add_notification", {
              msg: "提交成功",
              status: "success",
            });
          })
          .catch((e) => {
            that.$store.commit("hide_loading");
            that.$store.commit("add_notification", {
              msg: "提交失败+" + e,
            });
          });
      };
      if (all && leiji_ship_amount !== this.activeitem.amount) {
        this.dialogprops = {
          title: "将完成'" + this.activeitem.order_no + "'所有出货",
          desc:
            "此次出货" +
            ship_amount +
            "，累计出货金额将为 " +
            leiji_ship_amount +
            "，与订单总金额 " +
            this.activeitem.amount +
            " 不一致，请再次确认！",
          confirmtext: "确认完成出货",
          cancel: () => {
            that.dialogshow = false;
          },
          confirm: f,
        };
        this.dialogshow = true;
        return;
      } else if (!ship_amount && !all) {
        this.$store.commit("add_notification", {
          msg: "请输入出货金额",
        });
        return;
      }
      f();
    },
    receiptsave(all) {
      let shipment_id = this.activeitem.id;
      let receipt_amount = parseFloat(this.receipt_amount);
      if (!receipt_amount) {
        receipt_amount = 0;
      }
      let receipt_time = this.receipt_time;
      let receipt_remark = this.receipt_remark;
      let leiji_receipt_amount =
        receipt_amount + this.activeitem.receipt_amount;
      let that = this;
      let deduction = false;
      let deposit_id = null;
      if (this.receipt_method === "receipt") {
        deduction = false;
      }
      if (this.receipt_method === "deduction" && this.customer_deposit) {
        deduction = true;
        deposit_id = this.customer_deposit.value;
      }
      let f = () => {
        that.$store.commit("show_loading", "正在提交数据，请稍等......");
        receiptsave({
          shipment_id,
          receipt_amount,
          receipt_time,
          receipt_remark,
          all,
          deduction,
          deposit_id,
        })
          .then((res) => {
            res;
            that.dialogshow = false;
            that.closeedit();
            that.receipt_amount = null;
            that.receipt_remark = null;
            that.$store.commit("hide_loading");
            that.$store.commit("add_notification", {
              msg: "提交成功",
              status: "success",
            });
          })
          .catch((e) => {
            that.$store.commit("hide_loading");
            that.$store.commit("add_notification", {
              msg: "提交失败+" + e,
            });
          });
      };
      if (all && leiji_receipt_amount !== this.activeitem.amount) {
        this.dialogprops = {
          title: this.activeitem.shipment_title + "将完成所有收款",
          desc:
            "此次收款 " +
            receipt_amount +
            "，累计收款金额将为 " +
            leiji_receipt_amount +
            "，与总出货金额 " +
            this.activeitem.amount +
            " 不一致，请再次确认！",
          confirmtext: "确认完成收款",
          cancel: () => {
            that.dialogshow = false;
          },
          confirm: f,
        };
        this.dialogshow = true;
        return;
      } else if (!all && !receipt_amount) {
        this.$store.commit("add_notification", {
          msg: "请填写收款金额",
        });
        return;
      }
      f();
    },
    closeorder() {
      this.$store.commit("show_loading", "正在提交数据，请稍等......");
      let order_id = this.activeitem.id;
      let field = "status";
      let value = "done";
      updateorder({ field, value, order_id })
        .then(() => {
          this.$store.commit("hide_loading");
          this.$store.commit("add_notification", {
            msg: "提交成功",
            status: "success",
          });
          this.closeedit();
        })
        .catch((e) => {
          this.$store.commit("hide_loading");
          this.$store.commit("add_notification", {
            msg: "提交失败：" + e,
          });
        });
    },
    rebootorder() {
      this.$store.commit("show_loading", "正在提交数据，请稍等......");
      let order_id = this.activeitem.id;
      let field = "status";
      let value = "ongoing";
      updateorder({ field, value, order_id })
        .then(() => {
          this.$store.commit("hide_loading");
          this.$store.commit("add_notification", {
            msg: "提交成功",
            status: "success",
          });
          this.closeedit();
        })
        .catch((e) => {
          this.$store.commit("hide_loading");
          this.$store.commit("add_notification", {
            msg: "提交失败：" + e,
          });
        });
    },
  },
  mounted: function () {
    this.updateorderlist();
    document.title = {
      notallshipped: "订单-货未出完",
      allshipped: "订单-货已出完但款未付清",
      allpaid: "订单-款已付清",
    }[this.order_category];
    this.super_user = this.$store.state.super_user;
  },
};
</script>

<style lang="less">
.sales {
  .inline-item {
    display: inline-block;
    margin-right: 10px;
  }
  .filter-area2 {
    display: flex;
    align-items: center;
    .filter-button {
      display: flex;
      align-items: center;
      min-height: 30px;
      padding: 0 8px;
      flex-shrink: 0;
      background: #eee;
      margin: 1px 2px;
      border-radius: 5px;
      font-size: 0.7em;
      &.active {
        background: #cce5ff;
      }
    }
  }
  .summary {
    text-align: center;
    font-size: 0.85em;
    color: #333;
    padding: 10px 0;
  }
  .not-confirmed,
  .confirmed {
    color: red;
  }
}
.modal-1 {
  .order-no {
    text-align: center;
    font-size: 1.1em;
  }
}
.modal-2 {
  max-height: 95vh;
  .content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  .exit-edit {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    color: #fff;
    border-bottom: 1px solid #aaa;
    border-left: 1px solid #aaa;
    background: #aaa;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    .svg-icon {
      width: 100%;
      height: 100%;
      padding: 2px;
    }
    &:active {
      background-color: #d8d8d8;
    }
  }
  .deposit-order,
  .ship-order,
  .receipt-shipment {
    padding: 5px 6px 8px;
    .ship-data {
      padding-left: 6px;
      text-align: right;
      font-size: 0.9em;
      color: #555;
    }
    .ship-item {
      padding-left: 10px;
      display: flex;
      & > div {
        margin-right: 10px;
      }
    }
    .warning {
      color: red;
      margin-left: 5px;
      font-weight: bold;
    }
  }
  .receipt-shipment {
    .deposit-text {
      margin-left: 5px;
      font-size: 0.85em;
      margin-bottom: 10px;
    }
    .receipt-methods {
      display: flex;
      margin: 0 2px;
      .receipt-method-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 8px;
        background: #eee;
        margin: 0 2px;
        border-radius: 5px;
        font-size: 0.8em;
        &.active {
          background: #cce5ff;
        }
      }
    }
  }
}
.single-button {
  width: 60%;
  margin: 20px auto;
}
</style>