<template>
  <div class="edit-shipment">
    <div class="form-little-title">修改出货信息</div>
    <div class="order-item">
      <InputItem
        labelname="出货编号"
        v-model="shipment_title"
        :disabled="
          !icons.shipment_title || icons.shipment_title.icon === 'edit'
        "
      ></InputItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.shipment_title ? icons.shipment_title.icon : 'edit'"
          @click.native="() => clickicon('shipment_title')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-item">
      <DatetimeItem
        labelname="出货日期"
        title="出货日期"
        v-model="effective_time"
        :disabled="
          !icons.effective_time || icons.effective_time.icon === 'edit'
        "
      ></DatetimeItem>
      <div class="edit-icon">
        <svg-icon
          :iconName="icons.effective_time ? icons.effective_time.icon : 'edit'"
          @click.native="() => clickicon('effective_time')"
        ></svg-icon>
      </div>
    </div>
    <div class="order-shipment-item">合同编号以及金额</div>
    <div
      class="order-shipment-item"
      v-for="(item, i) in order_shipments"
      :key="item.id"
    >
      <div class="index">{{ i + 1 }}.</div>
      <div class="content">
        <div class="order_no">{{ item.order_no }}</div>
        <div class="amount">
          <span>出货 $</span><span v-if="!item.editing">{{ item.amount }}</span>
          <input
            v-if="item.editing"
            v-model.number="item.amount_m"
            type="number"
          />
        </div>
        <div class="icon">
          <svg-icon
            iconName="save"
            @click.native="() => saveordershipmentamount(i)"
            class="edit"
            v-if="item.editing"
          ></svg-icon>
          <svg-icon
            iconName="edit"
            @click.native="() => clickordershipmentediticon(i)"
            class="edit"
            v-else
          ></svg-icon>
          <!--
        <svg-icon
          iconName="delete"
          @click.native="() => clickordershipmentediticon(i)"
          class="delete"
        ></svg-icon>
        --></div>
      </div>
    </div>
  </div>
</template>
<script>
import InputItem from "@/components/InputItem.vue";
import DatetimeItem from "@/components/DatetimeItem.vue";
import {
  getsingleshipment,
  updateshipment,
  updateordershipmentamount,
} from "@/api/order";

export default {
  components: { InputItem, DatetimeItem },
  props: { shipment_id: [String, Number] },
  data: function () {
    return {
      shipment: {},
      shipment_title: "",
      effective_time: null,
      super_user: false,
      icons: {},
      order_shipments: [],
    };
  },
  watch: {},
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    canceloperation() {
      this.customer = null;
      this.modalshow4customer = false;
    },
    clickordershipmentediticon(i) {
      if (
        this.shipment.creator_id !== this.$store.state.user_id &&
        !this.super_user
      ) {
        this.$store.commit("add_notification", {
          msg: "您无权修改此字段",
        });
        return;
      } else {
        let item = this.order_shipments[i];
        item.editing = true;
        this.$set(this.order_shipments, i, item);
      }
    },
    saveordershipmentamount(i) {
      let item = this.order_shipments[i];
      let amount = item["amount"];
      let amount_m = item["amount_m"];
      if (amount === amount_m) {
        return;
      }
      this.$store.commit("show_loading", "正在提交数据，请稍等......");
      let that = this;
      updateordershipmentamount(item["id"], amount_m)
        .then((res) => {
          that.$store.commit("hide_loading");
          item.editing = false;
          item.amount = res.content;
          that.$set(that.order_shipments, i, item);
        })
        .catch((e) => {
          that.$store.commit("hide_loading");
          that.$store.commit("add_notification", {
            msg: "修改失败+" + e,
          });
        });
    },
    clickicon(field) {
      if (!this.editable[field] && !this.super_user) {
        this.$store.commit("add_notification", {
          msg: "您无权修改此字段",
        });
        return;
      }
      let a = {};
      let value = null;
      if (!this.icons[field] || this.icons[field].icon === "edit") {
        a[field] = { icon: "save" };
        this.icons = Object.assign({}, this.icons, a);
      } else {
        // 点了保存按钮
        a[field] = { icon: "edit" };
        if (field === "shipment_title") {
          if (this.shipment_title === this.shipment.shipment_title) {
            this.$store.commit("add_notification", {
              msg: "出货编号没有修改",
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = this.shipment_title;
        } else if (field === "effective_time") {
          if (
            new Date(this.effective_time).format("yyyy-MM-dd") ===
            new Date(this.shipment.effective_time).format("yyyy-MM-dd")
          ) {
            this.$store.commit("add_notification", {
              msg: "接单日期没有修改",
            });
            this.icons = Object.assign({}, this.icons, a);
            return;
          }
          value = new Date(this.effective_time).format("yyyy-MM-dd 00:00:00");
        }
        let shipment_id = this.shipment.id;
        let that = this;
        updateshipment({ field, value, shipment_id })
          .then(() => {
            that.$store.commit("add_notification", {
              msg: "修改成功",
              status: "success",
            });
            this.icons = Object.assign({}, this.icons, a);
            this.getsingleshipment();
          })
          .catch((e) => {
            that.$store.commit("add_notification", {
              msg: "修改失败：" + e,
            });
          });
        console.log(field, value);
        // 保存按钮点击逻辑结束
      }
    },
    getsingleshipment() {
      this.order_shipments = [];
      getsingleshipment(this.shipment_id).then((res) => {
        let data = res.content;
        this.shipment = JSON.parse(JSON.stringify(data));
        this.shipment_title = data.shipment_title;
        this.effective_time = new Date(data.effective_time);
        this.super_user = data.super_user;
        if (data.creator_id === this.$store.state.user_id || this.super_user) {
          this.editable = {
            shipment_title: true,
            effective_time: true,
          };
        }
        this.order_shipments = data.order_shipments.map((v) => {
          v.editing = false;
          v.amount_m = v.amount;
          return v;
        });
      });
    },
  },
  mounted: function () {
    this.getsingleshipment();
  },
};
</script>
<style lang='less'>
.edit-shipment {
  padding: 5px 6px 8px;
  .order-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .edit-icon {
      width: 25px;
      height: 25px;
      margin-left: 8px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .order-shipment-item {
    padding: 2px 0 2px 5px;
    display: flex;
    .index {
      width: 20px;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      .icon {
        svg.edit {
          width: 22px;
          height: 22px;
        }
        svg.delete {
          width: 20px;
          height: 20px;
        }
      }
      & > div {
        padding-right: 5px;
      }
      .amount {
        input {
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          padding: 3px;
          outline: none;
          width: 70px;
        }
      }
    }
  }
}
</style>