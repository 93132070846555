<template>
  <div id="createorder" class="main-container">
    <div class="form-little-title">创建订单</div>
    <InputItem labelname="合同编号" v-model="order_no"></InputItem>
    <SelectItem
      labelname="客户"
      :selectlist="customerList"
      v-model="customer"
      searchable
    ></SelectItem>
    <DatetimeItem
      labelname="接单日期"
      title="接单日期"
      v-model="order_time"
    ></DatetimeItem>
    <InputItem labelname="金额($)" v-model="amount" type="number"></InputItem>
    <InputItem labelname="主要产品" v-model="products"></InputItem>
    <div class="input-item need-deposit">
      <div>是否需要定金:</div>
      <input
        type="radio"
        id="need-deposit-true"
        :value="true"
        v-model="need_deposit"
      />
      <label for="need-deposit-true">需要</label>
      <input
        type="radio"
        id="need-deposit-false"
        :value="false"
        v-model="need_deposit"
      />
      <label for="need-deposit-false">不需要</label>
    </div>
    <div class="confirm-deposit">
      <div class="input-item confirm-item">
        <div>状态:</div>
        <input
          type="radio"
          id="order-confirmed"
          :value="true"
          v-model="order_confirmed"
        />
        <label for="order-confirmed">已确认</label>
        <input
          type="radio"
          id="order-not-confirmed"
          :value="false"
          v-model="order_confirmed"
        />
        <label for="order-not-confirmed">未确认</label>
      </div>
      <div class="input-item" v-if="need_deposit">
        <div>定金:</div>
        <input
          type="radio"
          id="deposit-paid"
          :value="true"
          v-model="deposit_paid"
        />
        <label for="deposit-paid">已付</label>
        <input
          type="radio"
          id="deposit-not-paid"
          :value="false"
          v-model="deposit_paid"
        />
        <label for="deposit-not-paid">未付</label>
      </div>
    </div>
    <SelectItem
      labelname="接单人"
      :selectlist="jiedanList"
      v-model="jiedan"
      disabled
    ></SelectItem>
    <div
      class="fuzhu"
      v-if="!$store.state.roles_disabled.includes('fuzhujiedan')"
    >
      <SelectItem
        labelname="辅助接单"
        :selectlist="fuzhujiedanList"
        clearable
        multiple
        v-model="fuzhujiedan"
      ></SelectItem>
      <InputItem
        class="narrow"
        labelname="系数"
        v-model="fuzhucoefficient"
        type="number"
      ></InputItem>
    </div>
    <SelectItem
      labelname="业务跟单"
      :selectlist="yewugendanList"
      clearable
      v-model="yewugendan"
      multiple
      v-if="!$store.state.roles_disabled.includes('yewugendan')"
    ></SelectItem>
    <SelectItem
      labelname="跟单人"
      :selectlist="gendanList"
      clearable
      v-model="gendan"
      v-if="!$store.state.roles_disabled.includes('gendan')"
    ></SelectItem>
    <SelectItem
      labelname="辅助跟单"
      :selectlist="fuzhugendanList"
      clearable
      v-model="fuzhugendan"
      v-if="!$store.state.roles_disabled.includes('fuzhugendan')"
    ></SelectItem>
    <InputItem labelname="订单来源" v-model="source"></InputItem>
    <SelectItem
      labelname="币种"
      :selectlist="currencyList"
      disabled
      v-model="currency"
      v-if="false"
    ></SelectItem>
    <InputItem
      labelname="汇率"
      v-model="exchange_rate"
      disabled
      type="number"
      v-if="false"
    ></InputItem>
    <br />
    <div class="two-buttons-group">
      <Button @click.native="goBack">取消</Button>
      <Button type="primary" @click="createorder">保存</Button>
    </div>
    <div class="modal" v-show="modalshow > 0">
      <div class="modal-mask"></div>
      <div class="modal-2" v-show="modalshow === 2">
        <AddCustomer
          v-on:saved="customersaved"
          v-on:canceled="canceloperation"
        ></AddCustomer>
        <!-- modal-2 结束 -->
      </div>
    </div>
  </div>
</template>
<script>
import InputItem from "@/components/InputItem.vue";
import SelectItem from "@/components/SelectItem.vue";
import DatetimeItem from "@/components/DatetimeItem.vue";
import Button from "@/components/Button.vue";
import AddCustomer from "@/components/AddCustomer.vue";
import { createOrder } from "@/api/order";
import { getcustomerlist } from "@/api/customer";
import { getalltypeofuserlistfs } from "@/api/user";

export default {
  components: { InputItem, SelectItem, DatetimeItem, Button, AddCustomer },
  data: function () {
    return {
      order_no: "",
      order_time: null,
      customerList: [],
      customer: null,
      jiedanList: [],
      jiedan: null,
      fuzhujiedanList: [],
      fuzhujiedan: [],
      fuzhucoefficient: null,
      yewugendanList: [],
      yewugendan: [],
      gendanList: [],
      gendan: null,
      fuzhugendanList: [],
      fuzhugendan: null,
      amount: null,
      products: null,
      source: null,
      need_deposit: false,
      order_confirmed: false,
      deposit_paid: false,
      currencyList: [{ value: 1, label: "美元" }],
      currency: { value: 1, label: "美元" },
      exchange_rate: 6.5,
      modalshow: 0,
    };
  },
  watch: {
    customer(v) {
      if (v && v.value === 0) {
        // 添加添加客户
        this.modalshow = 2;
      } else if (v) {
        this.jiedan = v.jiedan;
        this.fuzhujiedan = v.fuzhujiedan;
        this.jiedanList = v.jiedanList;
        this.fuzhujiedanList = v.fuzhujiedanList;
      }
    },
    /*fuzhujiedan(v) {
      if (v && this.jiedan && v.value === this.jiedan.value) {
        this.fuzhujiedan = null;
        this.$store.commit("add_notification", {
          msg: "辅助接单人和接单人不可以是同一个人",
        });
      }
      this.fuzhucoefficient = v.coefficient;
    },*/
    yewugendan(v) {
      let yewugendan_ids = v.map((v) => v.value);
      if (v && this.jiedan && yewugendan_ids.includes(this.jiedan.value)) {
        this.yewugendan = null;
        this.$store.commit("add_notification", {
          msg: "业务跟单不可以和接单人重复",
        });
      }
    },
    deposit_paid(v) {
      if (v) {
        this.order_confirmed = true;
      }
    },
    order_confirmed(v) {
      if (!v) {
        this.deposit_paid = false;
      }
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    customersaved(e) {
      e.value = e.id;
      e.label = e.name;
      getcustomerlist().then((res) => {
        let customerlist = res.content;
        //customerlist.push({ value: 0, label: "+ 新增客户" });
        this.customerList = customerlist;
        this.customer = e;
      });
      this.modalshow = 0;
    },
    canceloperation() {
      this.customer = null;
      this.modalshow = 0;
    },
    createorder() {
      if (!this.order_no) {
        this.$store.commit("add_notification", {
          msg: "请填写合同编号",
        });
        return;
      }
      if (!this.customer) {
        this.$store.commit("add_notification", {
          msg: "请选择客户",
        });
        return;
      }
      if (!this.amount) {
        this.$store.commit("add_notification", {
          msg: "请填写合同金额",
        });
        return;
      }
      if (!this.jiedan) {
        this.$store.commit("add_notification", {
          msg: "请选择接单人",
        });
        return;
      }
      if (!this.source) {
        this.$store.commit("add_notification", {
          msg: "请填写订单来源",
        });
        return;
      }
      let need_deposit = this.need_deposit;
      let confirm_status = 0;
      if (this.order_confirmed) {
        confirm_status = 1;
      }
      if (this.deposit_paid || (this.order_confirmed && !need_deposit)) {
        confirm_status = 2;
      }
      let data = {
        order_no: this.order_no,
        order_time: this.order_time,
        customer_id: this.customer.value,
        amount: this.amount * 1,
        products: this.products,
        jiedan_id: this.jiedan.value,
        fuzhujiedan: this.fuzhujiedan,
        fuzhucoefficient: this.fuzhucoefficient,
        yewugendan: this.yewugendan,
        gendan_id: this.gendan ? this.gendan.value : null,
        fuzhugendan_id: this.fuzhugendan ? this.fuzhugendan.value : null,
        currency_id: this.currency.id,
        currency: this.currency.label,
        exchange_rate: this.exchange_rate * 1,
        confirm_status: confirm_status,
        source: this.source,
        need_deposit: need_deposit,
      };
      this.$store.commit("show_loading", "正在提交数据，请稍等......");
      createOrder(data)
        .then((res) => {
          this.$store.commit("hide_loading");
          if (res.errcode) {
            this.$store.commit("add_notification", {
              msg: "提交失败：" + res.content,
            });
          } else {
            this.goBack();
          }
        })
        .catch((e) => {
          this.$store.commit("hide_loading");
          this.$store.commit("add_notification", {
            msg: "提交失败：" + e,
          });
        });
    },
  },
  mounted: function () {
    document.title = "创建订单";
    this.order_time = new Date();
    getcustomerlist().then((res) => {
      let customerlist = res.content;
      //customerlist.push({ value: 0, label: "+ 新增客户" });
      this.customerList = customerlist;
    });
    getalltypeofuserlistfs().then((res) => {
      //this.jiedanList = res.content.jiedanList;
      //this.fuzhujiedanList = res.content.fuzhujiedanList;
      this.yewugendanList = res.content.yewugendanList;
      this.gendanList = res.content.gendanList;
      this.fuzhugendanList = res.content.fuzhugendanList;
      let t = res.content.jiedanList.filter(
        (v) => v.value === this.$store.state.user_id
      );
      if (t.length === 1) {
        this.jiedan = t[0];
      }
    });
  },
};
</script>
<style lang='less'>
#createorder {
  .confirm-deposit {
    display: flex;
    flex-wrap: wrap;
    .input-item {
      min-height: 24px;
      width: auto;
      &.confirm-item {
        padding-right: 15px;
      }
    }
    padding-bottom: 6px;
  }
  .need-deposit.input-item {
    min-height: 24px;
    padding-top: 6px;
  }
}
.modal {
  /*
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  */
  position: fixed;
  z-index: 998;
  top: 0;
  height: 100vh;
  width: 100%;
  max-width: 760px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);

  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .modal-1 {
    position: absolute;
    right: 20px;
    left: 20px;
    top: 50%;
    max-height: 100%;
    transform: translate(0, -50%);
    padding: 15px;
    background-color: white;
    border-radius: 7px;

    .taskname {
      text-align: center;
      font-size: 22px;
    }
    .button-wrapper {
      margin: 15px 0;
    }
  }
  .modal-2 {
    position: absolute;
    right: 20px;
    left: 20px;
    max-height: 98vh;
    top: 50%;
    height: 400px;
    transform: translate(0, -50%);
    background-color: #fff;
    border-radius: 7px;
    z-index: 999;
  }
  .modal-confirm-again {
    position: absolute;
    right: 10px;
    left: 10px;
    height: 350px;
    max-height: 98vh;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #fff;
    border-radius: 7px;
    z-index: 1999;

    .confirm-wrapper {
      position: absolute;
      top: 0px;
      bottom: 45px;
      width: 100%;
      overflow-y: scroll;
      padding: 5px;
      .passage {
        margin-top: 8px;
      }
    }

    .confirm-panel {
      position: absolute;
      display: flex;
      justify-content: space-between;
      bottom: 0;
      height: 45px;
      width: 100%;

      .v-button {
        flex: 1 1 0;
        border-radius: 0px;
        border-bottom-left-radius: 7px;
      }
      .v-button-primary {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 7px;
      }
    }
  }
}
.fuzhu {
  display: flex;
  .narrow {
    padding-left: 30px;
    width: 60%;
  }
}
</style>